export const STATUS = {
    CREATED: "Non résolu",
    UPDATED: "Aggravé",
    PROCESSING: "Traitement en cours",
    RESOLVED: "Résolu",
    OTHER: "Autre",
    MOVED: "Déplacé"
}

export const translateState = (state) => STATUS[state]?STATUS[state]:state;

export const TYPE = {
    collectivity: "Collectivité",
    citizen: "Citoyen"
}

export const translateType = (type) => TYPE[type]?TYPE[type]:type;
