import React, { useState, useEffect } from 'react';
import api, {fetchToken} from '../../../api'
import { Paper, Typography, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

export const sortOrder = function (a, b) {
    return a.order - b.order;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 16, 
        padding:16
    },
    module: {
        paddingBottom:8
    },
    chapter: {
        paddingTop:8
    }
}));

export const TrainingPlan = ({training, currentmodule, currentchapter}) => {

    const [trainingPlan, setTrainingPlan] = useState(null);
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {
        fetchToken(api.url + '/trainings_plan/'+training.id).then(({json}) => {
            setTrainingPlan(json);
        })
    }, [training.id])

    if (!trainingPlan) {
        return (<></>);
    } else {
        return (
        <>
        { trainingPlan.training_modules?.length > 0 &&
            <Paper className={classes.root} elevation={2}>
                <Link color="primary" href={'#/training/'+training.id}><Typography variant="h6" color="primary">{trainingPlan.Title}</Typography></Link>
                <ul>
                    {trainingPlan.training_modules.sort(sortOrder).map(module => 
                        <li  key={"module"+module.id} onClick={()=> history.push('/training/'+training.id+'/'+module.id)} className={classes.module} style={{color: theme.palette.secondary.main}}>
                            { module?.id === currentmodule?.id && <Link color="secondary" href={'#/training/'+training.id+'/'+module.id}><b>{module.Title}</b></Link> }
                            { module?.id !== currentmodule?.id && <Link color="secondary" href={'#/training/'+training.id+'/'+module.id}>{module.Title}</Link> }
                            { module?.training_module_chapters?.length > 1 && 
                                <ul>
                                    {module?.training_module_chapters?.length > 1 && module?.training_module_chapters.sort(sortOrder).map(chapter =>
                                        <li key={"chapter"+chapter.id} className={classes.chapter} style={{color: theme.palette.primary.main}}>
                                            { chapter?.id === currentchapter?.id && <Typography color="primary" style={{fontWeight:600}}>{chapter.Title}</Typography> }
                                            { chapter?.id !== currentchapter?.id && <Link color="primary" href={'#/training/'+training.id+'/'+module.id+'/'+chapter.id}>{chapter.Title}</Link> }
                                        </li>
                                    )}
                                </ul>
                            }
                        </li>
                    )}
                </ul>
            </ Paper>
        }
        </>
        )
    }
}