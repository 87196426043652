// console.log("TEST ENV", process.env)

export const api = {
    url: process.env.REACT_APP_URL_API,
    url_front: process.env.REACT_APP_URL_FRONT,
    shapeparser: process.env.REACT_APP_URL_SHAPEPARSER,
    site: process.env.REACT_APP_SITE ||"C2G",
    site_copyright: process.env.REACT_APP_URL_WEB_SITE_COPYRIGHT ||"https://clean4green.org",
    siteSettings: process.env.REACT_APP_SITE === "LAPADE"? {
        name: "La PADE",
        loginBackground: "/lapade-background.jpg",
        loginImg: "/lapade-logo-slogan.png.png",
        logoHeader: "/logo-blue.png"
    }:{
        name: "Clean4green",
        loginBackground: "/bg-c4g.jpg",
        loginImg: "/logo-slogan-c4g-c2g.png",
        logoHeader: "/logo-c4g-white-white.png"
    },
    parseError: (res, message = null) => {
        if (res?.message && res.message?.length && res.message.length>0) {
            if (res.message[0]?.messages && res.message[0].messages?.length && res.message[0].messages.length > 0) {
                if (res.message[0].messages[0]?.id) {
                    if (message) return res.message[0].messages[0].message;
                    return res.message[0].messages[0].id;
                }
            }
        }
        return null;
    }
}

export const generateExtraQuery = (date_start, date_end) => {
    return (
        "?a=a&date_start="+
        (date_start?date_start:"")+
        "&date_end="+
        (date_end?date_end:"")
    )
}