import React from "react";
import { Form } from 'react-final-form';
import * as yup from 'yup'
import { TextField, Radios, Switches, DatePicker  } from 'mui-rff';
import { useDispatch } from 'react-redux'; 
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr-CA";

import {  Button, Grid, Typography } from "@material-ui/core";

import { authStyle, authValidate } from "../../Layout/AuthLayout";
import api, { authProvider } from "../../../api";

const validationSchema = yup.object({
  name: yup.string().required("Votre nom est requis."),
  firstname: yup.string().required("Votre prénom est requis."),
  
  // eslint-disable-next-line
  username: yup.string().min(4,"Trop court, minimum ${min} charactères").required("Requis"),
});

const formFields = [
    {
        size: { xs: 12, sm: 6 },
        field: (<TextField label="Pseudonyme" name="username" margin="none"  required={true} />)
      },
    {
        size: { xs: 12, sm: 6 },
        field: (<Radios label="Civilité" name="gender" formControlProps={{ margin: 'none' }} radioGroupProps={{ row: true }} 
            data={[
            { label: 'Autre', value: 'Autre' },
            { label: 'Monsieur', value: 'Monsieur' },
            { label: 'Madame', value: 'Madame' },
            ]}
      />
    ),
  },
  {
    size: { xs: 12, sm: 6},
    field: (<TextField label="Prénom" name="firstname" margin="none" required={true} />)
  },
  {
    size: { xs: 12, sm: 6 },
    field: (<TextField label="Nom" name="name" margin="none"  required={true} />)
  },
  {
    size: { xs: 6, sm: 6 },
    field:(  <DatePicker label="Date de naissance" name="birthdate" format="dd/MM/yyyy" required={false} dateFunsUtils={DateFnsUtils} locale={frLocale} />)
  },
  {
    size: { xs: 12, sm: 6 },
    field: (<TextField label="Email" name="email" type="email" margin="none"  disabled />)
  },
  {
    size: { xs: 6, sm: 3 },
    field: (<Switches name="newsletter" required={true} data={{label: 'Newsletter Clean2gether', value: true}} />)
  }
]

export function AccountProfileForm({user, update}) {

  const dispatch = useDispatch()
  const classes = authStyle();

  const onSubmit = async values => {
    let result = await new Promise(resolve => {
      authProvider.updateUserProfile(user.id, values).then((json) => {
        //console.log("updateUser success:", json)
        resolve(false)
        update()
      }).catch((error)=> {
          //console.log("updateUser error:", error)
        if (api.parseError(error)) {
            switch (api.parseError(error)) {
              case "Auth.form.error.email.taken":
                resolve({email: "Email déja enregistré"})
              break;
              case "Auth.form.error.username.taken":
                resolve({username: "Pseudonyme déja utilisé"})
              break;
              default:
                // console.log(error);
                resolve({error: "unknow-error"})
            }
          } else {
            resolve({error: "no-reponse"})
          }
      })
    })
    return result;
  };
  const handleClickOn = () => {
    // console.log("test");

    dispatch({type: 'SAGA_USER_REFRESH', payload: {
      test: "OK"
    }})
  }

  return (
    <>
      <Typography variant="h5" >Informations générales</Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={user}
        validate={authValidate(validationSchema)}
        render={({ handleSubmit, submitting, pristine}) => {
          return (
            <form onSubmit={handleSubmit} noValidate className={classes.form} >
                <Grid container alignItems="flex-start" spacing={4}>
                  {formFields.map((item, idx) => (
                    <Grid item {...item.size?item.size:{xs:12}} key={idx}>
                      {item.field}
                    </Grid>
                  ))}

                  <Grid item xs={12} style={{textAlign:"center"}}>
                    <Button variant="contained"color="primary" type="submit" disabled={pristine}>
                      Enregistrer 
                    </Button>
                  </Grid>
                </Grid>
            </form>
          );
        }}
        />
        <Button onClick={handleClickOn}>Rafraichir</Button>
        </>
  );
}

