import React, { useEffect, useState } from "react";
import { Layout, Notification } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogContentText, ThemeProvider } from '@material-ui/core';

import AppBar from './AppBar';
import { CustomTheme } from "../../theme/CustomTheme";
import { AdminSidebar } from './SideBar'
import { Redirect } from "react-router-dom"
import { Cookies } from '../../helpers/Cookies'
import { PopupBibliotheque } from "../UI/Popup/PopupBibliotheque";
import { PopupAlertes } from "../UI/Popup/PopupAlertes";

export const DialogLAPADEContext = React.createContext({
    openAlert:false, toggleAlert: ()=>{
        // console.log("toggle dialog")
    },
    openBiblio:false, toggleBiblio: ()=>{
        // console.log("toggle dialog")
    },
});

export const AdminLayout = (props) => {
    const { user, loading } = useSelector(state => state.account);
    const { filter } = useSelector(state => state.area);
    const dispatch = useDispatch();


    const [openAlert, setOpenAlert] = useState(false);
    const handleToggleOpenAlert = () => {
        setOpenAlert(!openAlert);
    };


    const [openBiblio, setOpenBiblio] = useState(false);
    const handleToggleOpenBiblio = () => {
        setOpenBiblio(!openBiblio);
    };


    useEffect(() => {
        if (filter === "Loading") {
            dispatch({type: 'AREA_INIT'});
        }
        // eslint-disable-next-line
    }, [filter])

    if (!user?.id) {
        const token = Cookies.getCookie('token');
        if (token) {
            if (!loading) {
                // console.log("AdminLayout dispatch SAGA_USER_REFRESH");
                dispatch({type: 'SAGA_USER_REFRESH'})
            }
        } else {
            return (<Redirect push to="/login"></Redirect>)
        }
    }

    
    return (
        <DialogLAPADEContext.Provider value={{
            openAlert:openAlert, 
            toggleAlert: handleToggleOpenAlert,
            openBiblio:openBiblio, 
            toggleBiblio: handleToggleOpenBiblio
            }}>
            <Layout
                {...props}
                appBar={AppBar}
                // menu={Menu}
                sidebar={AdminSidebar}
                notification={Notification}
            />
            <ThemeProvider theme={CustomTheme}>
                <DisabledDialog open={openAlert} handleToggleOpen={handleToggleOpenAlert}>
                    <PopupAlertes />
                </DisabledDialog>

                <DisabledDialog open={openBiblio} handleToggleOpen={handleToggleOpenBiblio}>
                    <PopupBibliotheque />
                </DisabledDialog>
            </ThemeProvider>
        </DialogLAPADEContext.Provider>
    )
};

const DisabledDialog = ({open, handleToggleOpen, width = "md", children, title }) => {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleToggleOpen}
                maxWidth={width}
                // width={width}
                scroll={"paper"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                {/* <DialogTitle id="alert-dialog-title" style={{color:"#50BCB8"}}>{title}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{fontSize:16}}>
                        { children }
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}