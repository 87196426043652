// in src/Dashboard.js
import React, {useState, useRef, useEffect } from "react";
import { Map, GeoJSON } from 'react-leaflet'
import {fetchToken} from '../../api'

import { MapLayerControl } from "../UI/Map/MapLayerControl";
export const TestMap = () => {

    const mapRef = useRef();
    const geoJsonLayer = useRef(null);
    const geoJsonLayerEPCI = useRef(null);
    const geoJsonLayerCommune = useRef(null);
    const [polygon, setPolygon] = useState(null)
    const [polygonEPCI, setPolygonEPCI] = useState(null)
    const [polygonCommune, setPolygonCommune] = useState(null)
    const [name, setName] = useState({});

    const handleMoveEnd = (e) => {

    }

    const handleMouseClick = (e) => {
      const lat = e.latlng.lat;
      const lng = e.latlng.lng;
      fetchToken(`https://nomi.v2.clean2gether.com/?coord=${lat},${lng}&types=cp,commune,region,epci,departement&format=1`).then(({json}) => {
        if (json?.error)
          return ;

        // console.log(json);

        setName({
          region:json?.region?.value?.properties?.nom,
          departement:json?.departement?.value?.properties?.nom,
          epci:json?.epci?.value?.properties?.nom_epci,
          cp: json?.cp?.value?.properties?.id,
          commune: json?.commune?.value?.properties?.nom,
        })
        setPolygon(json?.departement?.value?.geometry)
        setPolygonEPCI(json?.epci?.value?.geometry)
        setPolygonCommune(json?.commune?.value?.geometry)
    })
    }

    useEffect(() => {
      if (polygon?.coordinates)
        geoJsonLayer.current?.leafletElement.clearLayers().addData(polygon);
      if (polygonEPCI?.coordinates)
        geoJsonLayerEPCI.current?.leafletElement.clearLayers().addData(polygonEPCI);
      if (polygonCommune?.coordinates)
        geoJsonLayerCommune.current?.leafletElement.clearLayers().addData(polygonCommune);
    }, [polygon, polygonEPCI, polygonCommune]);

    return (
        <>
        <Map center={[47, 1]} zoom={6} scrollWheelZoom={true} style={{width:'100%', height:'60vh'}} onMoveEnd={handleMoveEnd} onClick={handleMouseClick} ref={mapRef}>
            <MapLayerControl/>
            <GeoJSON color="red" data={polygon} ref={geoJsonLayer} />
            <GeoJSON color="green" data={polygonEPCI} ref={geoJsonLayerEPCI} />
            <GeoJSON color="blue" data={polygonCommune} ref={geoJsonLayerCommune} />
        </Map>
        <div >Région: {name.region} </div>
        <div style={{color:"red"}}>Département : {name.departement} </div> 
        <div style={{color:"green"}}>EPCI: {name.epci}</div>
        Code Postal: {name.cp}<br />
        <div style={{color:"blue"}}>Commune: {name.commune}</div>
        </>
    )
}
