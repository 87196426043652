import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, TextField, Divider, InputAdornment } from '@material-ui/core';
import L from "leaflet";
import { Map, Marker, GeoJSON} from 'react-leaflet';

import { MapLayerControl } from './MapLayerControl'
import api, {fetchToken, fetchAddress, fetchGeoreverse} from '../../../api'
import { iconByValue } from '../../../helpers/Markers';

export const PickerMap = ({
    init = {zoom: 14, address: "", latitude: 43, longitude: 2, alertsID: null, updateAddress: false}, 
    handleUpdate,
    styleMap = {},
    geometry = false,
    enable
}) => {

    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState({
        latitude:init.latitude, 
        longitude: init.longitude, 
        zoom: init.zoom})
    const [address, setAddress] = useState(init.address);
    const [alertSelected, setAlertSelected] = useState(init.alertsID);

    useEffect(() => {
        let timer;
        if (location?.latitude &&
            location?.longitude &&
            location.latitude !== init.latitude &&
            location.longitude !== init.longitude &&
            init.updateAddress &&
            init.address !== null
        ) {
            setLoading(true)
            timer = setTimeout(async ()=> {
                    // console.log("nominatim query")
                    const { json } = await fetchGeoreverse(location.latitude, location.longitude)
                    // console.log(json.address);
                const { address } = json;
                const ad = `${address?.amenity ||address?.tourism || ""} ${address?.road || ""} ${address?.village || address?.city || address?.town || ""} ${address?.postcode}`;
                setAddress(ad);
                clearTimeout(timer)
                setLoading(false)
            },200);
        }
        return () => {
            clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        handleUpdate({location, address, alertSelected})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, address, alertSelected])

    const handleMoveLocation = async ({lat, lng, bbox}) => {
        setLocation({latitude:lat,longitude:lng, bbox})
    }

    const handleChangeSelected = (alertSelected) => {
        setAlertSelected(alertSelected);
    }

    const handleChangeAddress = (e,v) => {
        setAddress(e.target.value)
    }

    const isLoading = loading ? {
        startAdornment: <InputAdornment position="end"><CircularProgress size={20}/></InputAdornment>
    } : null;


    // if (enable === false) {
    //     console.log("disabled map");
    // }

        return (
            <>
            <MapPosition enable={enable} point={location} bbox={ location?.bbox || init?.bbox || false }handleMove={handleMoveLocation} handleSelected={alertSelected?handleChangeSelected:null} styleMap={styleMap} geometry={geometry} />
            { init.address !== null && <>
                <Divider />
                <TextField 
                    label="Adresse" 
                    value={address} 
                    onChange={handleChangeAddress} 
                    fullWidth 
                    variant="filled"
                    disabled={loading}
                    InputProps={isLoading}
                /></>}
                { init.alertsID !== null && <>
                    {alertSelected && alertSelected.join(",")}
                </> }
            </>
        )
}

const AutocompleteSearchIGN = ({handleChangeSearchAddress}) => {

    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [options, setOptions] = useState([{fulltext: 'Entrez une adresse'}])

    useEffect(() => {
        let timer;
        if (search?.length > 3) {
            setLoading(true)
            timer = setTimeout(async ()=> {
                // console.log("IGN autocomplete search")
                const { json } = await fetchAddress(search)
                // console.log("json result:", json)
                setTimeout(() => {
                    if (json?.length > 0) {
                        setLoading(false)
                        setOptions(json);
                    }
                }, 50);
            },500);
        }
        return () => {
            clearTimeout(timer)
        }
    }, [search]);

    const handleSearchChange = async (event, newSearchValue) => {
        setSearch(newSearchValue);
    }

    return (
        <Autocomplete
        id="combo-box-demo"
        options={options}
        loading={loading}
        autoHighlight={true}
        fullWidth={true}
        onInputChange={handleSearchChange}
        filterOptions={(x) => x}
        getOptionSelected={(option,value) => {
            if (option.lat === value.lat && option.lon === value.lon) { 
                return true;
            }
            return false;
        }}
        // onClick={(e)=>{console.log("ONCLICK");}}
        onChange={handleChangeSearchAddress}
        size={'small'}
        getOptionLabel={(option) => option.display_name}
        renderInput={(params) => <TextField {...params} label="Rechercher sur la carte" variant="outlined" />}
        />
    )

}

const MapPosition = ({
        children, 
        point = {latitude:43, longitude: 2, zoom: 8}, 
        handleMove, 
        handleSelected = false, 
        styleMap = {},
        geometry = false,
        bbox,
        enable
    }) => {
        
    const [zoom, setZoom] = useState(point.zoom);
    const [markers, setMarkers] = useState([]);
    const [selected, setSelected] = useState([]);
    const ref = useRef();
    const geoJsonLayer = useRef(null);

    const mapStyle = {
        boxShadow: "rgb(157 196 65) 0px 0px 3px",
        width:'100%', 
        height: '70vh',
        marginBottom: '20px',
        borderRadius: '2px',
        ...styleMap
    }

    // Bricolage pour débuguer ( stopPropagation , sur input search address)
    useEffect(() => {
        if(ref?.current){
            // console.log("Work")
            const disableClickPropagation = L?.DomEvent?.disableClickPropagation
            disableClickPropagation(ref.current)
        } else {
            setTimeout(()=> {
                if(ref?.current){
                    // console.log("WorkAfter")
                    const disableClickPropagation = L?.DomEvent?.disableClickPropagation
                    disableClickPropagation(ref.current)
                }
            }, 200)
        }
    }, [ref])

    // Changement dans le tableau d'alerte Sélectionné
    useEffect(() => {
        if (handleSelected) {
            handleSelected(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    useEffect(() => {
        // console.log("update geoJsonLayer", geometry);
        if (geometry[0]?.coordinates)
            geoJsonLayer.current?.leafletElement.clearLayers().addData(geometry);
    }, [geometry])

    // Launch search alert around point & d=0.15
    useEffect(() => {
        if (point?.latitude && point?.longitude && handleSelected) {
            // console.log(api.url + `/alertsGeoloc?lat=${point.latitude}&lon=${point.longitude}&d=0.15`);
            fetchToken(api.url + `/alertsGeoloc?lat=${point.latitude}&lon=${point.longitude}&d=0.10`).then(({json}) => {
                // console.log(json.length)
                setMarkers(json.map(m => ({
                    type: "Feature",
                    properties: {cluster: false, id: m.id, resolved: m.resolved, status: m.status},
                    geometry: {
                        type: "Point",
                        coordinates: [ m.lon, m.lat ]
                    }
                })));
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [point])

    const handleMarkerMove = ({target}) => {
        handleMove(target._latlng);
    }

    const handleZoomLevelChange = (e) => {
        setZoom(e.target._zoom);
    }

    const handleChangeSearchAddress = (event, newValue) => {

        const adaptNominatimBbox = (bbox) => {
            return (
                [bbox[2], bbox[0], bbox[3], bbox[1]]
            )
        }
        if (newValue?.lat && newValue?.lon) {
            handleMove({lat: parseFloat(newValue.lat), lng:parseFloat(newValue.lon), bbox: adaptNominatimBbox(newValue.boundingbox.map(str => parseFloat(str)))});
        }
    }

    const clickAlert = (alertID) => {
        if (!selected?.includes(alertID)) {
            setSelected([...selected, alertID]);
        } else {
            let newSelected = [...selected];
            newSelected.splice(selected.indexOf(alertID),1);
            setSelected(newSelected);
        }
    }

    const handleClickMap = (clickEvent) => {
        handleMove(clickEvent.latlng);
    }

    // console.log("BBOX BOUNDS:", bbox)
    const translateBbox = (bbox) => {
        if (bbox && bbox?.length === 4) {
            return ([
                [bbox[1], bbox[0]],
                [bbox[3], bbox[2]],
            ])
        }
        return false;
        
    }

    if (point && enable) {
        return (
            <>
                <div 
                    ref={ref} onClick={(event)=>{
                        // console.log("ONCLICK Stop propagation");
                        event.stopPropagation();
                    }}
                    style={{
                        padding: "8px 0px", 
                        zIndex:99999, 
                        backgroundColor:"#fff", 
                        width:"100%", 
                        position:"relative", 
                        display: "block",
                        // left: 50, 
                        // right:20, 
                        // top: 12, 
                        borderRadius: 2
                        }}>
                    <AutocompleteSearchIGN handleChangeSearchAddress={handleChangeSearchAddress}></AutocompleteSearchIGN>
                </div>

                <Map 
                    center={[point.latitude, point.longitude]} 
                    zoom={zoom} 
                    { ...(bbox? {bounds : translateBbox(bbox)}: {} ) }
                    // bounds={false}
                    scrollWheelZoom={true} 
                    style={mapStyle} 
                    maxZoom={18} 
                    onzoom={handleZoomLevelChange}
                    onClick={handleClickMap}>

                    <MapLayerControl/>

                    <GeoJSON color="red" data={geometry} ref={geoJsonLayer} />

                    <Marker position={[point.latitude, point.longitude]} draggable={true} ondragend={handleMarkerMove}></Marker>
                    {(handleSelected && markers?.length > 0) && markers.map(marker => {
                        const [longitude, latitude] = marker.geometry.coordinates;
                        return (
                            <Marker 
                                key={marker.properties.id+"markerid"}
                                position={[latitude, longitude]} 
                                draggable={false}
                                opacity={selected?.includes(marker.properties.id)?1:0.5}
                                icon={iconByValue(marker.properties)}
                                onClick={(e)=>{clickAlert(marker.properties.id)}}></Marker>
                        )
                    })}
                    
                    
                </Map>
            </>
        )
    } else return (null)
}