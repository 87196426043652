import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  LineChart,
  Resizable,
  BarChart,
  styler,
  EventMarker
} from "react-timeseries-charts";

import _ from "underscore";
import moment from "moment";
import { TimeRange, TimeSeries, Index } from "pondjs";
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { fetchJsonWithToken } from "../../api/httpClientToken";
import api from "../../api";
import { useTheme } from "@material-ui/core";

const style = styler([
  {key: "value", color: "steelblue", width: 1},
  {key: "total", color: "#F68B24", width: 2}
]);

const NullMarker = props => {
  return <g />;
};

export const BarChartGraph = ({
  query = "/timeseries?graph=alert_create&group=%Y-%m",
  title = "Graph",
  label = "Inscription",
  width = 0,
  height = 600
}) => {

  
  const { filter, date_start, date_end } = useSelector(state => state.area);
  const [timerange, setTimerange] = useState(false);
  const [series, setSeries] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  React.useEffect(() => {
      const load = async () => {
        setLoading(true);
        const created_at_gte = date_start?("&created_at_gte="+date_start):"";
        const created_at_lte = date_end?("&created_at_lte="+date_end):"";
        const alertCreated = await fetchJsonWithToken(api.url+"/timeseries?graph=alert_create&group=%Y-%m"+created_at_lte+created_at_gte);
        const eventResolved = await fetchJsonWithToken(api.url+"/timeseries?graph=alert_event_resolve&group=%Y-%m"+created_at_lte+created_at_gte);

        const mixedPoints = alertCreated.json.points.map((d,i) => {
          const resolvedIndex = eventResolved.json.points.findIndex((element)=> element[0] === d[0]);
          if (resolvedIndex >= 0) {

          }

          const t = resolvedIndex >= 0 ? eventResolved.json.points[resolvedIndex][1]:0;
          return [d[0], d[1],t]
        })

        const points = mixedPoints.map(([d, created,resolved]) => [
          Index.getIndexString("720h", new Date(d)),
          created,
          resolved
        ])

        const timeSeries = new TimeSeries({
            name: "hilo_rainfall",
            columns: ["index", "created", "resolved"],
            points: points,
        })
        setLoading(false);

        setSeries(timeSeries);
      };
      load();
  }, [filter, date_start, date_end]);

  React.useEffect(() => {
      if (series) {
      setTimerange(series.range());
      }
  }, [series]);


  if (loading) {
      return <p>Loading...</p>;
  }

  if (!loading && (!series || !timerange)) {
    return <p>Pas de résultats. <br />Changez les filtres</p>
  }


  let infoValues = [];
  if (highlight) {
      const trafficText = `${highlight.event.get("created")}`;
      const resolveText = `${highlight.event.get("resolved")}`;
      infoValues = [{ label: "Créées ", value: trafficText }, { label: "Résolues", value: resolveText }];
  }

 
 const style2 = styler([
  { key: "created", color: "#FF7100"},
  { key: "resolved", color: "#50bcb8"},
  ]);

  const renderChartContainer = () => {
    if (!series.range()) {
      return <>Aucune données</>
    }

    return (
      <ChartContainer timeRange={series.range()} >
        <ChartRow height="500">
          <YAxis
            id="rain"
            label="Alertes"
            min={0}
            max={series.max("created")}
            format=".2f"
            width="70"
            type="linear"
          />
          <Charts>
              <BarChart
                axis="rain"
                style={style2}
                spacing={1}
                offset={0}
                columns={["created"]}
                info={infoValues}
                infoHeight={40}
                infoTimeFormat={index =>
                  moment(index.begin()).format("MM/YY")
                }
                highlighted={highlight}
                onHighlightChange={
                  high => {
                    setHighlight(high);
                  }
                }
                series={series}
              />
              <BarChart
                axis="rain"
                style={style2}
                spacing={1}
                offset={0}
                columns={["resolved"]}
                info={infoValues}
                infoHeight={40}
                infoTimeFormat={index =>
                  moment(index.begin()).format("DD/MM/YY")
                }
                highlighted={highlight}
                onHighlightChange={
                  high => {
                    setHighlight(high);
                  }
                }
                series={series}
              />
          </Charts>
        </ChartRow>
      </ChartContainer>
    )
  }

  if (!width) {
    return (
      <Resizable>
        {renderChartContainer()}
      </Resizable>
    );
  } else {
      return (<>{renderChartContainer()}</>)
  }
};
