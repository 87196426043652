import React from "react";

import {
  List,
  Datagrid,
  ShowButton,
  ResourceContextProvider,
  DateField,
  BooleanField,
  Pagination,
  useListPaginationContext,
  SimpleList
} from "react-admin";

import {
  Divider,
  useMediaQuery
} from "@material-ui/core";

import { ImagesLiteField } from "../../Field/ImagesLiteField";
import { AlertPosition } from "../../Field/AlertPosition";

const AlertListFilteredPagination = props => {
  let { total } = useListPaginationContext(props);
  return (<Pagination labelRowsPerPage={"Alertes par pages :"} rowsPerPageOptions={[5,10]} count={total || 0} {...props} />);
}

  
  export const AlertListFiltered = (props) => {

    const { filter } = props;
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (!filter) {
      return (null);
    }

    return (
      <ResourceContextProvider value="alerts">
        <List
          basePath="/alerts"
          filter={filter}
          perPage={5}
          bulkActionButtons={false}
          actions={false}
          pagination={<AlertListFilteredPagination />}
          title=" "
          sort={{ field: "updated_at", order: "DESC" }}
          {...props}
        >
          <>
            <AlertListFilteredPagination />
            <Divider />
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.description}
                    secondaryText={record => `${record.resolved}`}
                    tertiaryText={record => new Date(record.created_at).toLocaleDateString()}
                    linkType= {record => "show" }
                />
            ) : (
            <Datagrid>
              <DateField label="Date" source="created_at" locales="fr-FR" />
              <AlertPosition source="town" label="Localisation" />
              <ImagesLiteField source="images" label="Images"></ImagesLiteField>
              <BooleanField label="Resolu" source="resolved" />
              <ShowButton label="Voir" size={"small"} />
            </Datagrid>
            )}
            </>
        </List>
        </ResourceContextProvider>
    );
  };
  