import React, { useContext } from 'react'
import { Typography, Button, Grid, makeStyles } from '@material-ui/core';
import { DialogLAPADEContext } from '../../Layout/AdminLayout';

const useClasses = makeStyles(theme => ({
    titleTypo: {
        color: theme.palette.secondary.main
    },
    ulPopup: {
        // border:"1px solid #000", 
        padding: "1.4em",
        paddingLeft: "2em"
    }
}))

export const PopupAlertes = () => {
    const dialogLAPADEContext   = useContext(DialogLAPADEContext);
    const classes = useClasses();
    return (<>
        <center>
            <Typography variant="h6" className={classes.titleTypo}>
                Contactez nous pour activer <b>La PADE Alertes</b>
            </Typography><br />
            <Button href="/#/contact" onClick={()=>dialogLAPADEContext.toggleAlert()}variant="contained" color="secondary">Nous contacter</Button ><br />
            <br />
        </center>

        <Typography variant="h6" className={classes.titleTypo}>Présentation de La PADE alertes</Typography>
        <br />

        Aujourd’hui, la protection de l’environnement est devenu un véritable <b>enjeu sociétal</b> (crise
        énergétique, changement climatique, pollutions des sols, cours d’eau et mers…)<br /><br />
        C’est pourquoi, la <b>priorité</b> de <b>la PADE</b> est la résolution des pollutions sauvages, la transition écologique et le développement durable.
        
        <br /><br />
        <Typography variant="h6" className={classes.titleTypo}>La PADE Alertes et ses avantages.</Typography>
        <br />
        La <b>PADE alertes</b> est un espace d’administration vous accompagnant dans la <b> gestion des dépôts sauvages </b> et de leur suivi, sur la base des alertes fournies par Clean2gether. À la fin du traitement de l’alerte, vous pourrez y déposé un bilan afin de conclure le suivi.
        <br /><br />
        <Grid container spacing={6}>
            <Grid item sm={6} xs={12} >
                <Typography variant="h6" className={classes.titleTypo}><b>Objectif de la PADE Alertes</b></Typography><br />
                <ul className={classes.ulPopup}>
                    <li><span style={{color:"#0bc3c1"}}>01</span> Recevez des alertes en temps réel.</li>
                    <li><span style={{color:"#23b2c1"}}>02</span> Visualisez-les immédiatement sur la carte.</li>
                    <li><span style={{color:"#34a3c1"}}>03</span> Commencez à les traiter immédiatement.</li>
                </ul>
            </Grid>
            <Grid item sm={6} xs={12} >
                <img src="/popup/01.jpg" alt="popup alerte 01" width="100%" height="auto"></img>
            </Grid>

            <Grid item sm={6} xs={12} >
                <img src="/popup/02.jpg" alt="popup alerte 02" width="100%" height="auto"></img>
            </Grid>
            <Grid item sm={6} xs={12} >
                <Typography variant="h6" className={classes.titleTypo}><b>Comment assuré le suivi ?</b></Typography><br />
                <ul className={classes.ulPopup}>
                    <li><span style={{color:"#0bc3c1"}}>01</span> Faite des retours à l’utilisateur sur la prise en charge de l’alerte qu’il a lancé.</li>
                    <li><span style={{color:"#23b2c1"}}>02</span> Savoir où vous en êtes dans le traitement des alertes.</li>
                    <li><span style={{color:"#34a3c1"}}>03</span> Garder un historique des actions menées pour chaque alerte.</li>
                </ul>
            </Grid>
            

            <Grid item sm={6} xs={12} >
                <Typography variant="h6" className={classes.titleTypo}><b>Bilan des alertes et des collectes</b></Typography><br />
                <ul className={classes.ulPopup}>
                    <li><span style={{color:"#0bc3c1"}}>01</span> Obtenir des informations sur chaque dépôt sauvage de déchets ou de collectes. </li>
                    <li><span style={{color:"#23b2c1"}}>02</span> Identifier les typologies de déchets récurrents. </li>
                    <li><span style={{color:"#34a3c1"}}>03</span> Décider d’actions à mener sur la base d’informations factuelles.</li>
                </ul>
            </Grid>
            <Grid item sm={6} xs={12} >
                <img src="/popup/03.jpg" alt="popup alerte 03" width="100%" height="auto"></img>
            </Grid>
        </Grid>
        <br />
        <center>Vous souhaitez adhérer à <b>la PADE</b> Alertes, veuillez nous contacter en cliquant sur le lien ci-dessous<br /><br />

        <Button href="/#/contact" onClick={()=>dialogLAPADEContext.toggleAlert()}variant="contained" color="secondary">Nous contacter</Button ><br />
        
        </center>
    </>)
}