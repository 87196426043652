import PropTypes from 'prop-types';

export const AlertPosition = ({ source, record = {} }) => {
    
    return(<span>
        <p>{record.town} </p>
        <p>{record.postcode}</p>
    </span>);
}

AlertPosition.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
};