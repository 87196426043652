import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Radio, Box } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import api, {fetchToken} from '../../../api'


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function AddEventDialog({alertId, disabled = false, icon = null}) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [commentaire, setCommentaire] = React.useState("");
  const [images, setImages] = React.useState([])

  const handleClickOpen = () => {
    window.location.replace('/#/alerteventcreate/'+ alertId)
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    let data = {
      status: status,
      description: commentaire,
      alert: alertId
    };

    let toUpload = []
    images.forEach(image => {
      toUpload.push(image.file)
    })

    fetchToken(api.url + `/alert-events`, {
      method: "POST",
      body:JSON.stringify(data),
    },toUpload).then(({json}) => {
      window.location.reload();
    })
  }

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleInputImages = (event) => {
    let uploadedFiles = [];
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      uploadedFiles.push({
        base64: URL.createObjectURL(files[i]),
        file: files[i]
      })
    }
    setImages(uploadedFiles);
  }

  return (
    <div>
      <Button 
        variant="outlined" 
        color="primary" 
        onClick={handleClickOpen} 
        disabled={disabled} 
        startIcon={icon}>
        Ajouter un suivi
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ajout d'un suivi</DialogTitle>
        <DialogContent>
          <DialogContentText>
            
          </DialogContentText>

            <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
            <RadioGroup aria-label="Type" name="status" value={status} onChange={handleChange}>
                <FormControlLabel value="PROCESSING" control={<Radio />} label="Procédure en cours" />
                <FormControlLabel value="RESOLVED" control={<Radio />} label="Résolu" />
                {/* <FormControlLabel value="PROCESSING" control={<Radio />} label="Intervention planifié" /> */}
                {/* <FormControlLabel value="UPDATE" control={<Radio />} label="Mise à jour, Infos complémentaire" /> */}
                {/* <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
            </RadioGroup>
            </FormControl>
            
            <TextField
              style={{marginTop:"20px"}}
              autoFocus
              margin="dense"
              id="name"
              value={commentaire}
              label="Commentaires"
              onChange={(e) => setCommentaire(e.target.value)}
              type="email"
              fullWidth
              multiline
            />
          <Box mb={6}></Box>
     
          {images.map((image, index) => {
            return <img width="100" src={image.base64} alt={"upload"+index} key={"upload"+index}/>
          })}
          <input accept="image/*" id="icon-button-file" type="file" multiple className={classes.input} onChange={handleInputImages}/>
          <label htmlFor="icon-button-file">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <PhotoCamera />
            </IconButton>
          </label>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function UploadButtons() {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            Upload
          </Button>
        </label>
        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <PhotoCamera />
          </IconButton>
        </label>
      </div>
    );
  }