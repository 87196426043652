import React from "react";

import {
    TextField,
    RichTextField,
    EditButton,
    ShowButton,
    useTranslate
} from "react-admin";

import { useSelector } from 'react-redux'; 
import { Card, CardActions, CardContent, CardActionArea, CardHeader, CardMedia } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {strapiImage} from '../../../api';
import { DateMomentField } from '../../Field/DateMomentField';
import { AlertImagesGalleryField } from '../../Field/AlertImagesGalleryField';
import { ShowDetailQuantity } from '../../UI/DetailQuantity'

export const AlertEventCard = ({event, basePath, showcard = true}) => {
    
    let history = useHistory();
    const { user } = useSelector(state => state.account)
    const translate = useTranslate();
    const ConditionalButton = (event) => {
        if (event?.user?.collectivite === user?.collectivite?.id) {
            return (<EditButton resource="alert-events" basePath={basePath} record={event} />)
        } else {
            return (<ShowButton resource="alert-events" basePath={basePath} record={event} />)
        }
    }
    const redirectClick = () => {
        if (event?.user?.collectivite === user?.collectivite?.id) {
            history.push("/alert-events/"+event.id);
        } else {
            history.push("/alert-events/"+event.id+"/show");
        }
    }

    const img = strapiImage(event?.cover, showcard?'<':'>', 'event.cover');
    const imgPadding = img?.height&&img?.width?(img.height * 100 / img.width):36;

    return (
        <Card key={event.id} elevation={8}>
            <CardActionArea onClick={redirectClick}>
                <CardMedia
                    style={{height:0, paddingTop:imgPadding+"%"}}
                    image={strapiImage(event?.alert?.images[0], showcard?'<':'>', 'alert.cover').url}
                    title={event.title}
                />
                <CardHeader
                    title={translate('alert.status.'+event.alert?.title)} //<TextField record={event} label="Titre evenement" source="alert.title" />
                    subheader={<>
                        Signalé: <DateMomentField record={{...event.alert}} label="Date" source="created_at" format={false} />
                    </>}
                />
                <CardContent style={{minHeight:'50px'}}>
                    <TextField record={event} label="Lieux" source="town" /> <TextField record={event} label="Lieux" source="postcode" /><br />
                    <TextField record={event} label="Lieux" source="epci" />
                </CardContent>
                <CardHeader
                    title={<>{translate('event.status.'+event.title)} <DateMomentField record={event} label="Date" source="updated_at" /></>}
                />
                <CardContent>
                    <ShowMoreRichTextField initOpen={!showcard}>
                        <RichTextField record={event} source="description" label="Description" style={{ width: '10px' }}/>
                    </ShowMoreRichTextField>
                    {(event?.images?.length > 0) && 
                        <AlertImagesGalleryField record={event} source="images" maxWidth="sm" />
                    }
                </CardContent>
            </CardActionArea>
            <ShowDetailQuantity report={event?.report}/>
            {showcard && 
            <CardActions >
                {ConditionalButton(event)}
            </CardActions>}
        </Card>
    )
}

const ShowMoreRichTextField = ({children, initOpen = false}) => {
    const open = initOpen;
    const MIN_HEIGHT = 40;
    const BORDER_HEIGHT = 40;

    return (
        <div style={{maxHeight: !open?MIN_HEIGHT+50:999, minHeight: MIN_HEIGHT, overflow:"hidden", position: "relative", maxWidth:"100%"}} >
            {children}
            <div style={{position:open?"relative":"absolute", top: open?0:MIN_HEIGHT+10,  height: BORDER_HEIGHT, width: "100%", background: open?"none":"linear-gradient(#FFFFFF00, #FFFFFFFF)", textAlign:"center"}}>
            </div>
        </div>
    )
}
