// in src/NotFound.js
import * as React from "react";
import {Card, GridList, GridListTile } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {strapiImage} from '../../api'

export const CollectivityChild = () => {
    const { user } = useSelector(state => state.account);
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('md'));
    const xs = useMediaQuery(theme.breakpoints.up('sm'));
    
    return (
        <Card>
            <Title title="Collectivités Affiliées" />
            <CardContent>
                <h3 align="center">Collectivités Affiliées</h3>
                { user?.collectivite?.child && <>
                    <GridList cellHeight={100} cols={ sm ? 3 : (xs ? 2 : 1) } >
                        {user.collectivite.child.map((collectivity, index)=> {
                            return (
                                <GridListTile key={index+"g"} cols={1} align="center" style={{padding:"10px"}}>
                                    <>
                                        {collectivity.title}
                                        {collectivity.logo && <><br /><img height="70%" alt={collectivity.title+" logo"} src={strapiImage(collectivity.logo, '-').url}/> </>} 
                                    </>
                                </GridListTile>
                            )
                        })}
                    </GridList></>
                }
            </CardContent>
        </Card>
    );
}
