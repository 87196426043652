// in src/MyAppBar.js
import * as React from 'react';
import { AppBar, UserMenu, MenuItemLink, RefreshButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { toggleSidebar } from 'ra-core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import api from '../../api';

const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    root: {
        //background: 'linear-gradient(45deg, #50BCB8 30%, #81C34F 90%)',
        background: 'linear-gradient(45deg, '+theme.palette.secondary.main+' 30%, '+theme.palette.primary.main+' 90%)',
        border: 0,
        borderRadius: 1,
        boxShadow: '0 2px 2px 2px #00040',
        color: 'white',
        // height: 48,
        padding: '0 0px',
    },
    logo: {
        maxWidth: 70,
        maxHeight: 47,
        marginRight: '17px'
    }
}));

const AppBarUserMenu = (props) => {
    const identity = props.identity;

    if (!identity?.username) {
        return (
            <UserMenu {...props}>
                <MenuItemLink
                    to="/login"
                    primaryText="Mon Compte"
                    leftIcon={<AccountCircleIcon />}
                />
                <RefreshButton disabled/>
            </UserMenu>
        )
    }
    return (<>
        <UserMenu {...props}>
            <MenuItemLink
                to="/account"
                primaryText="Mon Compte"
                leftIcon={<AccountCircleIcon />}
            />
        </UserMenu>
        {identity?.username}
        </>
    )
}

const CustomAppBar = props => {
    const { user } = useSelector(state => state.account);
    // const { filter } = useSelector(state => state.area);
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    // const setLocale = useSetLocale();
    const dispatch = useDispatch();
    const classes = useStyles();
    return (
        <AppBar 
            userMenu={<AppBarUserMenu identity={user}  />}
            className={classes.root}
            {...props}
        >
            <img src={api.siteSettings.logoHeader} alt="logo" className={classes.logo} onClick={()=>dispatch(toggleSidebar())}/>
            {!isXSmall && 
                <>
                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                    id="react-admin-title"
                />
                    {/* {"Zone: "+filter} */}
                </>
            }
            <span className={classes.spacer} />
            {/* <div>Language</div>
            <Button onClick={() => setLocale('en')}>English</Button>
            <Button onClick={() => setLocale('fr')}>French</Button> */}
            {/* {user?.username} */}
        </AppBar>
    );
};

export default CustomAppBar;