import { Status } from '../helpers/Status'



const userReducer = (state = 0, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'USER_INIT':
            return {
                user: {},
                collectivity: {},
                loading: false
            }
        case 'USER_REFRESH_START':
            return {
                ...state,
                loading: true
            }

        case 'USER_REFRESH_FAILED':
            return {
                ...state,
                loading: false
            }

        case 'USER_REFRESH_SUCCESS':
            // console.log("USER_REFRESH_SUCCESS")
            return {
                ...state,
                user: payload.user,
                status: Status(payload.user),
                tutorial: payload.tutorial,
                loading: true
            }
            
        default:
            return state;
    }
};

export default userReducer;