import PropTypes from 'prop-types';
import {strapiImage} from '../../api'
import ImageZoom from 'react-medium-image-zoom'


export const ImagesLiteField = ({ source, record = {}, label }) => {

    let images = record[source];

    if (images && images?.id > 0) {
        images = [images];
    }

    if (images && images.length > 0) {
        return images.map(image => {
            
            //Default original url
            let urlThumb = strapiImage(image, '-').url;
            let urlLarge = strapiImage(image, '>').url;
            const alt = record?.postcode + " " + record?.town +" "+ record?.created_at;

            return (
                <ImageZoom
                    label={"AAAAA"}
                    openText={"AAA"}
                    key={image.id}
                    image={{
                        src: urlThumb,
                        key:image.id,
                        alt: alt,
                        className: 'img',
                        style: { width: '80px' }
                    }}
                    zoomImage={{
                        src: urlLarge,
                        alt: alt
                    }}
                >
                </ImageZoom>
            )
        })
    }
    return(<span>
        X
    </span>);
}

ImagesLiteField.propTypes = {
label: PropTypes.string,
record: PropTypes.object,
source: PropTypes.string.isRequired,
};