import L from "leaflet";
import api from "../api";

export const MarkerAlert = new L.Icon({
    iconUrl: '/markers/alert-marker.png',
    iconSize: [39, 49],
    iconAnchor: [19,49]
});

export const MarkerAlertResolved = new L.Icon({
  iconUrl: '/markers/alert-marker-resolved-green.png',
  iconSize: [39, 45],
  iconAnchor: [19,45]
});

export const MarkerAlertProcessing = new L.Icon({
  iconUrl: '/markers/alert-marker-processing.png',
  iconSize: [39, 45],
  iconAnchor: [19,45]
});

export const iconByValue = (properties) => {
  if (properties.status === "RESOLVED") {
      return MarkerAlertResolved
  } else if(properties.status === "PROCESSING") {
    return MarkerAlertProcessing
  } else {
    return MarkerAlert
  }
}
// console.log(api.site);
const color1 = (api.site === "C2G")?{
  // C2G
  r:180,
  g:137,
  b:33
}:{
  // LAPADE
  r:180,
  g:137,
  b:33
}

const color2 = (api.site === "C2G")?{
  // C2G
  r:135,
  g:202,
  b:79
}:{
  // LAPADE
  r:80,
  g:188,
  b:184
}

function lerpRGB(color1, color2, t) {
  let color = {};

  if (t>1) {
      return color2;
  }
  if (t<0) {
      return color1;
  }
  color.r = parseInt(color1.r + ((color2.r - color1.r) * t));
  color.g = parseInt(color1.g + ((color2.g - color1.g) * t));
  color.b = parseInt(color1.b + ((color2.b - color1.b) * t));
  return color;
}

const rgbToHex = (red, green, blue) => `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}`;

function markerColor(count, resolved) {
  const t = count?(resolved / (count/3)):0;
  const i = lerpRGB(color1, color2, t);

  // console.log("count", count, "resolved", resolved, "color", rgbToHex(i.r, i.g, i.b), "color", i, "t", t);
  return (rgbToHex(i.r, i.g, i.b))
}

const icons = {};
export const fetchIcon = (count, resolved, id) => {
  
  const classMarker = (api.site === "C2G")?"cluster-marker-c4g":"cluster-marker";
  
  const size = (Math.sqrt(count)*1.5)+15;
  if (!icons[id]) {
      icons[id] = L.divIcon({
          html: `<div class="${classMarker}" data-count="${count}" data-resolved="${resolved}"
          style="
              width: ${size}px; 
              height: ${size}px; 
              margin-left: -${size/2}px; 
              margin-top: -${size/2}px;
              background-color: ${markerColor(count, resolved)}DD;
          ">${count}</div>`
      });
  }
  return icons[id];
  };