import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'; 
import { Box, Card, CardActionArea, CardHeader, CardMedia, Divider, Grid, Paper, Typography, CardContent, useTheme } from "@material-ui/core"
import { List, useListContext, useRefresh } from "react-admin"
import { Redirect, useHistory } from "react-router-dom"
import VerifiedUserIcon           from '@material-ui/icons/VerifiedUser';
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { ContentHeaderTuto } from "../../UI/TutoVideo";
import EventNoteIcon from '@material-ui/icons/EventNote';
import { dateFrom } from "../../../helpers/moment";
import api, { fetchToken, strapiImage } from "../../../api";
import { FilterSelectArea } from "../../UI/FilterSelectArea/FilterSelectArea";


export const BilanList = (props) => {

    const { status } = useSelector(state => state.account);
    const { filter } = useSelector(state => state.area);
    const [defaultFilter, setDefaultFilter] = useState(filter);
    const refresh = useRefresh();
    const theme = useTheme();
    useEffect(()=>{
        if (filter !== defaultFilter) {
            // console.log("refresh");
            setDefaultFilter(filter);
            refresh();
        }
      // eslint-disable-next-line
    },[filter])

    if (!status)
        return (null);

    if (!status.valid && status.type==="Association") {
        return (<Redirect push to="/space"></Redirect>)
    } else if (!status.valid) {
        return (<Redirect push to="/dashboard"></Redirect>)
    } else if (!status?.alert) {
        return (<Redirect push to="/dashboard"></Redirect>)
    }

    return (
    <>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Paper>
                    <Grid container >
                        <Grid item xs={12} md={9}>
                            <FilterSelectArea contour={false}/>
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Box display="flex" alignItems="center" justifyContent="center" style={{height:"100%"}}>
                            {/* <center> */}
                                <SumBilan filter={filter}></SumBilan>
                            {/* </center> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardHeader  style={{paddingBottom:"0px"}}
                        title={<ContentHeaderTuto title={<Typography  variant="h5">Liste des Bilans</Typography>} type="Bilan"/>}
                        avatar={<VerifiedUserIcon style={{color:theme.palette.secondary.main}} />}
                    ></CardHeader>
                    <CardContent>
                        <List {...props} 
                            actions={null}
                            title="Tous les bilans"
                            exporter={false}>
                            <BilanGrid />
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>)
}

const SumBilan = ({filter}) => {

    const [total, setTotal] = useState("");
    useEffect(()=>{
        fetchToken(api.url + `/bilans/sumTotal`).then(({json}) => {
            // console.log(json)
            const total = json.total
            setTotal(Math.round(total * 100) / 100);
        });
      // eslint-disable-next-line
    },[filter])

    return (
        // <> <b>{total}</b> Kg
        // <TextField label="Total pour la zone" value={9} record={{total:total}} disabled source="total" variant="outlined"></TextField>
        <center>
        <Typography component="p" variant="caption">Total pour la zone:</Typography>
        <Typography component="p" variant="h6">{total} Kg</Typography>
        </center>
    )
}

const BilanGrid = (props) => {
    const { ids, data } = useListContext();
    
    return (
        // <div style={{margin:12}}>
            <Grid container spacing={2}>
                {ids.map(id => 
                <Grid item xs={12} sm={6} md={4} lg={3} key={data[id].type+"_"+id}>
                    <BilanCard item={data[id]} />
                </Grid>
                )}
            </Grid>
        // </div>
    )
}

const BilanCard = ({item}) => {

    const history = useHistory();
    let img;

    let subtitle = false;
    if (item.type === "alert") {
        img = strapiImage(item?.images[0], '<', 'bilan.cover');
        subtitle = item?.report_title?item?.report_title:"Bilan Alerte #"+item.id;
    } else {
        img = strapiImage(item?.cover, '<', 'bilan.cover');
        subtitle = item?.report_title?item?.report_title:"Bilan "+item?.title;
    }

    const link =  item.type === "alert"?
        '/alerts/'+item.id+'/show/4' 
        :
        '/events/'+item.id+'/5';

    return (
        <Card>
            <CardActionArea onClick={()=>{history.push(link)}}>
                <CardHeader 
                    title={item.town +" " + item.postcode}
                    subheader={dateFrom(item.updated_at)}
                    avatar={item?.type === "alert"?<NotificationsActiveIcon color="primary"/>:<EventNoteIcon color="primary"/>}
                ></CardHeader>
                <Divider />
                <CardMedia
                    style={{height:0, paddingTop:100+"%"}}
                    image={img.url}
                    title={item.title}
                />
                <CardHeader 
                    subheader={"Déchets ramassés : "+item.report_info.total+" Kg"}
                    title={subtitle}
                    avatar={<VerifiedUserIcon color="primary"/>}
                ></CardHeader>
            </CardActionArea>
        </Card>
    )
}

// -Ajouter titre bilan
// -Alert Total ramassé : 11.5Kg en gras !
// -Lien event target _blank
