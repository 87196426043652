import {
    useRecordContext
} from "react-admin";

export const AlertStatusField = (props) => {

    const record = useRecordContext(props);

    if (record.status === "RESOLVED")
        return (<img alt="marker_alert" src="/markers/alert-marker-resolved.png"></img>)
    if (record.status === "PROCESSING")
        return (<img alt="marker_alert" src="/markers/alert-marker-processing.png"></img>)
    return (<img alt="marker_alert" src="/markers/alert-marker.png"></img>)
}