import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Card, CardContent, CardMedia, CardHeader, CardActionArea, Grid, Typography, Button, Slide, Dialog, AppBar, Toolbar, IconButton, useTheme } from '@material-ui/core';
import { CardActions, Title } from 'react-admin';
import { useSelector } from 'react-redux'; 
import { makeStyles } from '@material-ui/core/styles';
import {  Redirect } from "react-router-dom"

import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import api, {fetchToken, strapiImage } from '../../api'

const useStyles = makeStyles((theme) => {
    // console.log(theme);
    return (
    {
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    titleTraining: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        padding: "10px",
        borderRadius: theme.shape.borderRadius,
        marginBottom: "0px"
        
    }
    }
    )
    }
    );

export const TrainingDocument = () => {
    const [trainings, setTrainings] = useState(null);
    const location = useLocation();
    const classes = useStyles();
    const { status } = useSelector(state => state.account);
    const theme = useTheme();

    useEffect(() => {
        fetchToken(api.url + '/trainings').then(({json}) => {
            // console.log(json)
            setTrainings(json);
        })
    }, [location.pathname])


    if (!status?.training) {
        return (<Redirect push to="/dashboard"></Redirect>)
    }

    return (
    <>
        <Title title="Tableau de bord"></Title>
        <Card>
            <CardHeader  style={{paddingBottom:"0px"}}
                title={<Typography  variant="h5">Modèles de documents</Typography>}
                avatar={<AssignmentIcon style={{color:theme.palette.secondary.main}} />}
                
            ></CardHeader>
            <CardContent>
                <Typography variant="subtitle1">
                    Découvrez l’ensemble des modèles de documents présentés dans nos séminaires en ligne. <br />Vous pouvez accéder aux vidéos explicatives en cliquant sur le bouton vidéo.
                </Typography>
                <br />
                { (trainings && trainings?.length > 0) && 
                    <>
                        { trainings.map((training) => {
                            if (training?.state === "DRAFT") return (null);
                            if (training?.state === "COMING_SOON") return (null);
                            return (<>
                                <Typography variant="h6" className={classes.titleTraining}>
                                    {training.Title}
                                </Typography>
                                <br />
                                <Grid container spacing={2}>
                                    { training?.training_documents.map((document) => {
                                        if (document?.document?.mime === "application/pdf") {
                                            return (
                                                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                                                    <Document document={document} />
                                                </Grid>
                                            )
                                        } else {
                                            return (null);
                                        }
                                    })}
                                </Grid>
                            </>)
                        }) }
                    </>
                }
            </CardContent>
        </Card>
    </>
    )
}

const Document = ({document}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    return (
        <Card>
            <CardActionArea disabled={!document?.link} href={"/#"+document?.link} onClick={()=>{setOpen(open+1)}}>
                
                <CardMedia
                    className={classes.media}
                    image={strapiImage(document.image).url}
                    title="Paella dish"
                />
                <CardHeader subheader={document?.title} style={{minHeight:"100px"}}></CardHeader>
                
            </CardActionArea>
            <CardActions style={{ justifyContent: "center"}}>
                <div className={classes.buttons}>
                    {/* <center> */}
                        <Button
                            variant="contained"
                            color="secondary"
                            size='small'
                            // className={classes.button}
                            startIcon={<GetAppIcon />}
                            href={api.url+document?.document?.url}
                            target="_blank"
                        >
                            Télécharger
                        </Button>
                        { document?.link && 
                            <Button
                                size='small'
                                variant="outlined"
                                color="primary"
                                // className={classes.button}
                                startIcon={<PlayArrowIcon />}
                                href={"/#"+document.link}
                            >
                                Vidéo
                            </Button>
                        }
                        {/* <FullScreenDialog document={document} o={open}/> */}
                    {/* </center> */}
                </div>
            </CardActions>
        </Card>
    )
}


const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

export default function FullScreenDialog({document, o}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(o);
    const googleViewer = (pdfUrl) => ("https://docs.google.com/viewerng/viewer?url="+pdfUrl+"&embedded=true")

    // console.log(o)
    useEffect(()=>{
        if (o) {
            setOpen(true);
        }
    }, [o])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
        <Button variant="outlined" color="primary" onClick={handleClickOpen} startIcon={<VisibilityIcon />}>
            Voir
        </Button>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                {document.title}
                </Typography>
                {/* <Button autoFocus color="inherit" onClick={handleClose}>
                save
                </Button> */}
            </Toolbar>
            </AppBar>
            <br /><br /><br />
            <iframe src={googleViewer(api.url+document.document.url)} title={"previewPDF"+document.id} id={"previewPDF"+document.id} frameborder="0" height="100%" width="100%"></iframe>
        </Dialog>
        </>
    );
}