import React from 'react';

import { Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

export const AuthLayoutDecorator = () => {
  const classes = authStyle();
  return (
    <Avatar className={classes.avatar} style={{}}>
      <LockOutlinedIcon />
    </Avatar>
  )
}

export const authStyle = makeStyles((theme) => {
    return ({
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
    })
  }
);