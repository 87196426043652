import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
} from "@material-ui/core";
import { Link } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import api, {fetchToken} from '../../../api'
import { useSelector } from 'react-redux'; 

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: "90px"
    //minHeight: "100px;",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bigData: {
    fontSize: "35px",
    color: theme.palette.secondary.main,
  },
  link: {
    textDecoration:'none',
    color: "inherit"
  }
}));

export const WidgetsDashboard = ({children = null, line = false}) => {

  const [nbAlerte, setNbAlerte] = useState(0);
  const [nbAlerteResolved, setNbAlerteResolved] = useState(0);
  const [nbAlerteSuivis, setNbAlerteSuivis] = useState(0);
  const [nbCollectivityChild, setNbCollectivityChild] = useState(0);

  const { filter } = useSelector(state => state.area);

  useEffect(() => {
      fetchToken(api.url + `/alerts/count`).then(({json}) => {
        setNbAlerte(json)
      })
      fetchToken(api.url + `/alerts/count?status=RESOLVED`).then(({json}) => {
        setNbAlerteResolved(json)
      })
      fetchToken(api.url + `/alerts/count?alert_events_gt=0`).then(({json}) => {
        setNbAlerteSuivis(json)
      })
      fetchToken(api.url + `/collectivities/nbchild`).then(({json}) => {
        setNbCollectivityChild(json)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const classes = useStyles();
  if (!line)
  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="stretch"
      direction="row"
    >
      {nbCollectivityChild>0 && 
        <Grid item xs={12}>
          <Paper className={classes.paper}>
              <span className={classes.bigData}>{nbCollectivityChild}</span> <br />
              <Link to="/collectivity-child">Communes inscrites
            </Link>
          </Paper>
        </Grid>
      }
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <span className={classes.bigData}>{nbAlerte}</span> <br />
          <Link to='/alerts?displayedFilters=%7B%7D&filter=%7B%7D' className={classes.link}>
            Alertes émises
          </Link>
        </Paper>
      </Grid>
      <Grid item xs={12}>
          <Link to='/alerts?displayedFilters=%7B"alert_events_gte"%3Atrue%7D&filter=%7B"alert_events_gte"%3Atrue%7D' className={classes.link}>
        <Paper className={classes.paper}>
          <span className={classes.bigData}>{nbAlerteSuivis}</span> <br />
            Suivis
        </Paper>
          </Link>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <span className={classes.bigData}>{nbAlerteResolved}</span> <br />
          <Link to='/alerts?displayedFilters=%7B%7D&filter=%7B"status"%3A"RESOLVED"%7D' className={classes.link}>
            Alertes résolues
          </Link>
        </Paper>
      </Grid>
    </Grid>
  );
  else 
  return (
    <Grid
    container
    spacing={1}
    justifyContent="space-between"
    alignItems="stretch"
    direction="row"
  >
    {nbCollectivityChild>0 && 
      <Grid item md={3}>
        <Paper className={classes.paper}>
            <span className={classes.bigData}>{nbCollectivityChild}</span> <br />
            <Link to="/collectivity-child">Communes inscrites
          </Link>
        </Paper>
      </Grid>
    }
    <Grid item md={3}>
      <Paper className={classes.paper}>
        <span className={classes.bigData}>{nbAlerte}</span> <br />
        <Link to='/alerts?displayedFilters=%7B%7D&filter=%7B%7D' className={classes.link}>
          Alertes émises
        </Link>
      </Paper>
    </Grid>
    <Grid item md={3}>
        <Link to='/alerts?displayedFilters=%7B"alert_events_gte"%3Atrue%7D&filter=%7B"alert_events_gte"%3Atrue%7D' className={classes.link}>
      <Paper className={classes.paper}>
        <span className={classes.bigData}>{nbAlerteSuivis}</span> <br />
          Suivis
      </Paper>
        </Link>
    </Grid>
    <Grid item md={3}>
      <Paper className={classes.paper}>
        <span className={classes.bigData}>{nbAlerteResolved}</span> <br />
        <Link to='/alerts?displayedFilters=%7B%7D&filter=%7B"status"%3A"RESOLVED"%7D' className={classes.link}>
          Alertes résolues
        </Link>
      </Paper>
    </Grid>
  </Grid>
  )
};