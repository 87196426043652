import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useRecordContext, useListContext } from 'react-admin';
import { Map, Marker  } from 'react-leaflet'
import { MapLayerControl } from '../UI/Map/MapLayerControl';
import { iconByValue} from '../../helpers/Markers';


export const MapField = (props) => {

    const mapStyle = {
        boxShadow: "rgb(157 196 65) 0px 0px 3px",
        width:'100%', 
        height:'60vh',
        marginBottom: '20px',
        borderRadius: '2px'
    }

    const record = useRecordContext(props);
    const { 
        source = "",
        zoom = 16,
        scrollWheelZoom= true
    } = props;

    const latitude = record[source + 'latitude'];
    const longitude = record[source + 'longitude'];

    if (!latitude || !longitude) {
        return (null);
    }

    return (
        <Map center={[latitude, longitude]} zoom={zoom} scrollWheelZoom={scrollWheelZoom} style={mapStyle}>
            <MapLayerControl/>
            <Marker position={[latitude, longitude]} icon={iconByValue(record)}></Marker>
        </Map>
    )
}

MapField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};

export const MapList = (props) => {
    const {data, ids} = useListContext();
    const [points, setPoints] = useState([])

    useEffect( () =>{
        setPoints(ids.map(id => {
            return ({
                lat:data[id].latitude, 
                lon: data[id].longitude, 
                id: data[id].id})
        }))
    }, [data, ids])

    const markers = (points) => {
        return points.map(point => 
            <Marker key={point.id} position={[point.lat, point.lon]}></Marker>
    )}
    
    return (
        <Map center={[45, 0.5]} zoom={8} scrollWheelZoom={true} style={{width:'100%', height:'60vh'}}>
            <MapLayerControl/>
            {markers(points)}
        </Map>
    )
}

MapList.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
};