import React from 'react';

import { 
    Typography, 
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: "bold"
    },
    summary: {}
}));

export const TrainingFAQAccordion = ({data}) => {
    const classes = useStyles();
    const Content = data?.Content;

    if (!Content) {
        return (null);
    }
    return (
        <div>
            {Content && Content.map((item) => {
                return (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={"panel"+item.id+"a-content"}
                            id={"panel"+item.id+"a-header"}
                            className={classes.summary}
                        >
                            <Typography className={classes.heading}>{item.Question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {item.Answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    )
}