import frenchMessages from "ra-language-french";
import englishMessages from "ra-language-english";


export const frMessagesPlus = {
    ...frenchMessages,
    ressources: {
        event: {
            name: 'Evenement |||| Evenements'
        }
    },
    bilan: {
        quantity: {
            plastic: "Plastique",
            metal: "Métal",
            glass: "Verre",
            butts: "Mégots",
            all: "Tout venants",
            other: "Autres"
        }
    },
    event: {
        status: {
            PROCESSING: 'Procédure en cours',
            RESOLVED: 'Résolu'
        }
    },
    alert: {
        status: {
            DECHARGE_SAUVAGE: 'Décharge sauvage',
            OTHER: 'Autre'
        }
    },
    training: {
        state: {
            DRAFT: "Brouillon",
            AVAILABLE: "Disponible",
            COMING_SOON: "A venir"
        }
    }
}

export const enMessagesPlus = {
    ...englishMessages,
    ressources: {
        event: {
            name: 'Evenement |||| Evenements'
        }
    },
    bilan:{
        quantity: {
            plastic: "Plastic",
            metal: "Métal",
            glass: "Verre",
            butts: "Mégots",
            all: "Tout venants",
            other: "Autres"
        }
    }
}

export const i18nCustomTranslation = (locale) => {
    // console.log("locale?", locale);
    if (locale === "en")
        return enMessagesPlus
    return frMessagesPlus
}