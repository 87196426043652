import React, { useState, useRef, useEffect } from "react";
import { Map, Marker } from 'react-leaflet'
import useSupercluster from "use-supercluster";
import api, {fetchToken} from '../../../api';
import { useSelector } from 'react-redux'; 
import { GeoJSON } from 'react-leaflet';
import { MapLayerControl } from './MapLayerControl';
import { iconByValue, fetchIcon } from '../../../helpers/Markers';

import './MapField.css'
import { Dialog } from "@material-ui/core";
import { AlertShowCard } from "../../Resources/Alert/AlertShowCard";
import { useTheme } from "@material-ui/core/styles";
import { generateExtraQuery } from "../../../api/config";

export const NewClusterMap = ({search, onMove, onClick, disabled = false}) => {

  const mapRef = useRef();
  const { filter, bbox, geometry , polygon, date_start, date_end } = useSelector(state => state.area);
  const [bounds, setBounds]   = useState();
  const [zoom, setZoom]       = useState(9);
  const [markers, setMarkers] = useState([])
  const geoJsonLayer = useRef(null);
  const theme = useTheme();

  const [alertOpen, setAlertOpen] = useState(false);

  const handleMoveEnd = (e) => {
    if (mapRef?.current?.leafletElement) {
      const b = mapRef.current.leafletElement.getBounds();
      const newBounds = [
        b.getSouthWest().lng,
        b.getSouthWest().lat,
        b.getNorthEast().lng,
        b.getNorthEast().lat
      ]

      onMove(newBounds)
      setBounds(newBounds);
      setZoom(mapRef.current.leafletElement.getZoom());
    }
  }

  useEffect(() => {
    
    const extraQuery = generateExtraQuery(date_start, date_end);
    // const extraQuery = "?a=a&date_start="+(date_start?date_start:"")+"&date_end="+(date_end?date_end:"");
    // console.log(api.url + `/alertsGeoloc`+extraQuery);
      fetchToken(api.url + `/alertsGeoloc`+extraQuery).then(({json}) => {
          setMarkers(json.map(point => ({
              type: "Feature",
              properties: {cluster: false, id: point.id, resolved: point.resolved, status: point.status},
              geometry: {
                  type: "Point",
                  coordinates: [ point.lon, point.lat ]
              }
          })));
          handleMoveEnd()
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, date_start, date_end])

  useEffect(() => {
    if (bbox?.length === 4) {
      mapRef?.current?.leafletElement?.fitBounds([[bbox[1], bbox[0]],[bbox[3], bbox[2]]]);
    }
  }, [bbox])

  useEffect(() => {
    if (polygon) {
        geoJsonLayer.current?.leafletElement.clearLayers().addData(geometry);
    }
  }, [geometry, polygon])

  return (
    <div style={{position:"relative"}}>
      <Dialog onClose={()=>setAlertOpen(false)} open={!!alertOpen}>
        <MapAlertShowCard alert={alertOpen}></MapAlertShowCard>
      </Dialog>
      <Map center={[45.7, 0.2]} zoom={zoom} scrollWheelZoom={true} style={{width:'100%', height:'60vh'}} onMoveEnd={handleMoveEnd} ref={mapRef}>
          
          <MapLayerControl/>
          <SuperCluster points={markers} bounds={bounds} zoom={zoom} mapRef={mapRef} onClickMarker={setAlertOpen}/>
          {(polygon && geometry) && <GeoJSON color={theme.palette.secondary.main+"75"} data={geometry} ref={geoJsonLayer} />}
      </Map>
      { disabled && 
      <div 
        style={{position:"absolute", top:"0px", left:"0px", height:"100%", width:"100%", zIndex: "90000"}} 
        onClick={(event)=>{event.preventDefault();event.stopPropagation()}} />
      }
    </div>
  )
}

const SuperCluster = ({points, bounds, zoom, mapRef, options = { 
  radius: 85, 
  maxZoom: 11,
  map: props => ({
      resolved: props.resolved?1:0,
      status: props.status,
    }),
    reduce: (acc, props) => {
        acc.resolved += props.resolved;
        acc.statusResolved += (props.status ==="RESOLVED"?1:0)
        return acc
    }
}, onClickMarker=()=>{} }) => {
  const leaflet = mapRef.current.leafletElement;
  const { clusters, supercluster } = useSupercluster({
      points,
      bounds,
      zoom,
      options
  });

  return clusters.map(marker => {
    const { cluster } = marker.properties;
    if (cluster) {
        return <ClusterMarker leaflet={leaflet} supercluster={supercluster} marker={marker}/>;
    } else {
        return <SingleMarker marker={marker} key={`alert-${marker.properties.id}`} onClickMarker={onClickMarker}/>;
    }
  })
}

const ClusterMarker = ({leaflet, supercluster, marker}) => {
  const [longitude, latitude] = marker.geometry.coordinates;
  const { point_count, resolved } = marker.properties;
  return (
      <Marker
      key={`cluster-${marker.id}`}
      position={[latitude, longitude]}
      icon={fetchIcon(point_count, resolved, marker.id)}
      onClick={() => {
          const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(marker.id),35);
          leaflet.setView([latitude, longitude], expansionZoom, {animate: true});
      }}

      />
  );
}

const SingleMarker = ({marker, onClickMarker}) => {
  const [longitude, latitude] = marker.geometry.coordinates;
  return (
      <Marker
          position={[latitude, longitude]}
          icon={iconByValue(marker.properties)}
          onclick={()=>{onClickMarker(marker.properties)}}
      >
      </Marker>
  )
}


const MapAlertShowCard = ({alert}) => {

  const [alertLoaded, setAlertLoaded] = useState(false);

  useEffect(()=> {
    fetchToken(api.url + `/alerts/`+alert.id).then(({json}) => {
      // console.log(json);
      setAlertLoaded(json)
    })
  }, [alert.id])

  if (alertLoaded) {
    return (<><AlertShowCard alert={alertLoaded}></AlertShowCard></>)
  }
  return (<>Loading</>)
}