import {fetchUtils} from 'react-admin'
import {api} from './config';

export const fetchIGNAddress = (address) => {
    return new Promise ((resolve, reject) => {
        // fetchUtils.fetchJson(`https://geocodage.ign.fr/look4/completion/?text=${address}&maximumResponses=10`)
        fetchUtils.fetchJson(`https://nominatim.v2.clean2gether.com/search.php?format=json&addressdetails=1&limit=20&q=${address}`)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            reject(error);
        })
    });
} 

export const fetchIGNGeoreverse = (lat,lng) => {
    return new Promise ((resolve, reject) => {
        const LATITUDE = lat
        const LONGITUDE = lng
        const query = `https://nominatim.v2.clean2gether.com/reverse.php?lat=${LATITUDE}&lon=${LONGITUDE}&format=json`
        fetchUtils.fetchJson(query)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            reject(error);
        })
    })
}

export const fetchAdministration = (LATITUDE,LONGITUDE, TYPES="epci,commune", FORMAT=0) => {
    return new Promise ((resolve, reject) => {
        const query = `https://nomi.v2.clean2gether.com/?lat=${LATITUDE}&lng=${LONGITUDE}&types=${TYPES}&format=${FORMAT}`;
        fetchUtils.fetchJson(query)
        .then(response => {
            // console.log(response)
            resolve(response);
        })
        .catch(error => {
            reject(error);
        })
    })
}

//state?name=Nouvelle-Aquitaine&shape=1
export const fetchGeoJsonNomi = (type, name) => {
    return new Promise ((resolve, reject) => {

        let TYPE = type;
        let NAME = name;
        let CP = null
        if (name.split('@').length>1) {
          const [townName, townCP] = name.split('@');
          NAME = townName;
          CP = townCP;
        }

        const query = `https://nomi.v2.clean2gether.com/${TYPE}?name=${NAME}&cp=${CP}&shape=1`;
        fetchUtils.fetchJson(query)
        .then(response => {
            // console.log(response)
            resolve(response.json.feature.geometry);
        })
        .catch(error => {
            reject(error);
        })
    })
}

export const fetchCollectivityFromLocation = async (LATITUDE,LONGITUDE) => {
    return new Promise ((resolve, reject) => {
        const query = `${api.url}/collectivities/findFromCoord?LATITUDE=${LATITUDE}&LONGITUDE=${LONGITUDE}`;
        fetchUtils.fetchJson(query)
        .then(response => {
            // console.log(response)
            resolve(response);
        })
        .catch(error => {
            reject(error);
        })
    })
}