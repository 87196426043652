import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import {
    TextInput
} from 'react-admin';

import { PickerMap } from '../Map/PickerMap';
import { useForm } from 'react-final-form';
import { Typography, FormGroup, FormControlLabel, Switch } from "@material-ui/core";

export const AddressInput = (props) => {
    const uForm = useForm();
    const {
        label,
        source,
        enable
    } = props;

    const { bbox } = useSelector(state => state.area);

    const [ initAddress, setInitAddress ] = useState(false);
    const [ initAddressMeet, setInitAddressMeet ] = useState(false);
    const [addresseMeetEnable, setAddresseMeetEnable] = useState(false);

    const {
        address,
        address_latitude,
        address_longitude,
        address_meet,
        address_meet_latitude,
        address_meet_longitude
    } = uForm.getState().values;

    // console.log("uForm:", uForm, uForm.getState());


    useEffect(() => {
        // console.log("init AddressInput");
        if (address && address_latitude && address_longitude) {
            // console.log("setInitAddress")
            setInitAddress({
                zoom: 18, 
                address, 
                latitude: parseFloat(address_latitude), 
                longitude: parseFloat(address_longitude), 
                alertsID: [], 
                updateAddress: true
            })
        } else {
            setInitAddress({
                bbox: bbox,
                zoom: 17, 
                address: "", 
                latitude: 44.5, 
                longitude: -0.5, 
                alertsID: [], 
                updateAddress: true
            })
        }

        if (address_meet && address_meet_latitude && address_meet_longitude) {
            setInitAddressMeet({
                zoom: 17, 
                address: address_meet, 
                latitude: parseFloat(address_meet_latitude), 
                longitude: parseFloat(address_meet_longitude), 
                alertsID: [], 
                updateAddress: true
            })
            setAddresseMeetEnable(true);
        } else {
            setInitAddressMeet(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleUpdateAddress= (data) => {
        // console.log("handleUpdateAddress:");
        // console.log("uForm before update:", uForm.getState().values.address);
        uForm.change(source+"_latitude", data.location.latitude);
        uForm.change(source+"_longitude", data.location.longitude);
        uForm.change(source, data.address);
        // console.log("uForm after update:", uForm.getState().values.address);
    }

    const handleUpdateAddressMeet= (data) => {
        // console.log("handleUpdateAddressMeet:");
        uForm.change(source+"_meet_latitude", data.location.latitude);
        uForm.change(source+"_meet_longitude", data.location.longitude);
        uForm.change(source+"_meet", data.address);
    }

    const handleEnableMeet = (data) => {

        setAddresseMeetEnable(!addresseMeetEnable);
        // console.log("handleEnableMeet:", addresseMeetEnable);

        if (!addresseMeetEnable) {
            setInitAddressMeet({
                zoom: 17, 
                address, 
                latitude: parseFloat(address_latitude), 
                longitude: parseFloat(address_longitude), 
                alertsID: [], 
                updateAddress: true
            })
        } else {
            setInitAddressMeet(false)
            uForm.change(source+"_meet_latitude", null);
            uForm.change(source+"_meet_longitude", null);
            uForm.change(source+"_meet", null);
        }
    }

    // console.log("AddressInput enable: ", enable);

    return (
        
    <span>
        <Typography>{label}</Typography>
        {initAddress && <>
            <PickerMap
                styleMap={{height: "45vh"}}
                init={initAddress}
                handleUpdate={handleUpdateAddress}
                enable={enable}
            />
            <TextInput label="AAA" source="address" style={{display:"none"}}></TextInput>
            <FormGroup row>
                <FormControlLabel
                    control={<Switch checked={addresseMeetEnable} onChange={handleEnableMeet} name="checkedA" />}
                    label="Définir un lieu de rassemblement différent"
                />
            </FormGroup>
        </>}
        {/* <BooleanInput label="Lieux de rassemblement différent" value={addresseMeetEnable} onChange={handleEnableMeet}></BooleanInput> */}



        { initAddressMeet && <>
            <PickerMap
                styleMap={{height: "45vh"}}
                init={initAddressMeet}
                handleUpdate={handleUpdateAddressMeet}
                enable={enable}
            />
            <TextInput label="Adresse rassemblement finale" source="address_meet" style={{display:"none"}}></TextInput>
        </>}
    </span>)
};