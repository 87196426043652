import {Cookies} from '../helpers/Cookies';
import {fetchUtils} from 'react-admin'

export const fetchJsonWithToken = (url, options = {}, files = [], filePath = "files.images") => {
    // console.log("fetch url:", url);
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = Cookies.getCookie('token')
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }
    const filter = localStorage.getItem("CollectivityFilter");
    if (filter !== "false" && filter !== "undefined" && filter) {
        // console.log("FILTER BEFORE QUERY:",filter)
        if (url.includes('alertsGeoloc')) {
            // console.log("header add c2gfilter:", filter);
        }
        options.headers.set('c2gfilter', `${encodeURI(filter)}`);
    }

    if (files && files.length>0) {
        var formData = new FormData();
        files.forEach((file) => {
            try {
                formData.append(filePath, file, file?.name || null);
            } catch (e) {
                // console.log("File not found ! :", file)
            }
        })
        formData.append('data', options.body);
        options = {
            ...options,
            body : formData,
            maxBodyLength: 200428890
        }
    }
    
    //console.log("OPTIONS:", options)

    return new Promise((resolve, reject) => {
        fetchUtils.fetchJson(url, options).then(result => {
            resolve(result);
        }).catch(error=> {
            console.log(error.message, error.status, error.response); //, error.body
            if (error.status === 403) {
                localStorage.setItem("ForbidenUrl", url+" "+error.status);
                window.location.reload()
            }
            reject(error);
        })
    });
    
}