import React, { useEffect } from 'react';
import {useDispatch} from 'react-redux';
import { Notification, Title } from 'react-admin';
import { setIn } from 'final-form';
import { Box, Paper, Link, CssBaseline, Grid, Typography } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import api from "../../api";
import { CustomTheme } from "../../theme/CustomTheme";
import { AuthLayoutDecorator } from '../UI/AuthLayoutDecorator';

export const AuthLayout = ({children}) => {
    const classes = authStyle();
    const dispatch = useDispatch();

  useEffect(() => {
    // console.log("Init AuthLayout")
    dispatch({type: 'AREA_INIT'})
    dispatch({type:'USER_INIT'})
    // eslint-disable-next-line
  }, [])

    return (
    <>
      <Title title="Authentification"></Title>
      <ThemeProvider theme={CustomTheme} >
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} >
              <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              style={{height:'100%'}}>
              <Grid item>
                  { api?.siteSettings?.loginImg && 
                    <img alt="background" src={api?.siteSettings?.loginImg } width="100%" style={{maxWidth:"600px"}}/>
                  }
              </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <AuthLayoutDecorator />
              {children}
              <Copyright />
            </div>
          </Grid>
        </Grid>
        <Notification/>
      </ThemeProvider>
    </>
    );
};

function Copyright() {
    return (<Box mt={5}>
        <Typography variant="body2" color="textSecondary" align="center">
            Copyright © <Link color="inherit" href={api.site_copyright}> {api?.siteSettings?.name} </Link> {new Date().getFullYear()}.
        </Typography>
    </Box>);
}

export const authValidate = schema => async values => {
  if (typeof schema === 'function')
    schema = schema();

  try {
    await schema.validate(values, { abortEarly: false });
  } catch (e) {
    return e.inner.reduce((errors, error) => {
      return setIn(errors, error.path, error.message);
    }, {});
  }
};

export const authStyle = makeStyles((theme) => {
  // console.log(theme)
    return ({
      root: {
        height: '100vh',
      },
      image: {
        backgroundImage: "url("+api?.siteSettings?.loginBackground+")",
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        position:"relative",
      },
      formControl: {
        //margin: theme.spacing(1),
        minWidth: 120,
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
      muiAlert: {
        width: '100%',
        margin: '10px 0'
      },
      loadingBox: {
        position:"absolute", 
        top:"0px", 
        width:"100%", 
        height:"100%", 
        opacity: "1", 
        paddingTop:"15%", 
        paddingRight:"10%",
        textAlign: "center"
      }
    })
  }
);