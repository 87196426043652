import React from 'react'
import ImageGallery from 'react-image-gallery';
import {strapiImage} from '../../../api'
import "react-image-gallery/styles/scss/image-gallery.scss"

const images = [

];

export const MyGallery = ({inputs, strapiInputs}) => {
  let tab = false;
  if (strapiInputs && strapiInputs?.length > 0) {
    tab = strapiInputs.map(image => {
      return {
          original: strapiImage(image, '>').url,
          thumbnail:  strapiImage(image, '-').url,
          fullscreen: strapiImage(image, '+').url
      }
  })
}


  const items = tab || inputs || images;

  if (items && items?.length > 0)
    return (
      <ImageGallery 
        items={items} 
        lazyLoad={true} 
        thumbnailPosition="bottom" 
        showPlayButton={false}
        />
    )
  return (null)
}