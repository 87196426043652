import { createTheme  } from '@material-ui/core/styles';
import { defaultTheme } from 'react-admin';
import api from "../api"
//const color = '#50BCB8';

const themeLapade = {
  ...defaultTheme,
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
    primary: {
      main: '#18498a',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#50bcb8',
      contrastText: '#FFF'
    },
    primaryC2G: {
      main: '#81C34F',
      contrastText: '#FFF'
    },
    warning: {
      main: '#E8833A',
      contrastText: '#FFF'
    },
    background: {
    }
  },
  sidebar: {
    width: 240,
    closedWidth: 55,
  }
}

const themeC2g = {
  ...defaultTheme,
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
    primary: {
      main: '#50BCB8',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#87ca4f',
      contrastText: '#FFF'
    },
    warning: {
      main: '#E8833A',
      contrastText: '#FFF'
    },
    
    background: {
    }
  },
  sidebar: {
    width: 240,
    closedWidth: 55,
  }
}


export const CustomTheme = createTheme (api.site === "LAPADE"?themeLapade:themeC2g);
