import React from 'react'
import { Title } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'; 
import { CircularProgress, Grid, Divider, Typography, useTheme } from '@material-ui/core'
import { Card, CardContent, CardHeader } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { AccountProfileForm } from './AccountProfileForm'
import { AccountRoleForm } from './AccountRoleForm'
import { ContentHeaderTuto } from '../../UI/TutoVideo';

export const Account = () => {
    const { user } = useSelector(state => state.account)
    const theme = useTheme();
    const dispatch = useDispatch();

    const handleUpdate = () => {
        dispatch({type: 'SAGA_USER_REFRESH'})
    }

    if (user) {
        return (
            <Card>
                <Title title="Mon compte"></Title>
                <CardHeader  style={{paddingBottom:"0px"}}
                    title={<ContentHeaderTuto title={<Typography  variant="h5">Mon compte</Typography>} type="Compte"/>}
                    avatar={<SettingsIcon style={{color:theme.palette.secondary.main}} />}
                ></CardHeader>
                <CardContent>
                    <Divider style={{ marginBottom:30}}/>
                    <AccountRoleForm user={user} update={handleUpdate}></AccountRoleForm>
                    <Divider style={{ margin: "30px 0px"}}/>
                    <AccountProfileForm user={user} update={handleUpdate}></AccountProfileForm>
                </CardContent>
            </Card>
        )
    } else {
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{width:"50wh", height:"50vh"}}
            >
                <center>
                    <CircularProgress></CircularProgress>
                </center>
            </Grid>
        )
    }
}