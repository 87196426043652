// in src/NotFound.js
import  React from "react";
import { Title } from 'react-admin';
import { AdminOfflineLayout } from '../Layout/AdminOfflineLayout';
import { useParams } from "react-router-dom";
import { AlertShow } from "../Resources/Alert/AlertShow"
import { ResourceContextProvider } from "react-admin";

export const OfflineContext = React.createContext({open:false, toggle: ()=>{
    // console.log("toggle dialog")
}});

export const AlertPreview = () => {
    const params = useParams();
    return (
        <AdminOfflineLayout>
            <Title title="Prévisualisation " />
            <ResourceContextProvider value="alerts">
                <AlertShow id={params?.alertid} resource="alerts" basePath="/" />
            </ResourceContextProvider>
        </AdminOfflineLayout>
    )
};