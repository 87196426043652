import React from "react";

import {
    useRecordContext,
} from "react-admin";

import { useTheme } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

export const BooleanCustomField = (props) => {
    const { source, label, alwaysVisible = true, showLabel = false } = props;
    const theme = useTheme();
    const record = useRecordContext(props);
    // console.log(record, source, theme)
    if (record[source]) {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                <DoneIcon style={{ color: theme.palette.secondary.main }}/>
                {showLabel && <><span style={{marginLeft:'10px'}}>{label}</span><br /></>}
            </div>
        )
    } else if (alwaysVisible) {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                <CloseIcon style={{ color: "FF0000" }}/>
                {showLabel && <><span style={{marginLeft:'10px'}}>{label}</span><br /></>}
            </div>
        )
    }

    return (null);
}