import React, { useCallback } from 'react';
import {strapiImage} from '../../../api'
import { Card, CardActionArea, CardMedia } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        // maxWidth: 345,
    },
    media: {
        height: 350,
    },
    checkIcon: {
        // color: "#00FF00", 
        position:"absolute", 
        backgroundColor:"#FFFFFF99", 
        right:"5px",
        top:"5px",
        borderRadius:'5px',
        padding:'1px'
    },
    cardContent: {
        maxHeight:"200px", 
        marginBottom: 8
    }
});

export const TrainingModuleCard = ({training, module}) => {

    const classes = useStyles();
    const history = useHistory();
    const colors = useTheme();

    // eslint-disable-next-line
    const handleOnClick = useCallback(() => {
            let baseLink = '/training/'+training?.id;
            if (training?.state === "STATIC" && training?.slug) {
                baseLink = '/'+training?.slug 
            }
            // console.log("handleOnClick TrainingModuleCard")
            history.push(baseLink+'/'+module?.id)
        }, 
        [history, training?.id]
    );

    // console.log("module:", module);
    const imageUrl = strapiImage(module?.Image, '+')?.url

    return (
        <Card className={classes.root} onClick={handleOnClick} >
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={imageUrl}
                    title={module?.Title}
                >
                    { (training?.enabledProgress !== false) &&  <>
                        {module.state === 2 && <CheckIcon className={classes.checkIcon} /> }
                        {module.state === 0 && <CloseIcon className={classes.checkIcon} /> }
                        {module.state === 1 && <VisibilityIcon className={classes.checkIcon} /> }
                    </>}
                    <div style={{
                        position:"absolute",
                        bottom:0,
                        backgroundColor:"#FFFFFFE2",
                        width: "100%",
                        padding: 10,
                        fontSize: 15,
                        textAlign:"center",
                        fontWeight:"bold",
                        color: colors.palette.secondary.main
                    }}>
                        {module?.Title}
                    </div>
                </CardMedia>
                {/* <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" component="h2" align='center'>
                        {module?.Title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {module?.Description}
                    </Typography>
                </CardContent> */}
            </CardActionArea>
        </Card>
    )
}