const API_URL = process.env.REACT_APP_URL_API;

export const strapiImageFormat = (image, size = '>', type) => {
    if (!image) {
        if (type === "event.cover") {
            return {
                url: "/background-c2g.jpg",
                width: 1200,
                height: 628
            }
        } else {
            return {
                url: "/placeholder/cover.png",
                width: 400,
                height: 300
            }
        }
    }

    const obj = chooseImageFormat(image, size);
    return {...obj, url: API_URL + obj.url};
}

const chooseImageFormat = (image, size) => {
    const formatList = ['thumbnail', 'small', 'medium', 'large']

    const { formats } = image;
    try {
        if (size === '>') {
            for(const format of formatList.reverse()) {
                if (formats[format]) {
                    return {...formats[format], url: formats[format].url}
                }
            }
            return image;
        } else if (size === '<') {
            for(const format of formatList) {
                if (format === "thumbnail" && formats?.small) { continue;}
                if (formats[format]) {
                    return {...formats[format], url: formats[format].url}
                }
            }
            return image;
        } else if (size === '-') {
            for(const format of formatList) {
                if (formats[format]) {
                    // console.log(formats[format]);
                    return {...formats[format], url: formats[format].url}
                }
            }
            return image;
        } else if (size === '+') {
            return {...image, url: image.url};
        }
    } catch (e) {
        // console.log(e);
        return image
    }
}