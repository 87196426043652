import React, { useCallback } from 'react';
import {strapiImage} from '../../../api'
import { Card, CardActionArea, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        // maxWidth: 345,
    },
    media: {
        height: 350,
    },
    checkIcon: {
        // color: "#00FF00", 
        position:"absolute", 
        backgroundColor:"#FFFFFF99", 
        right:"5px",
        top:"5px",
        borderRadius:'5px',
        padding:'1px'
    },
    cardContent: {
        maxHeight:"200px", 
        marginBottom: 8
    }
});

export const TrainingModuleChapterCard = ({training, module, chapter}) => {

    const classes = useStyles();
    const history = useHistory();

    // const handleOnClick = useCallback(() => history.push('/training/'+training?.id+'/'+module?.id+'/'+chapter?.id), [history]);
    // eslint-disable-next-line
    const handleOnClick = useCallback(() => {
        let baseLink = '/training/'+training?.id;
        if (training?.state === "STATIC" && training?.slug) {
            baseLink = '/'+training?.slug 
        }
        // console.log("handleOnClick TrainingModuleCard")
        history.push(baseLink+'/'+module?.id+'/'+chapter?.id)
    }, 
    [history, training?.id]
);

    return (
        <Card className={classes.root} onClick={handleOnClick} >
            <CardActionArea>
                { chapter?.Image && 
                    <CardMedia
                        className={classes.media}
                        image={strapiImage(chapter?.Image, '+').url}
                        title={chapter?.Title}
                    >
                        { (training?.enabledProgress !== false) &&  <>
                            {chapter.state === 2 && <CheckIcon className={classes.checkIcon} /> }
                            {chapter.state === 0 && <CloseIcon className={classes.checkIcon} /> }
                            {chapter.state === 1 && <VisibilityIcon className={classes.checkIcon} /> }
                        </>}
                    </CardMedia>
                }
            </CardActionArea>
        </Card>
    )
}