import React, { useState, useEffect } from 'react';
import api, {fetchToken } from '../../api'
import { useLocation, useParams, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'; 
import { Title } from 'react-admin';
import { Card, CardContent, CardHeader, Grid, Paper, Typography, Button, LinearProgress, useTheme, CircularProgress } from '@material-ui/core';
import { TrainingPlan, sortOrder } from './Training/TrainingPlan';
import { TrainingCard } from './Training/TrainingCard';
import { TrainingModuleCard } from './Training/TrainingModuleCard';
import { TrainingModuleChapterCard } from './Training/TrainingModuleChapterCard';
import { TrainingBreadcrumb } from './Training/TrainingBreadcrumb';
import { TrainingModuleChapterFull } from './Training/TrainingModuleChapterFull';
import SchoolIcon from '@material-ui/icons/School';
import { TrainingFAQAccordion } from './Training/TrainingFAQAccordion';
import { TrainingFAQForm } from './Training/TrainingFAQForm';
import { ContentHeaderTuto } from '../UI/TutoVideo';
import { MarkdownDescription } from '../UI/MardownDescription';

export const Trainings = (props) => {
    const { status } = useSelector(state => state.account);

    const action = props?.action;
    const type = props?.type || "training";

    if (!status?.training && type === "training") {
        return (<>
        <CircularProgress></CircularProgress>
        {/* Formation non disponible */}
        </>);
    }



    // console.log(status);
    switch (action) {
        case "training":
            return <Training {...props} />
        case "trainingfaq":
            return <TrainingFAQ {...props}/>
        case "module":
            return <TrainingModule {...props}/>
        case "chapter":
            return <TrainingChapter {...props}/>
        default:
            return <TrainingHome {...props}/>
    }
}

export const TrainingHome = (props) => {
    const [trainings, setTrainings] = useState(null);
    const location = useLocation();
    const theme = useTheme();

    useEffect(() => {
        fetchToken(api.url + '/trainings').then(({json}) => {
            setTrainings(json);
        })
    }, [location.pathname])

    return (<>
        <Title title="Séminaires"></Title>
        <Card>
            <CardHeader
                style={{paddingBottom:"0px"}}
                title={<ContentHeaderTuto title={<Typography  variant="h5">Nos séminaires</Typography>} type="Training" />}
                avatar={<SchoolIcon style={{color:theme.palette.secondary.main}} />}
            />
            <CardContent>
                <Typography variant="subtitle1">
                Découvrez l’ensemble des séminaires en ligne proposé par la PADE, réalisé en partenariat avec Laurence Toussaint, formatrice d’élus locaux, sous-traitante pour les organismes agréés par le ministère de l’intérieur et responsable des affaires juridique de la communauté de commune Val de Saintonge.
                </Typography>
                <br />
                { (trainings && trainings?.length > 0) && 
                    <Grid container spacing={2}>
                        { trainings.map((training) => {
                            if (training?.state === "DRAFT" || training?.state === "STATIC") return (null);
                            return (
                                <Grid item lg={4} md={6} xs={12}>
                                    <TrainingCard training={training} />
                                </ Grid>
                            )
                        }) }
                    </ Grid>
                }
            </CardContent>
        </Card>
    </>)
}

export const Training = (props) => {
    const [training, setTraining] = useState(null);
    const location = useLocation();
    const params = useParams();

    let query = api.url + '/trainings/'+params?.trainingID;
    if (props?.type === "kdc") {
        query = api.url + '/trainings/?slug=kit-de-communication';
    }
    if (props?.type === "aide-documentaire") {
        query = api.url + '/trainings/?slug=aide-documentaire';
    }

    // const disabledProgress = training?.enabledProgress === false;

    useEffect(() => {
        fetchToken(query).then(({json}) => {
            
            const trainingData = (json?.length > 0)?json[0]:json;
            // console.log(trainingData);
            setTraining(applyProgressTraining(trainingData));
        })
        // eslint-disable-next-line
    }, [location.pathname])

    const applyProgressTraining = (training) => {
        // if (training?.enabledProgress === false) return (training);
        return {
            ...training,
            training_modules: training.training_modules.map((module) => {
                const state = training?.progress?.training[training.id]?.module[module.id]?.state || 0
                return ({
                    ...module,
                    state: state
                })
            })
        };
    }

    if ( !training ) {
        return (<><CircularProgress></CircularProgress></>)
    }
    if ( training && training.state!=="AVAILABLE" && training.state !== "STATIC") {
        return (<Redirect push to="/training"></Redirect>)
    }

    return (<>
        <Title title={training?.Title}></Title>
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item sm={12} lg={9}>
                        <CardHeader title={<TrainingBreadcrumb training={training} />} />
                        <CardContent>
                            <Grid container spacing={2}>
                                { training?.training_modules.sort(sortOrder).map((module) => {
                                    return (
                                        <Grid item lg={4} md={6} xs={12} key={"module"+module.id}>
                                            <TrainingModuleCard module={module} training={training}/>
                                        </ Grid>
                                    )
                                }) }
                            </Grid>
                        </CardContent>
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <TrainingPlan training={training}  />
                    </Grid>
                    <Grid item xs={12}>
                        <MarkdownDescription item={training} />
                        {training?.FAQ && <>
                            <p>Vous avez des questions ?</p>
                            <Button variant="contained" color="secondary" href={"/#/training/"+training?.id+"/faq"} >Consultez notre FAQ</Button>
                        </>}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </>)
}

export const TrainingFAQ = (props) => {
    const [training, setTraining] = useState(null);
    const location = useLocation();
    const params = useParams();

    let query = api.url + '/trainings/'+params?.trainingID;
    if (props?.type === "kdc") {
        query = api.url + '/trainings?slug=kit-de-communication';
    }

    // const disabledProgress = training?.progress === false;

    useEffect(() => {
        fetchToken(query).then(({json}) => {
            const trainingData = (json?.length > 0)?json[0]:json;
            setTraining(applyProgressTraining(trainingData));
        })
        // eslint-disable-next-line
    }, [location.pathname])


    const applyProgressTraining = (training) => {
        return {
            ...training,
            training_modules: training.training_modules.map((module) => {
                const state = training?.progress?.training[training.id]?.module[module.id]?.state || 0
                return ({
                    ...module,
                    state: state
                })
            })
        };
    }


    if ( !training ) {
        return (<><CircularProgress></CircularProgress></>)
    }

    return (<>
        <Title title={training?.Title}></Title>
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item lg={12}>
                        <CardHeader title={<TrainingBreadcrumb training={training} faq={training?.FAQ} />} />
               
                        <CardContent>
                            <Typography variant="subtitle1">
                                {(training?.FAQ?.Description?.length === 0) && <>
                                    Vous avez des questions concernant nos séminaires ou comment gérer un cas de figure précis ?<br />
                                    <br />
                                    Veuillez trouver ci-dessous les questions le plus fréquemment posées, triées par formation.<br /><br />
                                    Votre question n’est pas disponible dans la liste ? 
                                    <Button onClick={()=>{window.scrollTo(0, document.body.scrollHeight);}} style={{marginLeft: "18px"}} variant="contained" color="primary">
                                        Poser une question
                                    </Button>
                                </>}
                                {(training?.FAQ?.Description?.length > 0) && <>
                                    <MarkdownDescription item={training?.FAQ} />
                                </>}
                            </Typography>
                            <br />

                            <TrainingFAQAccordion data={training.FAQ}/>
                            <TrainingFAQForm></TrainingFAQForm>
                        </CardContent>
                        <CardHeader
                            title={<Button variant="contained" color="secondary" href={"/#/training/"+training?.id} >Retour</Button>}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </>)
}

export const TrainingModule = (props) => {
    const [module, setModule] = useState(null);
    const [training, setTraining] = useState(null);
    const [update, setUpdate] = useState(0);
    const location = useLocation();
    const params = useParams();

    const disabledProgress = training?.enabledProgress === false;

    const applyProgressModule = (module) => {
        let nbFinish = 0;
        return {
            ...module, 
            training_module_chapters : module.training_module_chapters.map((chapter) => {
                const state = module.progress?.training[module.training.id]?.module[module.id]?.chapter[chapter.id]?.state || 0
                if (state === 2) {nbFinish++}
                return {...chapter, state: state}
            }),
            linearProgress: (nbFinish * 100 / module?.training_module_chapters?.length || 1 )
        }
    }

    useEffect(() => {
        fetchToken(api.url + '/training-modules/'+params.moduleID).then(({json}) => {
            setTraining(json.training);
            setModule(applyProgressModule(json));
        })
        // eslint-disable-next-line
    }, [location.pathname, update])


    const onClickMarkAsFinish = () => {
        const chapterID = module?.training_module_chapters[0].id;
        fetchToken(api.url + '/training-module-chapters/'+chapterID+'/view').then(({json}) => {
            setUpdate(update+1);
        })
    }

    if (!module || !training) {
        return (null);
    }

    return (<>
        <Title title="Formation"></Title>
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item lg={9}>
                        <CardHeader
                            title={<TrainingBreadcrumb training={training} module={module} />}
                            action={
                                (module?.training_module_chapters?.length === 1 && !disabledProgress) && 
                                    <Button variant="contained" color="primary" disabled={module?.training_module_chapters[0].state === 2} onClick={onClickMarkAsFinish}>
                                        Marquer comme terminé
                                    </Button>
                            }
                        />
                        <CardContent>
                            { !disabledProgress && <>
                                <Typography style={{marginBottom:8}}>Progression du module</Typography>
                                <LinearProgress variant="determinate" value={module.linearProgress} style={{height: 10, borderRadius: 5, marginBottom: 16}}/>
                            </>}
                            <Grid container spacing={2}>
                                {(module?.training_module_chapters?.length > 1) && module?.training_module_chapters.sort(sortOrder).map((chapter) => {
                                    return (
                                        <Grid item lg={4} md={6} xs={12} key={"chapter"+chapter.id}>
                                            <TrainingModuleChapterCard training={training} module={module} chapter={chapter}/>
                                        </Grid>
                                    )
                                })}
                                {(module?.training_module_chapters?.length === 1) && module?.training_module_chapters.map((chapter) => {
                                    return (
                                        <Grid item xs={12} key={"chapter"+chapter.id}>
                                            <TrainingModuleChapterFull chapter={chapter} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            { (module?.training_module_chapters?.length !== 1 && module.Description) && 
                                <Paper style={{padding:16, marginTop: 16}} elevation={2}>
                                    <MarkdownDescription item={module} />
                                </Paper>
                            }
                        </CardContent>
                    </Grid>
                    <Grid item lg={3}>
                        <TrainingPlan training={training} currentmodule={module}  />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </>)
}

export const TrainingChapter = (props) => {
    const [training, setTraining] = useState(null);
    const [module, setModule] = useState(null);
    const [chapter, setChapter] = useState(null);
    const location = useLocation();
    const params = useParams();

    const applyProgressChapter = (chapter) => {
        return {
            ...chapter, 
            linearProgress: ( chapter.progress?.training[chapter.training_module.training.id]?.module[chapter.training_module.id]?.finish * 100 / chapter.progress?.training[chapter.training_module.training.id]?.module[chapter.training_module.id]?.total || 1 ),
            state: chapter.progress?.training[chapter.training_module.training.id]?.module[chapter.training_module.id]?.chapter[chapter.id]?.state
        }
    }

    useEffect(() => {
        fetchToken(api.url + '/training-module-chapters/'+params.chapterID).then(({json}) => { 
            setTraining(json.training_module.training);
            setModule(json.training_module);
            setChapter(applyProgressChapter(json));
        })
        // eslint-disable-next-line
    }, [location.pathname])

    const onClickMarkAsFinish = () => {
        fetchToken(api.url + '/training-module-chapters/'+params.chapterID+'/view').then(({json}) => {
            setTraining(json.training_module.training);
            setModule(json.training_module);
            setChapter(applyProgressChapter(json));
        })
    }

    if (!training || !module || !chapter) {
        return (null);
    }

    return (<>
        <Title title="Formation"></Title>
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item lg={9}>
                        <CardHeader 
                            title={<TrainingBreadcrumb training={training} module={module} chapter={chapter} />}
                            action={<Button variant="contained" color="primary" disabled={chapter?.state === 2} onClick={onClickMarkAsFinish}>Marquer comme terminé</Button>}
                        />
                        <CardContent>
                            <Typography style={{marginBottom:8}}>Progression du module</Typography>
                            <LinearProgress variant="determinate" value={chapter.linearProgress} style={{height: 10, borderRadius: 5, marginBottom: 16}}/>
                            <TrainingModuleChapterFull chapter={chapter} />
                        </CardContent>
                    </Grid>
                    <Grid item lg={3}>
                        <TrainingPlan training={training} currentmodule={module} currentchapter={chapter} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </>)
}