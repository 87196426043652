// in src/Dashboard.js
import React from "react";
import { useSelector } from 'react-redux';
import { DashboardAlert } from './Dashboard/DashboardAlert';
import { DashboardGeneral } from './Dashboard/DashboardGeneral';
// import { DashboardTraining } from './Dashboard/DashboardTraining';
import Alert from '@material-ui/lab/Alert';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { BlockLoading } from "../UI/Decorator/Decorator";
import {  Redirect } from "react-router-dom"


export const Dashboard = () => {
    const { user, status } = useSelector(state => state.account);
    
    if (!user || !status) {
        return (<BlockLoading loading={true}/>)
    } else {
        if (!status.valid) {
            if (status.type === "Association") {
                return (<Redirect push to="/space"></Redirect>)
            } else if (status.type === "Citoyen") { 
                return (<Redirect push to="/account"></Redirect>)
            } else {
                return (<BlockCollectivityNeedAdmin/>)
            }
        }
        if (status.type === "Association") {
            return (<DashboardAlert />)
        } else {
            return (<DashboardGeneral />)
        }
    }
};

const BlockCollectivityNeedAdmin = () => (
    <Grid container
    direction="row"
    alignItems="center">
    <Grid item xs={12} md={12} lg={6}>
        <Card>
            <CardContent>
                <Alert severity="warning" variant="outlined">Un administrateur doit valider votre compte pour débloquer l’accès à votre espace d’administration !</Alert>
                <br />
                <Typography variant="body1" >Nous traitons la demande de création de compte et activons votre espace dans les meilleurs délais. </Typography>
                <br />
                <Typography variant="body1">Nous vous confirmerons par email dès qu’il sera possible de vous connecter. </Typography>
                <br />
                <Typography variant="body1">Nous vous remercions pour votre confiance et restons à votre disposition pour vous accompagner.</Typography>
                <br />
                <Typography variant="body1">Bien cordialement,</Typography>
                <br />
                <Typography variant="body1">L’équipe Clean2gether.</Typography>
            </CardContent>
        </Card>
    </Grid>
</Grid>
)