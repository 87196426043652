import React, { useState } from "react";
import { Box, Card, CardContent, CardHeader, Grid, Paper, useTheme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Title } from 'react-admin'
import RoomIcon from '@material-ui/icons/Room';
import { AlertListFiltered } from '../../Resources/Alert'
import { NewClusterMap } from '../../UI/Map/NewClusterMap';
import { FilterSelectArea } from "../../UI/FilterSelectArea/FilterSelectArea"
import { WidgetsDashboard } from "../../UI/Widgets/Widgets";
import { ContentHeaderTuto } from "../../UI/TutoVideo";
import { BarChartGraph } from "../../TimeSeries/BarChartGraph";

export const DashboardAlert = () => {

    const [alertFilter, setAlertFilter] = useState(null);
    const reduxArea = useSelector( state => state.area);

    const theme = useTheme();
    const handleClusterMapMove = (e) => {
        const newFilter = {
            'latitude_gte':e[1],
            'latitude_lte':e[3],
            'longitude_gte':e[0],
            'longitude_lte':e[2],
            ...(reduxArea?.date_start && {created_at_gte: reduxArea.date_start}),
            ...(reduxArea?.date_end && {created_at_lte: reduxArea.date_end})
        }
        setAlertFilter(newFilter);
    }

    const handleClick = () => {
        console.log("handleClick");
    }

    return (
        <>
            <Title title="Tableau de bord"></Title>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <Paper>
                        <FilterSelectArea />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={9} md={10} lg={11}>
                    <Card>
                        <CardHeader  style={{paddingBottom:"0px"}}
                            title={<ContentHeaderTuto title={<Typography  variant="h5">Alertes sur votre zone</Typography>} type="Dashboard" />}
                            avatar={<RoomIcon style={{color:theme.palette.secondary.main}} />}
                        ></CardHeader>
                        {/* <CardHeader title={ <ContentHeaderTuto title="Alertes sur votre zone" type="Dashboard" />} /> */}
                        <CardContent>
                            <NewClusterMap onMove={handleClusterMapMove} onClick={handleClick}/>
                            <Box mt={4}>
                                <Grid container justifyContent="space-evenly" alignItems="center">
                                    <Grid item>
                                        <center>
                                            <img alt="marker_alert" src="/markers/alert-marker.png"></img>
                                            <p>Alerte</p>
                                        </center>
                                    </Grid>
                                    <Grid item>
                                        <center>
                                            <img alt="marker_process" src="/markers/alert-marker-processing.png"></img>
                                            <p>En cours</p>
                                        </center>
                                    </Grid>
                                    <Grid item>
                                        <center>
                                            <img alt="marker_resolved" src="/markers/alert-marker-resolved.png"></img>
                                            <p>Résolu</p>
                                        </center>
                                    </Grid>
                                    <Grid item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                    <WidgetsDashboard />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader title="Dernières Alertes" />
                        <CardContent>
                            <AlertListFiltered />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} >
                    <Card>
                        <CardHeader title="Alertes Créées/Résolues"></CardHeader>
                        <CardContent>
                            <BarChartGraph width={0} ></BarChartGraph>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}