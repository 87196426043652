import React, { useState, useEffect } from "react";

import {
    List,
    useListContext,
    useRefresh
} from "react-admin";

import { useSelector } from 'react-redux'; 
import { Redirect } from "react-router-dom"

import { Card, CardContent, CardHeader, Grid, Paper } from "@material-ui/core";

import { FilterSelectArea } from "../../UI/FilterSelectArea/FilterSelectArea";
import { AlertEventCard } from './AlertEventCard';
import { ContentHeaderTuto } from "../../UI/TutoVideo";

export const AlertEventList = (props) => {

    const refresh = useRefresh();
    const { user, status } = useSelector(state => state.account)
    const { filter } = useSelector(state => state.area);

    const [defaultFilter, setDefaultFilter] = useState(filter);

    useEffect(()=>{
        if (filter !== defaultFilter) {
            // console.log("refresh");
            setDefaultFilter(filter);
            refresh();
        }
      // eslint-disable-next-line
    },[filter])

    if (!user || !status)
        return (null);
    if (!status?.valid && status?.type === "Association") {
        return (<Redirect push to="/space"></Redirect>)
    } else if (!status.valid) {
        return (<Redirect push to="/dashboard"></Redirect>)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
                <Paper>
                    <FilterSelectArea contour={false} disabled={true}/>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Card>
                    <CardHeader title={<ContentHeaderTuto title="Suivis" type="0" />} />
                    <CardContent>
                    <List
                        filterDefaultValues={{ 'user.collectivite': user?.collectivite?.id }}
                        filters={null}
                        sort={{ field: 'created_at', order: 'DESC'}}
                        actions={null}
                        bulkActionButtons={false}
                        {...props}
                    >
                        <AlertEventGrid />
                    </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const AlertEventGrid = () => {
    const { ids, data } = useListContext();

    return (
        <Grid container sx={{flexGrow:1}} spacing={2} style={{padding: 8}}>
            {ids.map(id =>
                <Grid item xs={12} md={6} lg={4} key={"event#"+id}>
                    <AlertEventCard event={data[id]} list={true}/>
                </Grid>
            )}
        </Grid>
    )
}