import React, { useState, useEffect } from 'react';
import { Link, TextField, Button, Grid, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'; 
import { useParams } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import api , {fetchToken} from '../../../api'
import { AuthLayout } from '../../Layout/AuthLayout';

export const ResetPassword = () => {
    const classes = useStyles();
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [warning, setWarning] = useState(false);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();
    const params = useParams();
    const submit = e => {
        e.preventDefault();
        
        if (password !== passwordConfirmation) {
            setWarning("La confirmation du mot de passe ne correspond pas.");
            return ;
        }

        fetchToken(api.url + '/auth/reset-password', {
            method: "POST",
            body:JSON.stringify({
                code: params.code,
                password, 
                passwordConfirmation, 
            }),
        })
        .then( (json) => {
            // console.log(json)
            setSuccess("Mot de passe mis à jour ! 🚀");
        }).catch( (err, json) => {
            // console.log(err, json)
            setWarning("Lien de confirmation non valide !");
        });
    };

    const handleChange = (e) => {
        if (e.target.id === "password") {
          setPassword(e.target.value);
        }
        if (e.target.id === "passwordConfirmation") {
          setPasswordConfirmation(e.target.value);
        }
    };

    useEffect(() => {
      dispatch({type: 'FILTER_RESET'})
    }, [dispatch])

    return (
        <AuthLayout>
            <Typography component="h1" variant="h5">
                Administration Collectivités
            </Typography>
            <Box margin={2}>
                <Typography component="p" variant="subtitle1">
                    Renseignez votre nouveau mot de passe
                </Typography>
            </Box>

            {warning && (
            <MuiAlert elevation={6} variant="filled" severity="warning" className={classes.muiAlert}>
                {warning}
            </MuiAlert>)}
            {success && (
            <MuiAlert elevation={6} variant="filled" severity="success" className={classes.muiAlert}>
                {success}
            </MuiAlert>)}

            { !success && 
                <form className={classes.form} noValidate onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                            value={password}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="passwordNew"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            value={passwordConfirmation}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                            name="passwordConfirmation"
                            label="Confirmation du Mot de passe"
                            type="password"
                            id="passwordConfirmation"
                            autoComplete="passwordConfirmation"
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        Mettre à jour mon mot de passe
                    </Button>
                    <SubLink/>
                </form>}
            { success &&
                <Button fullWidth href="#/login" variant="contained" color="primary" className={classes.submit}>
                    Je me connecte
                </Button>
            }
        </AuthLayout>
    );
};

const SubLink = () => (
    <Grid container>
        <Grid item xs>
            <Link href="#/login" variant="body2">
                Je me souviens
            </Link>
        </Grid>
        <Grid item>
            <Link href="#/register" variant="body2">
                {"Pas encore de compte? M'inscrire"}
            </Link>
        </Grid>
    </Grid>
)

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    muiAlert: {
        width: '100%',
        margin: '10px 0'
    }
}));