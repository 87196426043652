import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Card,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Slide,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    useTheme
} from '@material-ui/core';
import { Title } from 'react-admin';
import { useSelector } from 'react-redux'; 
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";

import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIcon from '@material-ui/icons/Assignment';

import api, {fetchToken } from '../../api'
import { TrainingFAQAccordion } from './Training/TrainingFAQAccordion';
import { MarkdownDescription } from '../UI/MardownDescription';
import { TrainingFAQForm } from './Training/TrainingFAQForm';

const useStyles = makeStyles((theme) => {
    return (
        {
            root: {
                maxWidth: 345,
            },
            media: {
                height: 0,
                paddingTop: '56.25%', // 16:9
            },
            buttons: {
                '& > *': {
                    margin: theme.spacing(1),
                },
            },
            titleTraining: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                padding: "10px",
                borderRadius: theme.shape.borderRadius,
                marginBottom: "0px"
            },
            titleNewFAQ: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.contrastText,
                padding: "10px",
                borderRadius: theme.shape.borderRadius,
                marginBottom: "0px"
            }
        }
    )
});

export const TrainingFAQs = () => {
    const [trainings, setTrainings] = useState(null);
    const location = useLocation();
    const classes = useStyles();
    const { status } = useSelector(state => state.account);
    const theme = useTheme();

    useEffect(() => {
        fetchToken(api.url + '/trainings').then(({json}) => {
            // console.log(json)
            setTrainings(json);
        })
    }, [location.pathname])


    if (!status?.training) {
        return (<Redirect push to="/dashboard"></Redirect>)
    }

    return (
    <>
        <Title title="FAQs"></Title>
        <Card>
            <CardHeader  style={{paddingBottom:"0px"}}
                title={<Typography  variant="h5">FAQs</Typography>}
                avatar={<AssignmentIcon style={{color:theme.palette.secondary.main}} />}
            ></CardHeader>
            <CardContent>
                  { (trainings && trainings?.length > 0) && 
                    <>
                        { trainings.map((training) => {
                            if (training?.state === "DRAFT") return (null);
                            if (training?.state === "COMING_SOON") return (null);

                            // console.log("training:",training);
                            return (
                                <>
                                    <Typography variant="subtitle1">
                                        {(training?.FAQ?.Description?.length === 0) && <>
                                            Vous avez des questions concernant nos séminaires ou comment gérer un cas de figure précis ?<br />
                                            <br />
                                            Veuillez trouver ci-dessous les questions le plus fréquemment posées, triées par formation.<br /><br />
                                            Votre question n’est pas disponible dans la liste ? 
                                            <Button onClick={()=>{window.scrollTo(0, document.body.scrollHeight);}} style={{marginLeft: "18px"}} variant="contained" color="primary">
                                                Poser une question
                                            </Button>
                                            </>
                                        }
                                        {(training?.FAQ?.Description?.length > 0) && <>
                                            <MarkdownDescription item={training?.FAQ} />
                                            </>
                                        }
                                    </Typography>
                                    <br />

                                    <Typography variant="h6" className={classes.titleTraining}>
                                        {training.Title}
                                    </Typography>
                                    <br />
                                    <TrainingFAQAccordion data={training.FAQ}></TrainingFAQAccordion>
                                    <br />
                                </>
                            )
                        })}
                        
                    </>
                }
                <TrainingFAQForm />
            </CardContent>
        </Card>
    </>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({document, o}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(o);
    const googleViewer = (pdfUrl) => ("https://docs.google.com/viewerng/viewer?url="+pdfUrl+"&embedded=true")

    // console.log(o)
    useEffect(()=>{
        if (o) {
            setOpen(true);
        }
    }, [o])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
        <Button variant="outlined" color="primary" onClick={handleClickOpen} startIcon={<VisibilityIcon />}>
            Voir
        </Button>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                {document.title}
                </Typography>
                {/* <Button autoFocus color="inherit" onClick={handleClose}>
                save
                </Button> */}
            </Toolbar>
            </AppBar>
            <br /><br /><br />
            <iframe src={googleViewer(api.url+document.document.url)} title={"previewPDF"+document.id} id={"previewPDF"+document.id} frameborder="0" height="100%" width="100%"></iframe>
        </Dialog>
        </>
    );
}
