import React, { useState } from "react";
import { Layout, Notification } from 'react-admin';
import AppBar from './AppBar';
import { AdminSidebar } from './SideBar'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CustomTheme } from "../../theme/CustomTheme";
import { ThemeProvider } from "@material-ui/core";
import api from "../../api";

export const OfflineContext = React.createContext({
    open:false, toggle: ()=>{
        // console.log("toggle dialog")
    }
});

export const AdminOfflineLayout = (props) => {

    const [open, setOpen] = useState(false);
    const handleToggleOpen = () => {
        setOpen(!open);
    };

    return (
        <OfflineContext.Provider value={{open:open, toggle: handleToggleOpen, mode: "offline"}}>
            
            <Layout
                {...props}
                appBar={AppBar}
                sidebar={AdminSidebar}
                notification={Notification}
                title=""
            />
                <ThemeProvider theme={CustomTheme}>
                    <OfflineDialog open={open} handleToggleOpen={handleToggleOpen}/>
                </ThemeProvider>
            
        {/* </ThemeProvider> */}
        </OfflineContext.Provider>
    )
};

const OfflineDialog = ({open, handleToggleOpen}) => {
    const width = "md";
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleToggleOpen}
                maxWidth={width}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{color:"#50BCB8"}}><center>Accès à l’espace d’administration {api?.siteSettings?.name}</center></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{fontSize:16}}>
                        Testez {api?.siteSettings?.name} Alertes pendant 3 mois gratuitement et sans engagement !<br /><br />
                        
                        {api?.siteSettings?.name} Alertes est disponible pour toutes les communes, communautés de communes et syndicats de déchets gratuitement pendant 3 mois dès maintenant pour vous accompagner dans la gestion des dépôts sauvages de déchets sur votre territoire. <br /><br />

                        <center>
                            <Button 
                                target={"_self"} 
                                variant="contained" 
                                color="primary" 
                                style={{backgroundColor: "#50BCB8"}}
                                href="/#/register">
                                    S’inscrire gratuitement
                            </Button>
                        </center><br />

                        L’adhésion à {api?.siteSettings?.name} Alertes vous permet d’avoir accès à beaucoup d’autres fonctionnalités comme :<br />
                        <ul>
                            <li>Un suivi en temps réel des dépôts sauvages détectés par notre communauté sur votre territoire;</li>
                            <li>Savoir où vous en êtes dans le traitement des alertes</li>
                            <li>Garder un historique des actions menées pour chaque alerte;</li>
                            <li>Identifier les typologies de déchets récurrents;</li>
                            <li>Bilans et statistiques,</li>
                            <li>Et bien plus encore ...</li>
                        </ul>
                        <br /><br />
                        <center>
                            <Button 
                                target={"_self"} 
                                variant="contained" 
                                color="primary" 
                                style={{backgroundColor: "#50BCB8"}}
                                href="/#/register">
                                    S’inscrire gratuitement
                            </Button><br />
                            ou 
                            <br />
                            <Button 
                            target={"_blank"} 
                            variant="contained" 
                            color="primary" 
                            style={{backgroundColor: "#50BCB8"}}
                            href="https://calendly.com/la-pade/30min?primary_color=50bcb8">
                                Prendre rendez-vous pour plus d'informations
                            </Button>
                        </center>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}