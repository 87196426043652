import React, { useState } from 'react';
import { Grid, Card, CardContent, CardHeader, Backdrop, CircularProgress } from '@material-ui/core';
import {MyGallery} from '../../UI/Gallery'
import MuiAlert from '@material-ui/lab/Alert';
import 'react-image-crop/dist/ReactCrop.css';
import { UploadMultiMedia, UploadSingleMedia} from '../../UI/InputField/MediaInput';

export const Step3 = ({collectivity, update}) => {
    const [loading, setLoading] = useState(false);
    const handleChange = () => {
        // console.log("handleChange step3")
        update(2);
        setLoading(false)
    }

    return (<>
        <Backdrop
            style={{ color: '#fff', zIndex: 999999 }}
            open={loading}
            onClick={()=>{setLoading(false)}}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <MuiAlert elevation={6} variant="outlined" severity="info" style={{margin:0}} color="info">
            Afin de faciliter l'ajout de votre logo et de la photo de couverture, vous pouvez utiliser votre logo et photo de couverture Facebook.
        </MuiAlert>
        <h2>Gestion de vos images</h2>

        <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
                <UploadSingleMedia
                onChange={handleChange}
                label="Logo"
                setLoading={setLoading}
                image={collectivity?.logo}
                objPath={"logo"}
                aspect={1}
                entity={collectivity}/>
            </Grid>
            <Grid item xs={12} lg={8}>
                <UploadSingleMedia
                onChange={handleChange}
                label="Photo de couverture"
                setLoading={setLoading}
                image={collectivity?.page?.ImageBackground}
                objPath={"page.ImageBackground"}
                aspect={1375/500}
                entity={collectivity}
                size={{width:1375, height: 500}}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <UploadMultiMedia 
                    onChange={handleChange} 
                    setLoading={setLoading}
                    entity={collectivity.page} 
                    field= "ImageGallery" 
                    label="Galerie photo :" 
                />
            </Grid>

            {collectivity?.page?.ImageGallery?.length > 0 && 
            <Grid item xs={12} lg={12}>
                <Card elevation={5}>
                    <CardHeader title={"Apercu galerie"} subheader={``}/>
                    <CardContent style={{padding:16}}>
                        <MyGallery strapiInputs={collectivity.page.ImageGallery} ></MyGallery>
                    </CardContent>
                </Card>
            </Grid>
            }
        </Grid>
    </>)
}