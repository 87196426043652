import React, { useState, useEffect } from 'react';
import { Link, TextField, Button, Grid, Typography, CircularProgress, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from 'react-redux'; 
import api , {fetchToken} from '../../../api'
import { AuthLayout } from '../../Layout/AuthLayout';

export const ForgotPassword = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [warning, setWarning] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const submit = e => {
        e.preventDefault();
        setLoading(true)
        fetchToken(api.url + '/auth/forgot-password', {
            method: "POST",
            body:JSON.stringify({email}),
        })
        .then( (json) => {
            // console.log(json)
            setSuccess("Mail envoyé.")
            setLoading(false)
        }).catch( (err) => {
            // console.log(err);
            setWarning("Mail introuvable.")
            setLoading(false)
        });
    };

    useEffect(() => {
      dispatch({type: 'FILTER_RESET'})
    }, [dispatch])

    return (<AuthLayout>

            <Typography component="h1" variant="h5">
              Administration Collectivités
            </Typography>

            <Typography component="p" variant="subtitle1">
              Récupération de mot de passe
            </Typography>
            <br />
            {loading && <Box padding={15}><CircularProgress /></Box>}

            {!loading && (<>

                {warning && (
                <MuiAlert elevation={6} variant="filled" severity="warning" className={classes.muiAlert}>
                    {warning}
                </MuiAlert>)}

                {success && (
                <>
                <MuiAlert elevation={6} variant="filled" severity="success" className={classes.muiAlert}>
                    {success}
                </MuiAlert>
                <br />
                <Typography>
                    Un lien pour réinitialiser votre mot de passe vient d'être envoyé par email à l'adresse : { email }
                </Typography>
                </>
                )}
                

                {!success && (
                    <form className={classes.form} noValidate onSubmit={submit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <Button type="submit" fullWidth variant="contained" color="secondary" className={classes.submit}>
                            Demande de récupération de mot de passe
                        </Button>
                        <SubLink />
                    </form>
                )}

            </>)}
        </AuthLayout>
    );
};

const SubLink = () => (
    <Grid container>
        <Grid item xs>
            <Link href="#/login" variant="body2">
                Je me souviens
            </Link>
        </Grid>
        <Grid item>
            <Link href="#/register" variant="body2">
                {"Pas encore de compte? M'inscrire"}
            </Link>
        </Grid>
    </Grid>
)

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));