import React from "react";
import { Admin, Resource } from "react-admin";

import { Homepage } from "./components/Pages/Homepage";
import router from "./components/Pages/Router";

import { AlertList, AlertShow } from "./components/Resources/Alert/index";
import { EventList, EventCreate, EventEdit, EventShow } from "./components/Resources/Event/index";
// import { AlertEventCreate } from "./components/Resources/AlertEvent/AlertEventCreate"

import { AlertEventList } from "./components/Resources/AlertEvent/AlertEventList";
import { AlertEventEdit } from "./components/Resources/AlertEvent/AlertEventEdit";
import { AlertEventCreate2 } from "./components/Resources/AlertEvent/AlertEventCreate";
import { BilanList } from "./components/Resources/Bilan/BilanList";

import { CustomTheme } from "./theme/CustomTheme";
import { AdminLayout } from "./components/Layout/AdminLayout";

import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import EventNoteIcon from '@material-ui/icons/EventNote';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import VerifiedUserIcon           from '@material-ui/icons/VerifiedUser';

import { dataProvider, authProvider } from "./api";
import { Login } from "./components/Pages/Authentication/Login";

import polyglotI18nProvider from "ra-i18n-polyglot";
import { i18nCustomTranslation } from '../src/helpers/i18n';

import userReducer from "./redux/userReducer";
import userSaga from "./redux/userSaga";

import areaReducer from "./redux/areaReducer";
import api from "./api";

const i18nProvider = polyglotI18nProvider(i18nCustomTranslation, "fr");

const initialState = () => ({
    account: {
        user: {},
        collectivity: {},
        loading: false,
        tutorial:false
    },
    area: {
        filter: "Loading",
        epci: null,
        postcode: null,
        geometry: false,
        bbox: [-0.4631400991201377, 45.19254658077835, 0.9470359525429137, 46.14089585850345],
        polygon: true,
        select: false
    }
});

const App = () => {

return (
<Admin
    title={api.siteSettings.name+" Admin"}
    theme={CustomTheme}
    layout={AdminLayout}
    customRoutes={router}
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    //Pages
    dashboard={Homepage}
    loginPage={Login}
    // Redux
    initialState={initialState}
    customReducers={{ account: userReducer, area: areaReducer }}
    customSagas={[userSaga]}
    disableTelemetry
>
    <Resource
            options={{ label: "Liste " }}
            name="alerts"
            label="Alertes"
            list={AlertList}
            show={AlertShow}
            icon={NotificationsActiveIcon}
    />
    <Resource 
        // options={{ label: "Suivis d'alerte" }}
        name="alert-events"
        label="Suivis d'alerte"
        list={AlertEventList}
        edit={AlertEventEdit}
        show={AlertEventEdit}
        create={AlertEventCreate2}
        icon={DoneOutlineIcon}
    />
    <Resource
        options={{ label: "Évènements" }}
        name="events"
        label="Events"
        list={EventList}
        edit={EventEdit}
        show={EventShow}
        create={EventCreate}
        icon={EventNoteIcon}
    />

    <Resource
        options={{ label: "Bilans" }}
        name="bilans"
        label="Bilans"
        list={BilanList}
        // edit={EventEdit}
        // show={EventShow}
        // create={EventCreate}
        icon={VerifiedUserIcon}
    />

    <Resource name="alert-comments" />
</Admin>
)};


export default App;
