import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"


export const MarkdownDescription = ({item}) => {

    if (item?.Description) {
        return (
            <ReactMarkdown 
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                children={item?.Description}
                components={{img:({node,...props})=><img alt={item?.id+"desc"} style={{maxWidth:'100%'}}{...props}/>}}
            />
        )
    } else {
        return (null)
    }
}