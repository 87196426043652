
const areaReducer = (state = 0, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'AREA_INIT':
            const defaultInitArea = {
                filter: "ALL",
                epci: null,
                postcode: null,
                geometry: false,
                bbox: [-0.4631400991201377, 45.19254658077835, 0.9470359525429137, 46.14089585850345],
                polygon: true,
                select: {},
                date_start: null,
                date_end: null
            };
            const STRING_SESSION_AREA = localStorage.getItem("SESSION_AREA");
            if (STRING_SESSION_AREA) {
                const newArea = JSON.parse(STRING_SESSION_AREA);
                if (newArea?.filter && newArea?.filter !== "ALL") {
                    localStorage.setItem("CollectivityFilter", newArea.filter);
                } else {
                    localStorage.removeItem("CollectivityFilter");
                }
                return newArea;
            } else {
                return defaultInitArea;
            }
        case 'AREA_UPDATE':
            // console.log("AREA_UPDATE payload:", payload);
            if (payload.filter && (payload.filter !== state.filter)) {
                if (payload.filter === "ALL") {
                    localStorage.removeItem("CollectivityFilter");
                } else {
                    localStorage.setItem("CollectivityFilter", payload.filter);
                }
            }
            const newArea = {
                ...state,
                ...(payload.filter && { filter: payload.filter }),
                ...(payload.geometry && { geometry: payload.geometry }),
                ...(payload.bbox && { bbox: payload.bbox }),
                ...(payload.select && { select: payload.select}),
                ...(typeof payload.polygon === 'boolean' && { polygon: payload.polygon}),
                ...(1 && { date_start: payload.date_start}),
                ...(1 && { date_end: payload.date_end}),
            }
            localStorage.setItem("SESSION_AREA", JSON.stringify(newArea))
            return newArea;
        default:
            return state;
    }
};

export default areaReducer;