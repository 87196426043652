import React, { cloneElement, useEffect, useState } from "react";
import { useSelector } from 'react-redux'; 
import { Redirect } from "react-router-dom"

import { Chip, Paper, Card, CardHeader, CardContent, Grid, useTheme, Typography } from "@material-ui/core";

import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  DateField,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  SelectInput,
  useRefresh
} from "react-admin";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { ImagesLiteField } from "../../Field/ImagesLiteField";
import { BooleanCustomField } from '../../Field/BooleanCustomField';
import { FilterSelectArea } from "../../UI/FilterSelectArea/FilterSelectArea";
import { ContentHeaderTuto } from '../../UI/TutoVideo';

const QuickFilter = ({ label }) => {
  return (
    <Chip
      label={label}
      style={{
        marginBottom: 8,
      }}
    />
  );
};

const AlertFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Rechercher" source="q" alwaysOn />
      <QuickFilter
        source="alert_comments_gte"
        label="Avec des Commentaires"
        defaultValue={true}
      />
      <QuickFilter
        source="alert_events_gte"
        label="Avec des Suivis"
        defaultValue={true}
      />
      <SelectInput alwaysOn source="status" label="Statut" choices={[
          { id: 'RESOLVED', name: 'Résolu' },
          { id: 'PROCESSING', name: 'En cours de traitement' },
          { id: 'UPDATED', name: 'Aggravé' },
          { id: 'CREATED', name: 'Sans suivi' }
      ]} />
    </Filter>
  );
};

const AlertListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};

const AlertDatagrid = (props) => 
<Datagrid rowClick="show">
  {/* <TextField source="id" /> */}
  <DateField label="Création" source="created_at" locales="fr-FR" />
  <DateField label="Mise à jour" source="updated_at" locales="fr-FR" />
  {/* <TextField label="Type" source="title" /> */}
  <TextField
    label="Constatation"
    source="description"
    style={{ width: '10px' }}
  />
  {/* <UserField
    label="Utilisateur"
    source="user"
    sortable={false}
  ></UserField> */}
  
  <TextField label="Commune" source="town" />
  <TextField label="EPCI" source="epci" />
  <BooleanCustomField source="resolved" label="Résolu" showLabel={false}/>
  <BooleanCustomField source="report_info" label="Bilan" showLabel={false}/>
  <ImagesLiteField
    source="images"
    label="Images"
    sortable={false}
  ></ImagesLiteField>
</Datagrid>

export const AlertList = (props) => {
  const { status } = useSelector(state => state.account);
  const { filter } = useSelector(state => state.area);
  const [defaultFilter, setDefaultFilter] = useState(filter);
  const theme = useTheme();
  const refresh = useRefresh();

  useEffect(()=>{
    if (filter !== defaultFilter) {
      // console.log("refresh");
      setDefaultFilter(filter);
      refresh();
    }
    // eslint-disable-next-line
  },[filter])

  if (!status)
    return (null);

  if (!status.valid && status.type==="Association") {
    return (<Redirect push to="/space"></Redirect>)
  } else if (!status.valid) {
    return (<Redirect push to="/dashboard"></Redirect>)
  } else if (!status?.alert) {
    return (<Redirect push to="/dashboard"></Redirect>)
  }

  return (
  <Grid container spacing={1}>
    <Grid item xs={12} lg={12}>
      <Paper>
        <FilterSelectArea contour={false}/>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <Card>
        {/* <CardHeader title={<ContentHeaderTuto title="Liste des Alertes" type="Alerts" />} /> */}
        <CardHeader  style={{paddingBottom:"0px"}}
            title={<ContentHeaderTuto title={<Typography  variant="h5">Liste des Alertes</Typography>} type="Alerts" />}
            avatar={<NotificationsActiveIcon style={{color:theme.palette.secondary.main}} />}
        ></CardHeader>
        <CardContent>
          <List
            filters={<AlertFilter />}
            actions={<AlertListActions />}
            bulkActionButtons={false}
            {...props}
          >
            <AlertDatagrid />
          </List>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
  );
};
