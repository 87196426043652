/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import get from 'lodash/get';
import { 
    Create, 
    Edit, 
    SimpleForm, 
    TextInput, 
    required,
    BooleanInput,
    useEditController,
    Show,
    TabbedForm,
    FormTab,
    TabbedFormTabs,
    useShowController,
    Title,
    Toolbar,
    SaveButton,
    DeleteButton,
    useRedirect,
    useRefresh,
    useFormContext
} from 'react-admin';

import { Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import RichTextInput from 'ra-input-rich-text';
import { DateTimeInput } from 'react-admin-date-inputs';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";

import { AddressInput } from '../../UI/InputField/AddressInput';
import { InputSingleMedia, InputMultiMedia } from '../../UI/InputField/MediaInput';
import { EventCard } from './EventCard'

import { DetailQuantityField } from "../../UI/DetailQuantity";
import api from "../../../api";

export const EventCreate = (props) => (<>
    <MuiAlert elevation={6} variant="filled">Créez un brouillon pour l'évènement.</MuiAlert>

    <Create {...props}>
        <SimpleForm>
            <TextInput variant="outlined" source="title" label="Titre" validate={required()} />
            <DateTimeInput variant="outlined" label="Date de l'évènement" source="date_start" options={{disablePast: true, ampm: false, format: 'dd/MM/yyyy, HH:mm:ss'}} providerOptions={{ utils: DateFnsUtils, locale: frLocale }}/>
            <RichTextInput source="description"/>
            <TextInput variant="outlined" source="link_register" label="Lien d'inscription" />
            <TextInput variant="outlined" source="link_page_event" label="Lien de l'évènement" />
        </SimpleForm>
    </Create>
    </>
);

const editFormStyle = makeStyles((theme) => ({
    formTab_valid: {
        color: theme.palette.primary.main,
    },
    formTab_missing: {
        color: "#ff0000"
    },
    formTab_optionnal: {
        color: "#ee7100"
    }
}));

const toolbarStyle = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}));

const EventEditToolbar = (props) => {
    const redirect = useRedirect();
    const { pristine, record, basePath, canpublish, tab } = props;

    return (
        <Toolbar {...props} classes={toolbarStyle()}>
            <SaveButton 
                saving={false} 
                pristine={false} 
                disabled={pristine} 
                />
            {(tab!==4 && !record.published) && 
                <Button 
                    color="primary" 
                    variant="contained" 
                    disabled={!pristine || record?.published || !canpublish}
                    onClick={()=>redirect(`${basePath}/${record.id}/4`)}>Publier
                </Button>}
            <DeleteButton mutationMode="pessimistic"></DeleteButton>
        </Toolbar>
    )
}

export const EventEdit = (props) => {
    const editControllerProps = useEditController(props);
    const location = useLocation();
    const refresh = useRefresh();
    const { user } = useSelector(state => state.account)
    const style = editFormStyle();
    
    const [canPublish, setCanPublish] = useState(false)
    const [tabStatus, setTabStatus] = useState({
        general: "missing",
        location: "missing",
        images: "missing",
        accessibilite: "optionnal"
    })

    if (!editControllerProps?.record || !user?.collectivite?.id) {
        return (null);
    }

    const { collectivity, id:eventId } = editControllerProps.record;
    if (user?.collectivite?.id !== collectivity?.id) {
        return (<EventShow {...props} />)
    }

    const tabValue = (location) => {
        const { pathname } = location;
        const tabs = pathname.split("/");
        if (tabs?.length === 4) {
            return ( parseInt(tabs[3]) )
        } else {
            return (0);
        }
    }

    const redirectEventEditSubmit = (basePath, id, data) => {
        return location.pathname;
    }

    const validateEvent = (values) => {
        const generalState = () => {
            if (values.title && values.description && values.date_start) {
                return 'valid'
            } else {
                return 'missing'
            }
        }

        const locationState = () => {
            if (values.address?.length > 0 && values.address_latitude !== 0 && values.address_longitude !== 0) {
                return 'valid'
            } else {
                return 'missing'
            }
        }

        const imagesState = () => {
            if (values.cover) {
                return 'valid'
            } else {
                return 'missing'
            }
        }

        const accessibiliteState = () => {
            if (values?.accessibility_tools || 
                values?.accessibility_young || 
                values?.accessibility_disabled || 
                values?.accessibility_animals || 
                values?.accessibility) {
                return 'valid'
            } else {
                return 'optionnal'
            }
        }

        const newTabStatus = {
            general: generalState(),
            location: locationState(),
            images: imagesState(),
            accessibilite: accessibiliteState()
        }

        if (tabStatus.general !== newTabStatus.general ||
            tabStatus.location !== newTabStatus.location ||
            tabStatus.images !== newTabStatus.images ||
            tabStatus.accessibilite !== newTabStatus.accessibilite) {

            const missing = Object.keys(newTabStatus).filter(tab => newTabStatus[tab] === 'missing');
            if (missing.length === 0) {
                setCanPublish(true);
            }
            setTabStatus(newTabStatus)
        }

        return { };
    }

    // console.log(tabStatus);
    const handleImageUploadFinish = () => {
        setTimeout(()=> {
            refresh();
        }, 1000);
    }

    const handleCoverUploadFinish = (newCover) => {
        editControllerProps.record.cover = newCover;

        setTimeout(()=> {
            refresh();
        }, 1000)
    }

    return (<>
    <Title title={" "}></Title>
        <Grid container spacing={4}>
            <Grid item md={4}>
                <Typography variant="h6">Aperçu de l'évènement</Typography>
                <EventShow {...props} card={+true} />
            </Grid>
            <Grid item md={8}>
            <Typography variant="h6">Edition de l'évènement</Typography>
                <Edit {...props} title="Edition Événement" actions={false} mutationMode="pessimistic"
                onSuccess={()=>{
                    // console.log("EDIT onSuccess")
                    refresh();
                }}
                >
                    <TabbedForm
                        redirect={redirectEventEditSubmit} 
                        tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
                        syncWithLocation={true} 
                        validate={validateEvent}
                        toolbar={<EventEditToolbar canpublish={+canPublish} tab={tabValue(location)} />}
                        id="event_edit_form"
                        >
                        <FormTab
                            label="Général"
                            className={style['formTab_'+tabStatus.general]}>
                            <TextInput variant="outlined" source="title" label="Titre" validate={required()} />
                            <DateTimeInput variant="outlined" label="Date de l'évenement" source="date_start" options={{disablePast: true, ampm: false, format: 'dd/MM/yyyy, HH:mm:ss'}} providerOptions={{ utils: DateFnsUtils, locale: frLocale }} validate={required()}/>
                            <RichTextInput source="description" validate={required()} toolbar={[ ['bold', 'italic', 'underline'] ]} />
                            <TextInput variant="outlined" source="link_register" label="Lien d'inscription" />
                            <TextInput variant="outlined" source="link_page_event" label="Lien de l'évenement" />
                        </FormTab>
                        <FormTab label="Lieux" className={style['formTab_'+tabStatus.location]}>
                            <AddressInput source="address" label="Lieux" enable={tabValue(location)===1} ></AddressInput>
                        </FormTab>
                        <FormTab label="Images" className={style['formTab_'+tabStatus.images]}>
                            <InputSingleMedia
                                label="Couverture"
                                source="cover"
                                onChange={handleCoverUploadFinish} 
                                aspect={1.91}
                                size={{width:1200, height:628}}
                            />
                            <InputMultiMedia
                                source="gallery"
                                label="Galerie"
                                onChange={handleImageUploadFinish}
                            />
                        </FormTab>
                        <FormTab label="Accessibilité" className={style['formTab_'+tabStatus.accessibilite]}>
                            <BooleanInput label="Matériel mis à disposition" source="accessibility_tools"></BooleanInput>
                            <BooleanInput label="Accessible aux mineurs" source="accessibility_young"></BooleanInput>
                            {/* <BooleanInput label="Accessible aux personnes handicapées" source="accessibility_disabled"></BooleanInput> */}
                            <BooleanInput label="Accessible aux animaux" source="accessibility_animals"></BooleanInput>
                            <RichTextInput label="Ajouter d’autres informations concernant l’accessibilité" source="accessibility" />
                        </FormTab>
                        <FormTab label="Publier" className={style.formTab}>
                            <Typography>
                                <br />
                                <b>Publier l'évènement</b><br />
                            </Typography>
                            <BooleanInput label={"Publier"} source="published" style={{width: "100%"}} disabled={!canPublish}/>
                            <Typography>
                            Une fois publié, l'évènement devient visible sur notre site et notre application mobile.
                            </Typography>
                        </FormTab>
                        <FormTab label="Bilan" className={style.formTab}>
                            <Typography>
                                <br />
                                <b>Bilan de l'évenement</b><br />
                            </Typography>
                            <DetailQuantityField source='report_info' label="Quantité" />
                            <br />
                            <TextInput variant="outlined" source="report_title" label="Titre" fullWidth/>
                            <RichTextInput label="Description" source="report_description" />
                            <InputMultiMedia
                                source="report_images"
                                label="Images"
                                // onOpen={()=>{editControllerProps.save()}}
                                onChange={handleImageUploadFinish}
                            />
                        </FormTab>
                    </TabbedForm>
                </Edit>
            </Grid>
        </Grid>
        </>
    )
};

export const EventShow = (props) => {
    const {
        basePath,
        record
    } = useShowController(props);

    const card = props?.card || false;
    // console.log(card)

    useEffect(()=> {
        if (record?.id && record?.town && !card) {
            window.location.replace(api.url_front+"/collecte-de-dechets/"+record.town+"/"+record.id);
        }
    }, record)

    if (!card) {
        return (<>Redirection...</>);
    }

    return (
    <Show {...props} actions={null} title={" "}>
        <EventCard event={record} basePath={basePath} showcard={+card}/>
    </Show>)
}