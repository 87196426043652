import React, {useState} from 'react';

import { useSelector } from 'react-redux';

import { List, Datagrid, TextField, 
    ResourceContextProvider,Pagination, DateField, useRecordContext, FunctionField, EditButton
} from 'react-admin';

import { Button, Dialog, DialogTitle, DialogContent, useMediaQuery, useTheme, DialogActions }                     from '@material-ui/core'

import { translateState } from '../../../helpers/Enum';
import { ImagesLiteField }          from '../../Field/ImagesLiteField'
import { AlertEventEditForm } from './AlertEventEdit';
import EditIcon from '@material-ui/icons/Edit';


const AlertEventListFromAlertButton = (props) => {
    const record = useRecordContext(props);
    const { user } = useSelector(state => state.account)
    const [open, setOpen] = useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>  { user?.collectivite?.id === record.user.collectivite && record.status=== "RESOLVED" && 
                <EditButton {...props} label="Bilan"></EditButton>
            }
            { user?.collectivite?.id === record.user.collectivite && record.status !== "RESOLVED" && <>
                <Button variant="text" color="primary" onClick={()=>{setOpen(true)}} startIcon={<EditIcon></EditIcon>}>Editer</Button>
                
                <Dialog open={open} onClose={()=>{setOpen(false)}} fullScreen ={fullScreen}>
                    <DialogActions>
                    <Button onClick={()=>{setOpen(false)}} color="primary">
                        Fermer
                    </Button>
                    </DialogActions>
                    <DialogTitle id="responsive-dialog-title">Edition de suivi</DialogTitle>
                    
                    <DialogContent>
                        <ResourceContextProvider value="alert-events">
                            <AlertEventEditForm id={record.id} resource="alert-events" basePath=""/>
                        </ResourceContextProvider>
                    </DialogContent>
                    
                    
                </Dialog>
                </>
            }
        </>
    )
}

export const AlertEventListFromAlert = (props) => {
    const record = useRecordContext(props);

    return (
        <>
        <h4>Suivis</h4>
        <ResourceContextProvider value="alert-events">
            <List  basePath="/alert-events" filter={{alert:record.id}} perPage={10} bulkActionButtons={false} actions={false} pagination={<Pagination rowsPerPageOptions={[5, 10]}/>} title=" " empty={<span>Pas de suivi</span>} {...props} >
                <Datagrid>
                    <DateField label="Date" source="updated_at" locales="fr-FR"/>
                    <TextField source="description" />
                    <FunctionField label="Statut" render={record => `${translateState(record.status)}`}/>
                    <ImagesLiteField source="images" label="Images"></ImagesLiteField>
                    <AlertEventListFromAlertButton />
                </Datagrid>
            </List>
        </ResourceContextProvider><br />
        </>
    )
};