// in src/filterSaga.js

import { put, takeEvery, call } from 'redux-saga/effects';
import api, {fetchToken} from '../api';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

const queryApi = query => new Promise((resolve, reject) => {
    fetchToken(api.url + query).then(({json}) => {
        resolve(json)
    })
})

function* loginRequest(action) {
    const { payload } = action;
    // console.log("sagaLoginRequest payload:", payload, action);
    try {
        yield put({type: "USER_LOGIN_LOADING"});
        yield call(delay, 50);
        const collectivite = yield call(queryApi, "/collectivities/me")
        
        payload.user.collectivite = collectivite;
        // payload.tutorial = adminTutorial;
        yield put({ type: "USER_LOGIN_LOADED", payload: { user: payload.user, tutorial: payload.tutorial } });
    } catch (e) {
        yield put({type: "USER_LOGIN_FAILED", message: e.message});
    }
}

    function* userRefresh(action) {
        // const { payload } = action;
        // console.log("sagaUserRefresh payload:", action);
        try {
            yield put({type: "USER_REFRESH_START"});
            yield call(delay, 50);
            let user = yield call(queryApi, "/users/meFull");
            let adminTutorial = yield call(queryApi, "/admin-tutorial")
            if (!adminTutorial?.enable) {
                adminTutorial = false;
            }
            let collectivity = null;
            if (user?.collectivite) {
                collectivity = yield call(queryApi, "/collectivities/"+ user?.collectivite?.id )
            }
            user.collectivite = collectivity;
            yield put({type: "USER_REFRESH_SUCCESS", payload: {user, tutorial: adminTutorial}});
        } catch (e) {
            yield put({type: "USER_REFRESH_FAILED", message: e.message});
        }
    }


export default function* userSaga() {
    yield takeEvery('USER_LOGIN_REQUESTED', loginRequest)
    yield takeEvery('SAGA_USER_REFRESH', userRefresh)
}