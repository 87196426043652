export const Status = (user) => {
    if (user?.role?.name === "Collectivité") {
        if (user?.collectivite?.type === "Association") {

            let certified = false;
            let valid = false;

            if (user?.collectivite?.page?.Published && user?.collectivite?.description?.includes('#')) {
                valid = true;
            }

            if (user?.collectivite?.validated) {
                certified = true;
            }

            const out = {
                type: "Association",
                certified,
                valid,
                alert: true,
                training: false
            };
            // console.log("Status :", out)
            return (out);
        } else {
            const out = {
                certified: user?.collectivite?.description?.includes('#'),
                valid: user?.collectivite?.description?.includes('#'),
                type: "Collectivité",
                training: user?.collectivite?.training,
                alert: user?.collectivite?.alert
            }
            // console.log("Status :", out)
            return (out)
        }
    } else if (user?.role?.name === "Citoyen") {
        return {
            type: "Citoyen",
            certified: false,
            valid :false, 
            alert: false,
            training: false
        }
    } else {
        return {
            type: "Public",
            certified: false,
            valid :false, 
            alert: false,
            training: false
        }
    }
};