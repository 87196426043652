import React from "react";

import {
  List,
  Datagrid,
  TextField,
  ResourceContextProvider,
  DateField,
  useRecordContext,
} from "react-admin";

import { UserField } from "../../Field/UserField";

export const AlertCommentListFromAlert = (props) => {
  const record = useRecordContext(props);
  // console.log(record.id)
  return (
    <>
    <h4>Commentaires</h4>
    <ResourceContextProvider value="alert-comments">
      <List
        basePath="/alert-comments"
        filter={{ alert: record.id }}
        perPage={5}
        bulkActionButtons={false}
        actions={false}
        pagination={false}
        title=" "
        empty={<span>Pas de commentaires</span>}
        {...props}
      >
        <Datagrid>
          <DateField label="Date" source="created_at" locales="fr-FR" />
          <UserField label="Utilisateur" source="user"></UserField>
          <TextField source="description" label="Commentaire"/>
          {/* <TextField label="Ville" source="town" />
          <TextField label="Code postal" source="postcode" /> */}
        </Datagrid>
      </List>
    </ResourceContextProvider>
    </>
  );
};
