import React, { cloneElement, useState, useEffect } from "react";

import {
    List,
    TopToolbar,
    useListContext,
    sanitizeListRestProps,
    Filter,
    CreateButton,
    SelectInput,
    TextInput,
    useRefresh
} from "react-admin";

import { useSelector } from 'react-redux'; 
import { Redirect } from "react-router-dom"
import EventNoteIcon from '@material-ui/icons/EventNote';
import { Card, CardContent, CardHeader, Grid, Paper, useTheme, Typography } from "@material-ui/core";

import { FilterSelectArea } from "../../UI/FilterSelectArea/FilterSelectArea";
import { EventCard } from './EventCard';
import { ContentHeaderTuto } from "../../UI/TutoVideo";

export const EventList = (props) => {

    const refresh = useRefresh();
    const { user, status } = useSelector(state => state.account)
    const { filter } = useSelector(state => state.area);
    const [defaultFilter, setDefaultFilter] = useState(filter);
    const theme = useTheme();
    useEffect(()=>{
        if (filter !== defaultFilter) {
            setDefaultFilter(filter);
            refresh();
        }
      // eslint-disable-next-line
    },[filter])

    if (!user || !status)
        return (null);
    if (!status?.valid && status?.type==="Association") {
        return (<Redirect push to="/space"></Redirect>)
    } else if (!status.valid) {
        return (<Redirect push to="/dashboard"></Redirect>)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
                <Paper>
                    <FilterSelectArea contour={false} />
                </Paper>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Card>
                    {/* <CardHeader title={<ContentHeaderTuto title="Liste Evenements" type="Events" />} /> */}
                    <CardHeader  style={{paddingBottom:"0px"}}
                        title={<ContentHeaderTuto title={<Typography  variant="h5">Liste Évènements</Typography>} type="Events" />}
                        avatar={<EventNoteIcon style={{color:theme.palette.secondary.main}} />}
                    ></CardHeader>
                    <CardContent>
                    <List
                        filterDefaultValues={{ collectivity: user?.collectivite?.id,  date_start_filter:"*" }}
                        filters={<EventListFilter />}
                        sort={{ field: 'date_start', order: 'DESC'}}
                        actions={<EventListActions />}
                        bulkActionButtons={false}
                        {...props}
                    >
                        <EventGrid />
                    </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const EventGrid = () => {
    const { ids, data } = useListContext();

    return (
        <Grid container sx={{flexGrow:1}} spacing={2} style={{padding: 8}}>
            {ids.map(id =>
                <Grid item xs={12} md={6} lg={4} key={"event#"+id}>
                    <EventCard event={data[id]} list={true} />
                </Grid>
            )}
        </Grid>
    )
}

const EventListFilter = (props) => {
    const { user } = useSelector(state=>state.account)

    return (
        <Filter {...props}>
            <TextInput label="Rechercher" source="q" alwaysOn />
            <SelectInput size="small" style={{width:220}} allowEmpty={false} source="collectivity" alwaysOn label="Organisateur" emptyText="Filtré par zone" resettable={true} choices={[
                { id: "*", name: 'Tous ( Filtré par zone )' },
                { id: user?.collectivite?.id, name: 'Mes évènements' },
            ]} />
            <SelectInput size="small" style={{width:200}} allowEmpty={false} alwaysOn source="date_start_filter" emptyText="*" label="Terminé/A venir" choices={[
                { id: "*", name: 'Toutes' },
                { id: '<', name: 'Passé' },
                { id: '>', name: 'Futur' },
            ]} />
        </Filter>
    );
};

const EventListActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();


    const { status } = useSelector( state => state.account)

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
                })
            }

            <CreateButton disabled={!status?.certified}></CreateButton>
        </TopToolbar>
    );
};