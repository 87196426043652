// in src/Dashboard.js
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Box, Button, Card, CardContent, CardHeader, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Title } from 'react-admin'
import { useHistory } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { NewClusterMap } from '../../UI/Map/NewClusterMap';
import { FilterSelectArea } from "../../UI/FilterSelectArea/FilterSelectArea"
import { WidgetsDashboard } from "../../UI/Widgets/Widgets";
import { TrainingModuleCard } from "../Training/TrainingModuleCard";
import api, {fetchToken} from '../../../api'
import { DialogLAPADEContext } from "../../Layout/AdminLayout";
import { BarChartGraph } from "../../TimeSeries/BarChartGraph";

export const DashboardGeneral = () => {

    const history = useHistory();
    const { status } = useSelector(state => state.account);
    const [ module, setModule] = useState(null);
    const [ training, setTraining] = useState(null);
    const theme = useTheme();
    const dialogLAPADEContext   = useContext(DialogLAPADEContext);
    const handleOnClickTraining = useCallback(() => history.push('/training/'+training?.id+'/'+module?.id), [history, training?.id, module?.id]);

    useEffect(() => {
        fetchToken(api.url + '/training-modules/11').then(({json}) => {
            setModule(json);
        })
        fetchToken(api.url + '/trainings/'+1).then(({json}) => {
            setTraining((json));
        })
    }, [])

    const handleClusterMapMove = (e) => {

    }

    const handleClick = () => {

    }

    return (
        <>
            <Title title="Tableau de bord"></Title>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <Paper>
                        <FilterSelectArea />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={9} >
                    <Card style={{opacity:status?.alert?1:0.5, position:"relative"}} >
                        <CardHeader  style={{paddingBottom:"0px"}}
                            title={<Typography  variant="h5">Tableau de Bord</Typography>}
                            avatar={<DashboardIcon style={{color:theme.palette.secondary.main}} />}
                        ></CardHeader>
                        <CardContent>
                            <NewClusterMap onMove={handleClusterMapMove} onClick={handleClick} disabled={!status.alert} />
                            <Box mt={4}>
                                <Grid container justifyContent="space-evenly" alignItems="center" style={{width:"100%"}}>
                                    <Grid item>
                                        <center>
                                            <img alt="marker_alert" src="/markers/alert-marker.png"></img>
                                            <p>Alerte</p>
                                        </center>
                                    </Grid>
                                    <Grid item>
                                        <center>
                                            <img alt="marker_process" src="/markers/alert-marker-processing.png"></img>
                                            <p>En cours</p>
                                        </center>
                                    </Grid>
                                    <Grid item>
                                        <center>
                                            <img alt="marker_resolved" src="/markers/alert-marker-resolved.png"></img>
                                            <p>Résolu</p>
                                        </center>
                                    </Grid>
                                </Grid>
                            </Box>
                            <WidgetsDashboard line={true} />
                        </CardContent>
                        {!status?.alert && 
                            <div style={{position:"absolute", top:"0px", left:"0px", width:"100%", height:"100%", zIndex:99999999}} onClick={()=>dialogLAPADEContext.toggleAlert()}></div>
                        }
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={3}>
                    <Card style={{opacity:status?.training?1:0.5, position:"relative"}} >
                        <CardHeader title={"Bibliothèque"} />
                        <CardContent>
                            { (module && training) && 
                                <TrainingModuleCard module={module} training={training}></TrainingModuleCard>
                            }
                            <Box mt={2}>
                                <center>
                                    <Button variant="contained" color="primary" onClick={handleOnClickTraining}>Accéder</Button>
                                </center>
                            </Box>
                            
                        </CardContent>
                        {!status?.training && 
                            <div style={{position:"absolute", top:"0px", left:"0px", width:"100%", height:"100%", zIndex:99999999}} onClick={()=>dialogLAPADEContext.toggleBiblio()}></div>
                        }
                    </Card>
                </Grid>
                <Grid item xs={12} >
                    <Card>
                        <CardHeader title="Alertes Créées/Résolues"></CardHeader>
                        <CardContent>
                            <BarChartGraph width={0} ></BarChartGraph>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
