import React from "react";

import {
    TextField,
    RichTextField
} from "react-admin";

import { useSelector } from 'react-redux'; 
import { Card, CardContent, CardActionArea, CardHeader, CardMedia, Avatar, Grid, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import api, {strapiImage} from '../../../api';
import { DateMomentField } from '../../Field/DateMomentField';
import { MapField } from '../../Field/MapField';
import { AlertImagesGalleryField } from '../../Field/AlertImagesGalleryField';
import { BooleanCustomField } from '../../Field/BooleanCustomField';
import VerifiedUserIcon           from '@material-ui/icons/VerifiedUser';
import PinDropIcon from '@material-ui/icons/PinDrop';

export const EventCard = ({event, basePath, showcard = true}) => {
    let history = useHistory();
    const { user } = useSelector(state => state.account)

    const redirectClick = () => {
        if (event?.collectivity?.id === user?.collectivite?.id) {
            history.push("/events/"+event.id);
        } else {
            // console.log(api.url_front+"/collecte-de-dechets/"+event.town+"/"+event.id);
            // window.location.replace(api.url_front+"/collecte-de-dechets/"+event.town+"/"+event.id)
            window.open(api.url_front+"/collecte-de-dechets/"+event.town+"/"+event.id, '_blank', 'noopener,noreferrer');
            // history.back();
            // window.location.replace("www.clean2gether.com/collecte-de-dechets/"+event.town+"/"+event.id);
            // history.replace("www.clean2gether.com/collecte-de-dechets/"+event.town+"/"+event.id);
        }
    }

    const img = strapiImage(event?.cover, showcard?'<':'>', 'event.cover');
    const imgPadding = img?.height&&img?.width?(img.height * 100 / img.width):36;
    // console.log(img.height * 100 / img.width);

    if (showcard) {
        return (
            <Card key={event.id} elevation={8} >
                <CardActionArea onClick={redirectClick} >
                    <CardMedia
                        style={{height:0, paddingTop:imgPadding+"%"}}
                        image={strapiImage(event?.cover, showcard?'<':'>', 'event.cover').url}
                        title={event.title}
                    />
                    <CardHeader
                        title={<TextField record={event} label="Titre evenement" source="title" />}
                        subheader={<>
                            Organisé par <TextField record={event} label="Titre evenement" source="collectivity.title" /> <br />
                            Date : <DateMomentField record={event} label="Date" source="date_start" />
                        </>}
                        style={{height:"70px"}}
                        avatar={<Avatar  variant="square" alt={event?.collectivity?.title} src={strapiImage(event?.collectivity?.logo, '-').url} />}
                        action={<BooleanCustomField record={event} label="" source="published"/>}
                    />
                    <CardHeader
                        style={{minHeight:60}}
                        avatar={<Avatar style={{backgroundColor: "#FFFFFF00"}}><PinDropIcon color="primary"/></Avatar>}
                        subheader={<>
                                <TextField record={event} label="Région" source="state" /> <TextField record={event} label="Département" source="county" /><br />
                                <TextField record={event} label="Lieux" source="address" />
                        </>}
                    />

                    { (event?.report_info && event?.report_info['total'] && parseFloat(event?.report_info['total']) > 0) ? ( 
                    <CardHeader
                        avatar={<Avatar style={{backgroundColor: "#FFFFFF00"}}><VerifiedUserIcon color="primary"/></Avatar>}
                        subheader={<>
                                Total ramassé : {event?.report_info && event?.report_info['total'] } Kg
                        </>}
                    />)
                    :
                    (<CardHeader
                        avatar={<Avatar style={{backgroundColor: "#FFFFFF00"}}><VerifiedUserIcon color="transparent"/></Avatar>}
                        subheader={<></>}
                    />)
                }

                    <CardContent >
                        {!showcard && <>
                            <ShowMoreRichTextField initOpen={!showcard}>
                                <RichTextField record={event} source="description" label="Description" style={{ width: '10px' }}/>
                            </ShowMoreRichTextField>
                            {(event?.gallery?.length > 0) && 
                                <AlertImagesGalleryField record={event} source="gallery" maxWidth="sm" />
                            }
                        </>}
                    </CardContent>
                    
                </CardActionArea>
            </Card>
        )
    } else {
        return (<Card key={event.id} elevation={8}>
            <CardMedia
                style={{height:0, paddingTop:imgPadding+"%"}}
                image={strapiImage(event?.cover, showcard?'<':'>').url}
                title={event.title}
            >
            </CardMedia>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{marginTop: "-50px", backgroundColor: "#fff" ,borderLeft: "1px solid rgb(150,150,150)", boxShadow:"rgb(200 200 200) -5px 1px 16px"}}>
                        <CardHeader
                            title={<>Organisateur <TextField record={event} label="Titre evenement" source="collectivity.title" /></>}
                            subheader={<>
                                <DateMomentField record={event} label="Date" source="date_start" format={true} /><br />
                                <DateMomentField record={event} label="Date" source="date_start" />
                            </>}
                            avatar={<Avatar  variant="square" alt={event?.collectivity?.title} src={strapiImage(event?.collectivity?.logo, '-').url} />}
                            action={<BooleanCustomField record={event} label="" source="published"/>}
                        />
                        Département: <TextField record={event} label="Région" source="state" /> <TextField record={event} label="Département" source="county" /><br />
                        Adresse : <TextField record={event} label="Lieux" source="address" />
                        <Divider style={{ margin: "10px 0" }}/>
                        {/* Accéssibilité: <br /><br /> */}
                        <BooleanCustomField record={event} label="Matériel mis à disposition" source="accessibility_tools" alwaysVisible={false}/>
                        <BooleanCustomField record={event} label="Accessible aux mineurs" source="accessibility_young" alwaysVisible={false}/>
                        <BooleanCustomField record={event} label="Accessible aux personnes handicapées" source="accessibility_disabled" alwaysVisible={false}/>
                        <BooleanCustomField record={event} label="Accessible aux animaux" source="accessibility_animals" alwaysVisible={false}/>
                        
                        <RichTextField record={event} source="accessibility" label="Infos complémentaires" style={{ width: '10px' }}/>
                    </Grid>
                    <Grid item xs={12} md={8} style={{padding: "5%"}}>
                        <MapField record={event} source="address_"/>
                        <TextField record={event} label="Titre evenement" source="title" variant="h4" color="secondary"/>
                        <ShowMoreRichTextField initOpen={!showcard}>
                            <RichTextField record={event} source="description" label="Description" style={{ width: '10px' }}/>
                        </ShowMoreRichTextField>
                        {(event?.gallery?.length > 0) && 
                            <AlertImagesGalleryField record={event} source="gallery" />
                        }
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
        </Card>)
    }
}

const ShowMoreRichTextField = ({children, initOpen = false}) => {
    const open = initOpen;

    const MIN_HEIGHT = 40;
    const BORDER_HEIGHT = 40;
    return (
        <div style={{maxHeight: !open?MIN_HEIGHT+50:999, minHeight: MIN_HEIGHT, overflow:"hidden", position: "relative", maxWidth:"100%"}} >
            {children}
            <div style={{position:open?"relative":"absolute", top: open?0:MIN_HEIGHT+10,  height: BORDER_HEIGHT, width: "100%", background: open?"none":"linear-gradient(#FFFFFF00, #FFFFFFFF)", textAlign:"center"}}>
                {/* <Button variant="contained" color="secondary" onClick={()=>{setOpen(!open)}}>Afficher la suite</Button> */}
            </div>
        </div>
    )
}
