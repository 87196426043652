import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { DateField } from "react-admin";
import { Typography, Button, TextField, Grid, Paper, Divider, Box } from "@material-ui/core";
import api, { fetchToken, fetchGeoJson } from "../../../api"
import { PickerMap } from "../../UI/Map/PickerMap";

import MuiAlert from '@material-ui/lab/Alert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { AlertDialog } from '../../UI/AlertDialog';

export const AccountRoleForm = ({update}) => {
  const { status } = useSelector(state => state.account)

  if (!status)
    return (null)
  if (status.type === "Citoyen") {
    return (<ShowRoleCitoyen />)
  }

  if (status.type ==="Association") {
    return (<ShowRoleAssociation/>)
  }

  if (status.type === "Collectivité") {
    return (<ShowRoleCollectivite/>)
  }
  return (null)
}

const ShowRoleCitoyen = () => {
  const [state, setState] = useState();
  const [joinRequest, setJoinRequest] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const refreshCollectivityJoinRequest = () => {

    if (!refresh)
      return ;
    fetchToken(api.url + `/collectivity-join-requests/1`, {
      method: "GET",
    }).then(({json}) => {
      console.log("result refresh join request:", json)
      setJoinRequest(json);
      setState(false);
      setRefresh(false)
    }).catch((e) => {
      setJoinRequest(false);
      setState(false);
      console.log("error:", e);
      setRefresh(false)
    })
  }

  useEffect(refreshCollectivityJoinRequest, [refresh])

  const handleDeleteCollectivityJoinRequest= () => {
    console.log("handleDeleteRequest")
    fetchToken(api.url + `/collectivity-join-requests/1`, {
      method: "DELETE",
    }).then(({json}) => {
      setRefresh(true);
    }).catch((e) => {
      console.log("error:", e);
    })
  }

  const handleCreateCollectivityJoinRequest = (collectivityId, message) => {
    fetchToken(api.url + `/collectivity-join-requests`, {
      method: "POST",
      body: JSON.stringify({status:"WAITING", query: message, collectivity: collectivityId})
    }).then(({json}) => {
        setRefresh(true);
        console.log("callback ", json)
    }).catch((e) => {
      console.log("error:", e);
    })
  }

  return (<>
    <Typography variant="h5" component="span"> Type de compte </Typography>
    <Typography variant="h6" component="span" color="primary">Citoyen</Typography>
    <br />

    {!joinRequest && <>
      Vous souhaitez modifier votre type de compte ?<br /><br />
      <Button variant="contained" color="primary" onClick={()=>setState('join')}>Rejoindre une association</Button><br /><br />
      <Button variant="contained" color="secondary" onClick={()=>setState('create')}>Créer une association</Button>
      {state==="create" && <ShowRoleCitoyenCreate />}
      {state==="join" && <ShowRoleCitoyenJoin handleCreateCollectivityJoinRequest={handleCreateCollectivityJoinRequest}/>}
    </>}

    {joinRequest && <>
      <Grid container>
        <Grid item xs={12} sm={9}>
          Vous avez envoyé une demande pour rejoindre <b>l'association </b> : &nbsp;
          <Typography variant="h6" component="span" color="primary">
            {joinRequest.collectivity.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" color="secondary" onClick={()=>handleDeleteCollectivityJoinRequest(joinRequest.id)}>Annuler</Button>
        </Grid>
      </Grid>
      <br /><br />
    </>}
  </>
  )
}

const ShowRoleAssociation = () => {
  const [collectivityJoinRequests, setCollectivityJoinRequests] = useState([])
  const { user } = useSelector(state => state.account)

  const handleLeave = () => {
    fetchToken(api.url + `/collectivities/leave`, {
      method: "GET",
    }).then(({json}) => {
        console.log("callback ", json)
        window.location.reload();
    }).catch((e) => {
      console.log("error:", e);
    })
  }

  const refreshJoinRequest = () => {
    fetchToken(api.url + `/collectivity-join-requests`, {
      method: "GET",
    }).then(({json}) => {
        // console.log("callback ", json)
        setCollectivityJoinRequests(json)
    }).catch((e) => {
      console.log("error:", e);
    })
  }

  const handleAcceptJoinRequest = (request) => {
    fetchToken(api.url + `/collectivity-join-requests/accept/${request.id}`, {
      method: "GET",
    }).then(({json}) => {
        console.log("callback ", json)
        // setCollectivityJoinRequests(json)
        window.location.reload();
    }).catch((e) => {
      console.log("error:", e);
    })
  }

  const handleDeclineJoinRequest = (request) => {
    fetchToken(api.url + `/collectivity-join-requests/decline/${request.id}`, {
      method: "GET",
    }).then(({json}) => {
        console.log("callback ", json)
        // setCollectivityJoinRequests(json)
        window.location.reload();
    }).catch((e) => {
      console.log("error:", e);
    })
  }

  useEffect(refreshJoinRequest, [])

  return (<>
    <Typography>
      Administrateur de l'association <b>{user.collectivite.title}</b>
    </Typography>
    <p>Demande pour rejoindre l'association:</p>
    { collectivityJoinRequests?.length > 0 && <>
      <Paper>
        <List>
          {collectivityJoinRequests.map(request => (
            <ListItem>
              <ListItemText 
                primary={<>{request.user.username} le <DateField record={request} source="created_at" /></>}
                secondary={<>Commentaire : {request.query}</>}
              />
              <ListItemSecondaryAction>
                <Button 
                  onClick={(e)=>handleAcceptJoinRequest(request)}
                  edge="end" 
                  variant="contained" 
                  color="primary" >
                    Accepter
                </Button> 
                &nbsp;
                <Button 
                  onClick={(e)=>handleDeclineJoinRequest(request)}
                  edge="end" 
                  variant="contained" 
                  style={{backgroundColor:"#ff0000", color:"#FFF"}} >
                    Refuser
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </>}

    <AlertDialog 
      buttonProps={{color:"primary", variant:"contained"}} 
      buttonText="Quitter cette Association" 
      dialogTitle="Quitter cette Association" 
      onConfirm={handleLeave}>
        Êtes-vous sûrs de vouloir quitter l'association {user.collectivite.title} ? 
      </AlertDialog>
    {/* <Button color="primary" variant="contained" onClick={handleLeave}>Quitter cette Association</Button><br /> */}
  </>)
}

const ShowRoleCitoyenCreate = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState(false)

  const handleSubmit = () => {
    const data = {
      title: name,
      type: "Association"
    }
    fetchToken(api.url + `/collectivities`, {
      method: "POST",
      body:JSON.stringify(data),
    }).then(({json}) => {
        console.log("callback ", json)
        window.location.reload();
    }).catch((e) => {
      setError("Une collectivité porte déja ce nom")
      console.log("error:", e);
    })
  }
  return (
    // <Card>
    <Box style={{marginTop:20}}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <MuiAlert
              elevation={6} 
              variant="outlined" 
              severity="info" 
              color="info" 
              // className={classes.muiAlert}
              >Pour créer votre association sur Clean2gether saisissez le nom, <br />
              une fois crée vous pourrez paramétrer votre association ( Descriptions, Zones, Illustration, ... ) sur la page dédié à votre Espace d'association.<br />
              Aprés avoir remplis les informations de votre association une vérifications par notre équipe est nécéssaire avant la publications.<br /><br />
              <TextField value={name} fullWidth error={Boolean(error)} helperText={error} onChange={(event)=>setName(event.target.value)} label="Nom de l'association"/> 
              <br /><br />
              <Button color="primary" variant="contained" disabled={name?.length < 5} onClick={handleSubmit}>Créer l'association</Button>
            </MuiAlert>
        </Grid>
        {/* <Grid item xs={6}>
          <TextField value={name} fullWidth error={Boolean(error)} helperText={error} onChange={(event)=>setName(event.target.value)} label="Nom de l'association"/> 
        </Grid>
        <Grid item xs={6}>
          <Button color="primary" variant="contained" disabled={name?.length < 5} onClick={handleSubmit}>Créer</Button>
        </Grid> */}
      </Grid>
    </Box>
  )
}

const ShowRoleCitoyenJoin = ({handleCreateCollectivityJoinRequest}) => {
  const [collectivitiesFromMap, setCollectivitiesFromMap] = useState([]);
  const [selectedCollectivity, setSelectedCollectivity] = useState(false);
  const [collectivityGeometry, setCollectivityGeometry] = useState(false);
  const [joinRequestMessage, setJoinRequestMessage] = useState("");

  const handlePickerMapUpdatePosition = ({location}) => {
    fetchToken(api.url + `/collectivities/findFromCoord?LATITUDE=${location.latitude}&LONGITUDE=${location.longitude}`, {
      method: "GET",
    }).then(({json}) => {
        // console.log("callback ", json)
        setCollectivitiesFromMap(json);
    }).catch((e) => {
      console.log("error:", e);
    })
  }

  useEffect(()=> {
    if (selectedCollectivity?.description)
      fetchCollectivityGeometry(selectedCollectivity.description);
  }, [selectedCollectivity])

  const fetchCollectivityGeometry = async (description) => {
    const zoneArray = description.trim().split("\n");
    console.log("zoneArray", zoneArray);
    const GeoJsonArray = await Promise.all(zoneArray.map(zone=>{
      return new Promise((resolve, reject) => {
        const [type, name] = zone.split('#');
        fetchGeoJson(type, name)
        .then(data => {
          console.log("fetchGeoJson result:", type, name, data);
          resolve(data);
        }).catch(e => {
          console.log("fetchGeoJson error:", type, name, e);
          reject(e);
        })
      })
    }));
    setCollectivityGeometry(GeoJsonArray);
    console.log("Loading finish :", GeoJsonArray);
  }

  return (<>
    <Divider style={{margin: "30px 0"}}/>
    <Typography variant="h5">Rechercher une association par secteur :</Typography>
    <Typography variant="caption">
      Cliquez (ou déplacez le marqueur) sur votre zone d'intervention, 
      afin d'obtenir les associations présentent sur le territoire.
    </Typography>
    <Grid container spacing={8}>
      <Grid item xs={12} md={6}>
        <PickerMap 
        init={{zoom: 6, address: null, latitude: 46.4681, longitude: 2.46, alertsID: null, updateAddress: false}} 
        styleMap={{height: "45vh"}}
        handleUpdate={handlePickerMapUpdatePosition} 
        geometry={collectivityGeometry}
        enable={true}
        />
      </Grid>
      <Grid item xs={12} md={6}>
      <br />
      {collectivitiesFromMap?.length > 0 && <>
        <Typography variant="h6" >Associations présentent sur cette zone :</Typography>
        <ul style={{paddingInlineStart: 0, listStyle: "none"}}>
          {collectivitiesFromMap.map((collectivity,index) => {
            return (<li key={"button"+index} style={{margin:"10px 0"}}>
              <Button key={"button"+index} variant="contained" color={collectivity.id === selectedCollectivity.id?"primary":"secondary"} onClick={()=>setSelectedCollectivity(collectivity)}>
                {collectivity.title}
              </Button>
            </li>)
          })}
        </ul>
      
        {selectedCollectivity && <>
          Vous avez sélectionné : <Typography variant="h6" color="primary" component="span" >{selectedCollectivity.title}</Typography> <br /><br />
          Pour faciliter votre demande auprès de l'association, veuillez communiquer les informations nécessaires comme votre numéro d'adhérent, nom et prénom.
          <TextField fullWidth={true} label="Message joint à la requète" multiline={true} value={joinRequestMessage} onChange={(event)=>setJoinRequestMessage(event.target.value)}/>
          <br /><br />
          <Button key={"buttonJoin"} color="primary" variant="contained" onClick={()=>handleCreateCollectivityJoinRequest(selectedCollectivity.id, joinRequestMessage)}>Envoyer une demande</Button><br />
        </>}
      </>}
      </Grid>
    </Grid>
    

    </>
  )
}

const ShowRoleCollectivite = () => {
  const { user } = useSelector(state => state.account)
  return (
    <Typography>
      Administrateur de la collectivité <b>{user.collectivite.title}</b>
    </Typography>
  )
}