import { strapiRest } from './ra-strapi-rest';
import { strapiAuth } from './ra-strapi-auth'
import { strapiImageFormat } from './strapiImage';
import { fetchJsonWithToken } from './httpClientToken'
import { api } from './config'
import { fetchIGNAddress, fetchIGNGeoreverse, fetchAdministration, fetchCollectivityFromLocation, fetchGeoJsonNomi } from './apiAddressSearch'

export const dataProvider = strapiRest(api.url, fetchJsonWithToken)
export const authProvider = strapiAuth;
export const fetchToken = fetchJsonWithToken;
export const fetchAddress = fetchIGNAddress;
export const fetchGeoreverse = fetchIGNGeoreverse;
export const fetchAdmin = fetchAdministration;
export const fetchGeoJson = fetchGeoJsonNomi;
export const fetchCollectivitiesFromLocation = fetchCollectivityFromLocation;
export const strapiImage = strapiImageFormat;

export default api;