import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, Typography, useTheme, Grid, CardActionArea, CardMedia, makeStyles, Breadcrumbs, Link, Icon } from "@material-ui/core";
import api, {fetchToken, strapiImage } from '../../api'
import { useLocation, useParams } from "react-router-dom";
import { FileDocument } from './Training/TrainingModuleChapterFull';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme) => ({
    titleHeader: {
        fontSize:"0.7em",
        margin: 0,
        padding: 0,
        fontWeight:"bold"
    },
    link: {
        display: 'flex',
    },
    media: {
        height: 0,
        paddingTop: '70%',
        // backgroundColor:theme.palette.secondary.main,
        backgroundSize: 'contain'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
        color: theme.palette.secondary.main
    },
}));

export const Services = (props) => {
    const theme = useTheme();
    const [services, setServices] = useState(null);
    const classes = useStyles();
    const location = useLocation();
    useEffect(() => {
        fetchToken(api.url + '/partners').then(({json}) => {
            console.log(json)
            console.log(strapiImage(json[0]?.logo, '+').url)
            setServices(json);
        })
    }, [location.pathname])

    return (
        <Card>
            <CardHeader  style={{paddingBottom:"0px"}}
                title={<Typography  variant="h5">Services</Typography>}
                avatar={<Icon style={{color:theme.palette.secondary.main}}>support_agent</Icon>}
            ></CardHeader>

            <CardContent>
                <Typography variant="subtitle1">
                
                </Typography>
                <br />
                { (services && services?.length > 0) &&
                    <Grid container spacing={2}>
                        { services.map((service) => {
                            return (
                                <Grid item lg={4} md={6} xs={12}>
                                    <Card>
                                        <CardActionArea href={"/#/services/"+service.id}>
                                            <CardHeader title={<center><p className={classes.titleHeader}>{service?.title}</p></center>} ></CardHeader>
                                            <CardMedia
                                                className={classes.media}
                                                image={strapiImage(service?.logo, '+').url}
                                                title={service?.title}
                                            />
                                            <CardContent>
                                                {service.description}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </ Grid>
                            )
                        })}
                    </ Grid>
                }
            </CardContent>
        </Card>
    )
}

export const Service = (props) => {

    const params = useParams();
    const [service, setService] = useState(false);
    const location = useLocation();
    const classes = useStyles();

    useEffect(() => {
        fetchToken(api.url + '/partners/'+params?.id).then(({json}) => {
            console.log(json)
            setService(json);
        })
    }, [location.pathname, params?.id])

    
    return (<>
    <Card>
        <CardContent>
        <CardHeader title={
            <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/#/services"  className={classes.link}>
            <HomeIcon className={classes.icon} />Services
        </Link>
        
        <Link color="inherit" href={"/#/services/"+service?.id}>
            {service && <b>{service?.title}</b>}
        </Link>

        </Breadcrumbs>

        }></CardHeader>
        <center><img width="30%" alt={service?.title} src={strapiImage(service?.logo, '+').url}/></center>
        <CardContent>
            {service.description}
        </CardContent>
    { service?.document && 
        <FileDocument file={service.document} height="1200px"></FileDocument>
    }
    </CardContent>
</Card>

    </>)
}