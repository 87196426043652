import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, 
    ButtonGroup, 
    Button, IconButton, 
    Card, 
    CardActionArea, 
    CardMedia, 
    CardContent, 
    CardActions, 
    CardHeader, 
    Dialog, 
    DialogContent, 
    Box,
    CircularProgress  
} from '@material-ui/core';

import { useForm } from 'react-final-form';

import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';
import api, {fetchToken, strapiImage} from '../../../api'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles((theme) => ({
    cropButtonGroup: {
        position: "absolute",
        top: 0,
        right: 0, 
        zIndex:99999
    },
    previewCanvas: {
        position: "absolute",
        top: 0,
        left: 0
    },
    media: {
        width: "auto",
        height: 300,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
    },
    input: {
        display: 'none',
    }
}));


// SINGLE IMAGES
export const InputSingleMedia = (props) => {
    const {
        source,
        label,
        record,
        resource,
        aspect = 1,
        elevation= 1,
        size,
        onChange = () => {}
    } = props;


    const [loading, setLoading] = useState(false);

    const handleChange = (newImage) => {
        if (newImage) {
            onChange(newImage);
        }
        setLoading(false)
    }

    if (loading) {
        return (<Grid container justifyContent="center"><Box padding="10%"><CircularProgress /></Box></Grid>);
    } else {
        return (
            <UploadSingleMedia
            disabled={loading}
            onChange={handleChange}
            entity={record}
            label={label}
            objPath={source}
            setLoading={setLoading}
            image={record?record[source]:null}
            resource={resource}
            aspect={aspect}
            size={size}
            elevation={elevation} 
            />
        )
    }
}

export const UploadSingleMedia = ({
    entity, 
    onChange, 
    label, 
    image, 
    objPath, 
    aspect = 1, 
    resource = "collectivities",
    size={width:180, height: 180}, 
    elevation = 5,
    setLoading}) => {
    const [current, setCurrent] = useState(image?`${strapiImage(image, '>').url}`:false);
    const classes = useStyles();

    const handleChangeImage = (objPath, blob, setter) => {
        if (objPath) {
            setLoading(true);
            // Delete Previous image then
            blob.name = "upload_"+resource+"_"+objPath+"_"+entity.id+".jpg";
            fetchToken(api.url+'/'+resource+'/'+entity.id, {
                method: "PUT",
                body:JSON.stringify(objPath),
            },[blob], "files."+objPath).then(({json}) => {
                // window.location.reload();
                // console.log(json, objPath)
                onChange(json[objPath]);
            })

            setter(URL.createObjectURL(blob));
        } else {
            // console.log("onChange null")
            onChange(null);
        }
    }
    // const txtImageSize = image?`Actuel (${image.width}*${image.height})`:""

    return (
        <Card elevation={elevation}>
            <CardHeader 
                title={label} 
                subheader={`Taille recommandée: (${parseInt(size.width)}*${parseInt(size.height)})`}
            />
            <CardActionArea style={{padding:16}} onClick={()=>{document.getElementById(`inputFile${objPath}`).click()}}>
                { current && <CardMedia
                    className={classes.media}
                    image={current}
                    title={label}
                />}
                {/* {!current && <Skeleton variant="rect"  height={300} animation="pulse"/>} */}
            </CardActionArea>
            <CardActions>
                <InputImageCrop objPath={objPath} image={current} setter={setCurrent} handleChange={handleChangeImage} aspect={aspect}/>
            </CardActions>
        </Card>
    )
}

export const ImageGallery = ({image, onRemove}) => {
    const current = `${strapiImage(image, '>').url}`;
    const classes = useStyles();

    return (
        <Card elevation={3}>
            <CardMedia image={current} style={{height:150, width: 200}} >
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    
                    onClick={()=>{onRemove(image.id)}}
                >
                    <DeleteIcon />
                </Button>
                {/* <IconButton color="primary">
                    <DeleteIcon />
                </IconButton> */}
            </CardMedia>
        </Card>
    )
}

export const InputImageCrop = ({objPath, image, handleChange, setter, aspect = 16/9, formDirty = false}) => {
    const classes = useStyles();
    const [upImg, setUpImg] = useState();
    // eslint-disable-next-line
    const [preview, setPreview] = useState(false);
    const [crop, setCrop] = useState({ unit: '%', width: 100, aspect });
    const [completedCrop, setCompletedCrop] = useState(null);
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const notify = useNotify();

    const handleClickInput = (event) => {
        if (formDirty) {
            event.preventDefault();
            event.stopPropagation();
            // console.log("STOP NOT CLEAN FORM")
            notify("Enregistrez avant d'ajouter une image", 'info');
        }
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }
    
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
    
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
    
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    const handleCropDone = () => {
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
        // console.log(crop, canvas)
        if (!crop || !canvas) {
            return;
        }
        // console.log("canvas to blob");
        canvas.toBlob((blob) => { 
            handleChange(objPath, blob, setter);
            reset();
        })
    }

    const reset = () => {
        setUpImg(null);
        // setPreview(false);
        // setCrop({ unit: '%', width: 100, aspect });
        // setCompletedCrop(null);
    }

    const handleCropCancel = () => {
        reset();
        document.getElementById(`inputFile${objPath}`).value="";
        handleChange(false);
    }

    return (<>
    <input 
        accept="image/*" 
        className={classes.input} 
        id={`inputFile${objPath}`} 
        type="file" 
        onClick={handleClickInput}
        onChange={onSelectFile}/>
    <label htmlFor={`inputFile${objPath}`}>
        <IconButton color="primary" aria-label="upload picture" component="span">
        <PhotoCamera /> 
        </IconButton>
        Envoyer une image
    </label>
        {/* {image && 
    <IconButton color="primary" aria-label="upload picture" component="span" onClick={()=>{cropCurrentImage()}}>
        <CropIcon />
      </IconButton>} */}

    <Dialog
        open={Boolean(upImg)}
        onClose={handleCropCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogContent>
            <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                crossorigin="anonymous"
            >

            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" className={classes.cropButtonGroup}>
                <Button disabled={!completedCrop?.width || !completedCrop?.height} onClick={handleCropDone}><DoneIcon/></Button>
                <Button onClick={handleCropCancel}><CancelIcon/></Button>
            </ButtonGroup>
                <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                    opacity: preview?1:0,
                    zIndex:preview?999999:-1
                    }}
                    className={classes.previewCanvas}
                />
            </ReactCrop>
        </DialogContent>
    </Dialog>

    </>)
}


// MULTI IMAGES
export const InputMultiMedia = (props) => {
    const {
        source,
        label,
        record,
        resource,
        onChange = () => {},
        onOpen= () => {
            // console.log("onOpen")
        }
    } = props;

    const [loading, setLoading] = useState(false);
    const form = useForm();
    const formState = form.getState();



    const handleChange = (newImage) => {
        // submit()
        onChange(newImage);
        // setLoading(false)
    }

    if (loading) {
        return (<Grid container justifyContent="center"><Box padding="10%"><CircularProgress /></Box></Grid>);
    } else {
        return (
            <UploadMultiMedia
                disabled={loading}
                onChange={handleChange}
                onOpen={onOpen}
                entity={record}
                label={label}
                sublabel={null}
                field={source}
                setLoading={setLoading}
                resource={resource}
                elevation={1}
                formDirty={formState.dirty}
            />
        )
    }
}

export const UploadMultiMedia = ({
    entity, 
    onChange,
    onOpen,
    label, 
    sublabel="", 
    field, 
    setLoading,
    resource = "collectivity-pages",
    elevation = 5,
    formDirty= false
}) => {

    const content = entity;
    const fieldSplit = field.split('.');
    // console.log(fieldSplit, field, content)


    let contentField = [];
    try {
        contentField = (fieldSplit.length > 1)?content[fieldSplit[0]][fieldSplit[1]]:content[field];
    } catch (e) {

    }

    const handleRemove = (id) => {

        const ImageGalleryArray = contentField.map(i => i.id).filter(i => i !== id)



        // console.log("body send :", 
        // JSON.stringify({
        //     ...(fieldSplit.length>1 && {
        //         [fieldSplit[0]]:{
        //             [fieldSplit[1]]:ImageGalleryArray,
        //             ...entity.report
        //     }}),
        //     ...(fieldSplit.length === 1 && {
        //         [field]: ImageGalleryArray
        //     }),
            
        // }))
        fetchToken(api.url+'/'+resource+'/'+content.id, {
            method: "PUT",
            body:JSON.stringify({
                ...(fieldSplit.length>1 && {
                    [fieldSplit[0]]:{
                        ...entity.report,
                        [fieldSplit[1]]:ImageGalleryArray,
                        // info:entity.report.info,
                        // description:entity.report.description
                }}),
                ...(fieldSplit.length === 1 && {
                    [field]: ImageGalleryArray
                }),
                
            }),
        }).then(({json}) => {
            // window.location.reload();
            // console.log(json)
            onChange();
        })
    }

    const handleNewUpload = (objPath, blob, setter, field) => {
        // console.log("handleNewUpload:", objPath)
            if (blob) {
                // console.log("blob content:", blob);
                blob.name = "upload_"+resource+"_"+objPath
                +"_"+content.id+".jpg";
                setLoading(true);
                // console.log("handleNewUpload with blob field", field)
                fetchToken(api.url+'/'+resource+'/'+content.id, {
                    method: "PUT",
                    body:JSON.stringify(objPath),
                },[blob], "files."+objPath).then(({json}) => {
                    // window.location.reload();
                    // console.log(json)
                    onChange();
                })
            } else {
                onChange();
            }
        // setter(URL.createObjectURL(blob));
    }

    return (
        <Card elevation={elevation}>
            <CardHeader title={label} subheader={sublabel}/>
            <CardContent style={{padding:16}}>
                <Grid container spacing={2}>
                    {contentField && contentField.map((image, index) => 
                        <Grid item key={index}>
                            <ImageGallery
                                image={image} 
                                onRemove={handleRemove}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <InputImageCrop  aspect={4/3} handleChange={handleNewUpload} field={field} objPath={field} onOpen={onOpen} formDirty={formDirty}/> 
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
