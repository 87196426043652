import React from "react";

import {
  DateField,
  Edit,
  SimpleForm,
  useRefresh,
  useEditController,
  Toolbar,
  SaveButton,
  TextInput
} from "react-admin";

import { Redirect } from "react-router-dom"
import { CardHeader, Box} from '@material-ui/core';
import { DetailQuantityField } from "../../UI/DetailQuantity";
import { InputMultiMedia } from '../../UI/InputField/MediaInput';

export const AlertEventEdit = (props) => {

    const editControllerProps = useEditController(props);
    const {
        record, // empty object, unless some values were passed in the location state to prefill the form
    } = editControllerProps;

    if (record?.alert?.id && record.status === "RESOLVED")
    return (
            <Redirect push to={"/alerts/"+record.alert.id+"/show/4"}></Redirect>
    )
    if (record?.alert?.id)
    return (
            <Redirect push to={"/alerts/"+record.alert.id+"/show/2"}></Redirect>
    )
    return (null)
}

export const AlertEventEditForm = (props) => {

    const editControllerProps = useEditController(props);
    const {
        record, // empty object, unless some values were passed in the location state to prefill the form
    } = editControllerProps;
    const refresh = useRefresh();

    if (!record?.title && record?.alert?.id) {
        record.title = "Bilan Alerte #"+record.alert.id;
    }

    if (record?.alert?.id)
    return (
        <Edit
        actions={null} 
        title={" "} 
        onSuccess={()=>{refresh();}}
        mutationMode="pessimistic"
        {...props}>
            <SimpleForm  redirect="show" toolbar={<AlertEventEditToolbar />}>
                {record?.status === "RESOLVED" && <>
                    <CardHeader title="Editer le bilan"></CardHeader>
                    <Box m={2}>
                        <DetailQuantityField source='info' label="Précisez les quantités si possible" helper="Ou ajouter directement le poids total ramassé."></DetailQuantityField>
                    </Box>
                </>}
                <TextInput source="title" label="Titre" multiline fullWidth/>
                <TextInput source="description" label="Description" multiline fullWidth/>
                <InputMultiMedia
                        record={record}
                        source="images"
                        label="Photos"
                        onChange={()=> {setTimeout(()=> {refresh();}, 1000)}}
                />
                <DateField label="Date de publication" source="created_at" />
            </SimpleForm>
        </Edit>
    )
    return (null)
}

const AlertEventEditToolbar = (props) => {
    const { pristine } = props;

    return (
        <Toolbar {...props} >
            <SaveButton disabled={pristine} />
        </Toolbar>
    )
}