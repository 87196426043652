import React, { useState, useEffect } from "react";

import {
    List,
    // TopToolbar,
    useListContext,
    // sanitizeListRestProps,
    // Filter,
    // CreateButton,
    // SelectInput,
    // TextInput,
    useRefresh,
    // RichTextField
} from "react-admin";

import { useSelector } from 'react-redux'; 
import { Redirect } from "react-router-dom"

import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";

// import { FilterSelectArea } from "../../UI/FilterSelectArea/FilterSelectArea";
// import { EventCard } from './EventCard';
import { ContentHeaderTuto } from "../../UI/TutoVideo";
// import { strapiImage } from "../../../api";
import { ShowDetailQuantity } from "../../UI/DetailQuantity";
import { dateFrom } from "../../../helpers/moment";

export const EventListBilan = (props) => {

    const refresh = useRefresh();
    const { user, status } = useSelector(state => state.account)
    const { filter } = useSelector(state => state.area);

    const [defaultFilter, setDefaultFilter] = useState(filter);

    useEffect(()=>{
        if (filter !== defaultFilter) {
            // console.log("refresh");
            setDefaultFilter(filter);
            refresh();
        }
      // eslint-disable-next-line
    },[filter])

    if (!user || !status)
        return (null);
    if (!status?.valid && status?.type==="Association") {
        return (<Redirect push to="/space"></Redirect>)
    } else if (!status.valid) {
        return (<Redirect push to="/dashboard"></Redirect>)
    }

    return (
        <Grid container spacing={1}>
            {/* <Grid item xs={12} lg={12}>
                <Paper>
                    <FilterSelectArea contour={false} />
                </Paper>
            </Grid> */}
            <Grid item xs={12} lg={12}>
                <Card>
                    <CardHeader title={<ContentHeaderTuto title="Bilans sur des évenements" type={null} />} />
                    <CardContent>
                    <List
                        filterDefaultValues={{ collectivity: user?.collectivite?.id,  date_start_filter:"*", report_info_null: false }}
                        filters={<EventListFilter />}
                        sort={{ field: 'date_start', order: 'DESC'}}
                        actions={null}
                        bulkActionButtons={false}
                        {...props}
                    >
                        <EventGrid />
                    </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const EventGrid = () => {
    const { ids, data } = useListContext();

    return (
        <Grid container sx={{flexGrow:1}} spacing={2} style={{padding: 8}}>
            {ids.map(id =>
                <Grid item xs={12} md={6} lg={4} key={"event#"+id}>
                    <EventCardBilan event={data[id]} list={true} />
                </Grid>
            )}
        </Grid>
    )
}

const EventCardBilan = ({event}) => {

    // const img = strapiImage(event?.report_images[0], '<', 'event.cover').url;
    // const imgPadding = img?.height&&img?.width?(img.height * 100 / img.width):36;

    return (<Card>
        <CardContent>
            <ShowDetailQuantity item={event} card={true} />
            {/* <CardHeader title={"a"}></CardHeader> */}
            {/* <CardMedia
                style={{height:0, paddingTop:imgPadding+"%"}}
                image={img}
                title={event.title}
            /> */}
            {/* <RichTextField record={event} source="report_description" label="Description" style={{ width: '10px' }}/> */}
        </CardContent>
        <CardHeader subheader={dateFrom(event.date_start)} ></CardHeader>
        <CardHeader subheader={"Bilan mis à jour : "+dateFrom(event.updated_at)} ></CardHeader>
    </Card>)
}

const EventListFilter = (props) => {
    // const { user } = useSelector(state=>state.account)

    return (
        // <Filter {...props}>
        //     <TextInput label="Rechercher" source="q" alwaysOn />
        //     <SelectInput size="small" style={{width:220}} allowEmpty={false} source="collectivity" alwaysOn label="Organisateur" emptyText="Filtré par zone" resettable={true} choices={[
        //         { id: "*", name: 'Tous ( Filtré par zone )' },
        //         { id: user?.collectivite?.id, name: 'Mes évènements' },
        //     ]} />
        //     <SelectInput size="small" style={{width:200}} allowEmpty={false} alwaysOn source="date_start_filter" emptyText="*" label="Terminé/A venir" choices={[
        //         { id: "*", name: 'Toutes' },
        //         { id: '<', name: 'Passé' },
        //         { id: '>', name: 'Futur' },
        //     ]} />
        // </Filter>
        null
    );
};

// const EventListActions = (props) => {
//     const { className, exporter, filters, maxResults, ...rest } = props;
//     const {
//         resource,
//         displayedFilters,
//         filterValues,
//         showFilter,
//     } = useListContext();


//     const { status } = useSelector( state => state.account)

//     return (
//         <TopToolbar {...sanitizeListRestProps(rest)}>
//             {filters &&
//                 cloneElement(filters, {
//                 resource,
//                 showFilter,
//                 displayedFilters,
//                 filterValues,
//                 context: "button",
//                 })
//             }

//             <CreateButton disabled={!status?.certified}></CreateButton>
//         </TopToolbar>
//     );
// };