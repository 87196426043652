import React                    from 'react';
import { 
    Card, 
    CardContent, 
    Grid, 
    Paper,
    CardActionArea, 
    Button }                    from '@material-ui/core';
import InsertDriveFileIcon      from '@material-ui/icons/InsertDriveFile';
import PictureAsPdfIcon         from '@material-ui/icons/PictureAsPdf';
import api                      from '../../../api'

import { MarkdownDescription } from '../../UI/MardownDescription';

export const TrainingModuleChapterFull = ({chapter}) => {
    return (
        <>
            { chapter?.URLVIDEO  && 
                <div 
                    dangerouslySetInnerHTML={{__html: chapter?.URLVIDEO}}
                />
            }
            { chapter?.video?.url && 
                <video width="100%" height="auto" controls>
                    <source src={api.url + chapter?.video?.url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video> 
            }
            <Paper style={{padding:16, marginBottom: 16}} elevation={2}>
                <MarkdownDescription item={chapter} />
                {/* <Typography variant="body1" component="p">{chapter.Description}</Typography> */}
            </Paper>
            <Grid container spacing={4}>
                {(chapter?.files?.length > 0) && chapter.files.map((file) => <FileDocument key={"file"+file.id} file={file}/>)}
            </Grid>
        </>)
}

export const FileDocument = ({file, height = "800px"}) => {
    const handleOnClick = () => {}
    return (
        <Grid item xs={12}>
            <Card onClick={handleOnClick}>
                <CardActionArea href={api.url + file.url} target="_blank">
                    <CardContent >
                    { file.mime === "application/pdf" && <>
                    <Button  startIcon={<PictureAsPdfIcon style={{color:"#FF0000"}} fontSize={"large"}/>}>Télécharger </Button>
                    {file.name}
                    <br />
                    {file.caption}
                    </>
                    }
                    { file.mime !== "application/pdf" && <>
                    <Button startIcon={<InsertDriveFileIcon style={{color:"#FF0000"}}  fontSize={"large"}/>}>Télécharger </Button>{file.name}
                    <br />
                    {file.caption}
                    </>
                    }
                    </CardContent>
                </CardActionArea>
                { file.mime === "application/pdf" && 
                <CardContent style={{height:height}}>
                    <iframe src={api.url+file.url+"#toolbar=0"} id="previewPDFiframe" title="previewPDFiframe" frameborder="0" height="100%" width="100%"></iframe>
                </CardContent>
                }
            </Card>
        </Grid>
    )
}