import React, { useState } from "react";
import { Form } from 'react-final-form';
import * as yup from 'yup'
import { TextField } from 'mui-rff';
import { useLogin } from 'react-admin';

import AppleSignin from 'react-apple-signin-auth';

import MuiAlert from "@material-ui/lab/Alert";
import { Link, Button, Grid, Typography, CircularProgress, Box, InputAdornment, Divider } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import api from '../../../api'
import { AuthLayout, authStyle, authValidate } from "../../Layout/AuthLayout";
import {sleep} from "../../../helpers/Sleep";

const validationSchema = yup.object({
  identifier: yup.string().required("Requis"),
  password: yup.string().required("Veuillez saisir un mot de passe."),
});

const formFields = [
  {
    size: { xs: 12, sm: 12 },
    field: (<TextField label="Pseudonyme ou Email" name="identifier" margin="none" variant="outlined" required={true} />)
  }
]

export const Login = () => {
  const login = useLogin();
  const classes = authStyle();
  const [togglePassword, setTogglePassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loginApple, setLoginApple] = useState(false);

  const onSubmit = async loginData => {
    setLoading(true);
    await sleep(500);
    let result = await new Promise((resolve, reject) => {
      const username = loginData.identifier;
      const password = loginData.password;
      login({ username, password },"/loading")
      .catch( (err, data) => {
        //  console.log("Connection NOK", err, err.message)
        if (err.message === "account.role.invalid") {
          setWarning("Compte en cours de validation par un administrateur.")
        } else if (err.message === "account.credential.invalid") {
          setWarning("Paire identifiants/Mot de passe incorrecte.", 'info')
        } else if (err.message === "Auth.form.error.confirmed") {
          setWarning("Vous n'avez pas confirmé votre adresse email", 'info')
        } else {
          setWarning("Service momentanément indisponible.")
        }
        resolve()
      });
    })
    setLoading(false);
    return result;
  };

  return (
    <AuthLayout>
      <Typography component="h1" variant="h5">
        Connexion {api?.siteSettings?.name}
      </Typography>
      <Typography component="p" variant="caption">
        Connectez vous pour accéder à l'espace d'administration
      </Typography>

      {warning && 
        <Box m={2}>
          <MuiAlert severity="warning">
            <b>{warning}</b>
          </MuiAlert>
        </Box>}

        <Form
          onSubmit={onSubmit}
          initialValues={{gender:'Autre'}}
          validate={authValidate(validationSchema)}
          render={({ handleSubmit, submitting}) => {

            return (
              <form onSubmit={handleSubmit} noValidate className={classes.form} style={{opacity: loading?"0.5":"1"}}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    {formFields.map((item, idx) => (
                      <Grid item {...item.size?item.size:{xs:12}} key={idx}>
                        {item.field}
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <TextField label="Mot de passe" name="password" type={togglePassword?"password":"text"} margin="none" variant="outlined" 
                        InputProps={{
                          endAdornment:(
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e)=> setTogglePassword(!togglePassword)}
                                onMouseDown={(e)=> setTogglePassword(!togglePassword)}
                                edge="end"
                              >
                                {togglePassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SubLink/>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                      <Button variant="contained" color="secondary" type="submit" disabled={submitting}>
                        Se connecter
                      </Button>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                      <Divider></Divider>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                    <Button
                      variant="contained"
                      style={{backgroundColor:"#3B5998", color:"#FFFFFF"}}
                      startIcon={<FacebookIcon />}
                      href={api.url+"/connect/facebook"}
                    >Se connecter avec facebook</Button>
                    
                  </Grid>
                  <Grid item xs={12} style={{textAlign:"center"}}>
                    {/* <AppleLogin clientId="com.clean2gether.login" scope="email" redirectURI="https://api.local.uplay.ovh/apple/callback" /> */}

                    {loginApple && 
                      <AppleSignin
                        /** Auth options passed to AppleID.auth.init() */
                        authOptions={{
                          /** Client ID - eg: 'com.example.com' */
                          clientId: 'com.clean2gether.login',
                          /** Requested scopes, seperated by spaces - eg: 'email name' */
                          scope: 'name email',
                          /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                          redirectURI: api.url + '/apple/callback',
                          /** State string that is returned with the apple response */
                          state: 'login-admin',
                          /** Nonce */
                          nonce: 'nonce',
                          /** Uses popup auth instead of redirection */
                          usePopup: false,
                        }} // REQUIRED
                        /** General props */
                        uiType="dark"
                        /** className */
                        className="apple-auth-btn"
                        /** Removes default style tag */
                        noDefaultStyle={false}
                        /** Allows to change the button's children, eg: for changing the button text */
                        buttonExtraChildren="SE CONNECTER AVEC APPLE"
                        /** Extra controlling props */
                        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                        // onSuccess={(response) => console.log(response)} // default = undefined
                        /** Called upon signin error */
                        onError={(error) => console.error(error)} // default = undefined
                        /** Skips loading the apple script if true */
                        skipScript={false} // default = undefined
                        /** Apple image props */
                        // iconProp={{ style: { marginTop: '10px' } }} // default = undefined
                        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                        // render={(props) => <button {...props}>My Custom Button</button>}
                      />
                  }
                  <div onClick={()=> {setLoginApple(true)}} > . . </div>
                  </Grid>

                  </Grid>
                  {loading && <div className={classes.loadingBox}><CircularProgress /></div>}
              </form>
            );
          }}
        />

      
    </AuthLayout>
  );
}

const SubLink = () => (
  <Grid container>
    <Grid item xs={12} md={6}>
      <Link href="#/forgotPassword" variant="body2">
        Mot de passe oublié ?
      </Link>
    </Grid>
    <Grid item xs={12} md={6}>
      <Link href="#/register" variant="body2">
        {"Pas encore de compte? M'inscrire"}
      </Link>
    </Grid>
  </Grid>
);