import React, { useState } from 'react';
import api, {fetchToken } from '../../api'
import { Title } from 'react-admin';
import { Card, CardContent, CardHeader, Grid, Typography, Button, useTheme, TextField, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SchoolIcon from '@material-ui/icons/School';
import { BlockLoading, Checkmark } from '../UI/Decorator/Decorator';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%'
        },
    },
    formControl: {
        // margin: theme.spacing(1),
    },
    titleContact: {
        color: theme.palette.secondary.main
    }
}));

export const PageContact = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { user } = useSelector(state => state.account);
    const [objet, setObjet] = useState("Demande d’information")
    const [email, setEmail] = useState(user.email);
    const [message, setMessage] = useState("");
    const [valid, setValid] = useState(false);
    const [error, setError] = useState(false);
    const [submit, setSubmit] = useState(false);

    if (user?.email && (email !== user.email)) {
        setEmail(user.email);
    }

    const handleOnPress = async (event) => {
        setSubmit(true);

        try {
            await fetchToken(api.url+'/form-contacts',{
                method: "POST",
                body:JSON.stringify({
                    prenom: "USER: "+user.id,
                    nom: user.username,
                    message,
                    objet,
                    email: user.email
                })
            });
            setError(false);
            setValid(true);
            // console.log("newContact:", newContact);
            setSubmit(false);
        } catch (e) {
            // console.log("Error:", e.body);
            setError(e.body.data.errors);
            setSubmit(false);
        }
    }

    return (<>
        <Title title="Nous contacter"></Title>
        <Card>
            <CardHeader  style={{paddingBottom:"0px"}}
                title={<Typography  variant="h5">Nous contacter</Typography>}
                avatar={<SchoolIcon style={{color:theme.palette.secondary.main}} />}
            ></CardHeader>
            <CardContent>
            <Typography variant="subtitle1">
                
                Vous rencontrez des difficultés concernant notre espace d’administration, souhaitez plus d’informations ou faire une suggestion ?<br />
                
                Veuillez remplir le formulaire ci-dessous, nous vous répondrons dans les meilleurs délais.<br /><br />
                </Typography>
            <Grid container spacing={2}
  justifyContent="left">
        <Grid item lg={6} md={12}>
     
                <br />
                
                {!valid && 
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}>
                            {user?.email && 
                                <TextField
                                    variant="outlined"
                                    label="Adresse email" 
                                    disabled
                                    fullWidth
                                    value={user.email}
                                    className={classes.formControl}
                                />
                            }
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                <InputLabel id="demo-simple-select-filled-label">Objet</InputLabel>
                                <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={objet}
                                onChange={(e)=>setObjet(e.target.value)}
                                label="Objet :"
                                >
                                <MenuItem value="Autre">Autre</MenuItem>
                                <MenuItem value={"Suggestion"}>Suggestion</MenuItem>
                                <MenuItem value={"Demande d’information"}>Demande d’information</MenuItem>
                                <MenuItem value={"Problème d’utilisation"}>Problème d’utilisation</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12}>
                            <TextField 
                                id="outlined-basic" 
                                label="Votre message" 
                                placeholder="Rédigez votre message"
                                value={message}
                                onChange={(e)=>setMessage(e.target.value)}
                                variant="outlined" 
                                multiline 
                                rows={4} 
                                fullWidth
                                error={!!error?.message}
                                helperText={error?.message?"Entrez un message":false}
                                className={classes.formControl}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Button
                                fullWidth
                                className={classes.formControl}
                                variant="contained"
                                color="secondary"
                                disabled={submit}
                                onClick={(e)=> {
                                    console.log("TTT");
                                    handleOnPress(e)
                                }}
                            >
                                Envoyer
                            </Button>
                        </Grid>
                    </Grid>
                }
                {submit &&
                    <BlockLoading loading={submit} label="Envoi..." />
                }
                {valid &&
                    <>
                        
                        <Checkmark >
                            <br />
                        <center>
                            Demande de contact envoyé.<br />
                            Nous vous répondrons par email dés que possible.
                        </center>
                        </Checkmark>
                        
                    </>
                }


                </Grid>
                
                <Grid item lg={6} ms={12}>
                <center>
                    <h4 className={classes.titleContact}>
                        Adresse
                    </h4>
    27 chemin de Dagen<br />
    33750 St Germain du Puch<br />
    FRANCE<br />
    <h4 className={classes.titleContact}>
    Téléphone
    </h4>
    06 15 06 40 68
    </center>
                </Grid>
                
                </Grid>
            </CardContent>
        </Card>
        </>
    )
}