import React, { useContext } from 'react'
import { Typography, Button, makeStyles } from '@material-ui/core';
import { DialogLAPADEContext } from '../../Layout/AdminLayout';

const useClasses = makeStyles(theme => ({
    titleTypo: {
        color: theme.palette.secondary.main
    },
    ulPopup: {
        // border:"1px solid #000", 
        padding: "1.4em",
        paddingLeft: "2em",
        marginTop: "0px",
        paddingTop: "0px"
    }
}))

export const PopupBibliotheque = () => {
    const dialogLAPADEContext   = useContext(DialogLAPADEContext);
    const classes = useClasses();
    return (<>
        <center>
            <Typography variant="h6" className={classes.titleTypo}>
                Contactez nous pour activer <b>La PADE Bibliothèque</b>
            </Typography><br />
            <Button href="/#/contact" onClick={()=>dialogLAPADEContext.toggleBiblio()}variant="contained" color="secondary">Nous contacter</Button ><br />
            <br />
        </center>

        La <b>PADE Bibliothèque</b>, c’est une bibliothèque <b>d’informations</b> et  <b>documentaire</b> à destination des <b>collectivités</b> et des <b>élus</b> relatifs à la réglementation et à la législation sur la protection de l’environnement.
        <br /><br />
        <Typography variant="h6" className={classes.titleTypo}>
            Sur quel support ?
        </Typography>
        <br />
        Des <b>séminaires en ligne</b> vous seront dispensés sur la gestion des dépôts sauvages ou encore la transition écologique et le développement durable. <br />
        De plus, vous pourrez <b>retrouver tous les textes et documents</b> visionnés durant ce séminaire directement dans la partie <b>Modèles de documents</b>.
        <br /><br />
        <Typography variant="h6" className={classes.titleTypo}>Nos thématiques prioritaires</Typography>
        <br />
        <img src="/popup/04.jpg" alt="popup alerte 04" width="100%" height="auto"></img>
     


                <Typography variant="h6" className={classes.titleTypo}>Quels sont les avantages ?</Typography><br />
                <ul className={classes.ulPopup}>
                    <li><span style={{color:"#0bc3c1"}}>01</span> Palier aux insécurités administratives, financières et pénales, grâce à nos séminaires vous proposant des outils simples, qui suivent l’évolution de l’actualité du plan légal.</li>
                    <li><span style={{color:"#23b2c1"}}>02</span> Accédez à l’ensemble des textes législatifs et réglementaires présentés lors du séminaire, qui suivent les avancements constants des réglementations et des textes législatifs.</li>
                    <li><span style={{color:"#29a8c1"}}>03</span> Accédez à l’ensemble des modèles de documents évoqués dans les séminaires, qui suivent l’évolution des réglementations et des textes législatifs.</li>
                    <li><span style={{color:"#34a3c1"}}>04</span> À l’issue de ces séminaires vidéo, vous serez en mesure d’utiliser correctement les bons modèles de documents dans les situations adéquates.</li>
                </ul>

        <br />
        <center>Vous souhaitez adhérer à la PADE Alerte, veuillez nous contacter en cliquant sur le lien ci-dessous<br /><br />

        <Button href="/#/contact" onClick={()=>dialogLAPADEContext.toggleBiblio()}variant="contained" color="secondary">Nous contacter</Button ><br />
        </center>
    </>)
}