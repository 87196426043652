import React from 'react';
import { Breadcrumbs , Link } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
        color: theme.palette.secondary.main
    },

}));

export const TrainingBreadcrumb = ({training, module, chapter, faq}) => {
    const classes = useStyles();

    let HOME_TITLE = "Séminaires";
    let URL_ROOT = "/#/training";
    let URL_ROOT_ID = URL_ROOT+"/"+training?.id;
    if (training?.state === "STATIC") {
        HOME_TITLE = training?.Title;
        URL_ROOT = "/#/"+training?.slug;
        URL_ROOT_ID = URL_ROOT;
    }

    return (
        <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href={URL_ROOT}  className={classes.link}>
            <HomeIcon className={classes.icon} />{HOME_TITLE}
        </Link>
        
        { (training && training?.state !== "STATIC") && 
            <Link color="inherit" href={URL_ROOT_ID}>
                {!module && <b>{training?.Title}</b>}
                {module && <>{training?.Title}</>}
            </Link>
        }

        { module && 
            <Link color="inherit" href={URL_ROOT_ID+"/"+module?.id}>
                {!chapter && <b>{module?.Title}</b>}
                {chapter && <>{module?.Title}</>}
            </Link>
        }
        { chapter && 
            <Link color="inherit" href={URL_ROOT_ID+"/"+module?.id+"/"+chapter?.id}>
                <b>{chapter.Title}</b>
            </Link>
        }
        { faq && <>FAQ</> }
        </Breadcrumbs>
    )
}