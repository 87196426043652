import React, { useState, useEffect } from 'react';
import { useAuthProvider } from 'react-admin';
import api, {fetchToken} from '../../api'
import { useDispatch, useSelector } from 'react-redux'; 
import { Redirect } from 'react-router-dom';
import { CircularProgress, Grid, Card, CardHeader, CardContent, useTheme, Typography } from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { WizardForm } from './Space/WizardForm';
import { ContentHeaderTuto } from '../UI/TutoVideo';

export const Space = (props) => {
    const authProvider = useAuthProvider();
    const { user, status } = useSelector( state => state.account)
    const dispatch = useDispatch()
    const [collectivity, setCollectivity] = useState(null);
    const [initStep, setInitStep] = useState(0)
    const theme = useTheme();

    // const status = Status(user);

    useEffect(() => {
        updateCollectivity();
        // eslint-disable-next-line
    }, [user])

    const updateCollectivity = async () => {
        if (user?.collectivite?.id) {
            const {json} = await fetchToken(api.url+'/collectivities/'+user.collectivite.id)
            if (!json?.page?.id) {
                const newPage = await fetchToken(api.url+'/collectivity-pages',{
                    method: "POST",
                    body:JSON.stringify({})
                });
                
                const {json} = await fetchToken(api.url+'/collectivities/'+user.collectivite.id,{
                    method: "PUT",
                    body:JSON.stringify({page:newPage.json.id})
                });
                setCollectivity(json);
                dispatch({type: 'SAGA_USER_REFRESH'})
            } else {
                setCollectivity(json);
            }
        }
    }

    const handleUpdate = async (step) => {
        await updateCollectivity();
        setInitStep(step);
        if (step === 1) {
            authProvider.refreshIdentity().then((user) => {
                dispatch({type: 'SAGA_USER_REFRESH'})
            });
        }
    }

    const handlePublish = async () => {
        await fetchToken(api.url+'/collectivity-pages/'+user.collectivite.page?.id,{
            method: "PUT",
            body:JSON.stringify({Published:true})
        });
        dispatch({type: 'SAGA_USER_REFRESH'})
        window.location = '/';
    }

    if (user?.id) {
        if (status?.type !== "Association") {
            return (<Redirect push to="/dashboard"></Redirect>)
        }

        return (
            <Card>
                <CardHeader  style={{paddingBottom:"0px"}}
                    title={<ContentHeaderTuto title={<Typography  variant="h5">Espace association</Typography>} type="Page"/>}
                    avatar={<MenuBookIcon style={{color:theme.palette.secondary.main}} />}
                ></CardHeader>
                <CardContent>
                    <WizardForm user={user} update={handleUpdate} collectivity={collectivity} initStep={initStep} handlePublish={handlePublish}></WizardForm>
                </CardContent>
            </Card>
        )
    } else {
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{width:"50wh", height:"50vh"}}
            >
            <center>
                <CircularProgress></CircularProgress>
            </center>
            </Grid>
        )
    }
}