import React from 'react';
import { useRecordContext } from 'react-admin';

import { MyGallery } from '../UI/Gallery';
import {
    Container,
    Box
  } from "@material-ui/core";
import {strapiImage} from '../../api'

export const AlertImagesGalleryField = (props) => {
    const { source, maxWidth="md" } = props;
    const record = useRecordContext(props);

    let tab = [];
    if (record[source]?.length > 0) {
        tab = record[source].map(image => {
            return {
                original: strapiImage(image, '>').url,
                thumbnail:  strapiImage(image, '-').url,
                fullscreen: strapiImage(image, '+').url
            }
        })
    }
    return (
        <Box mt={2}>
            <Container maxWidth={maxWidth}>
                <MyGallery theme={null} inputs={tab}></MyGallery>
            </Container>
        </Box>
    )
}