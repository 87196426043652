import "./Check.css"
import { CircularProgress, Grid } from '@material-ui/core';

export const Checkmark = ({children}) => {
    return (
      <div className="success-animation">
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
        {children}
      </div>
    )
}
export const BlockLoading = ({loading, label = "Connexion en cours..."}) => (
  <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{minHeight:"50vh"}}
  >

      {loading && <>
          <Grid item>
              {label}<br /><br />
          </Grid>
          <Grid item>
              <CircularProgress size={100} thickness={2}/> 
          </Grid></>
      }

      {!loading && <Checkmark></Checkmark>}
  </Grid>
)