import { linkToRecord , useGetOne, useRecordContext, TextField } from 'react-admin';
import { useContext } from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OfflineContext } from            "../Layout/AdminOfflineLayout";

export const UserField = (props) => {

    const record = useRecordContext(props);

    const { link } = props;

    if (record?.user?.id) {

        if (link) {
            return(<span>
                <Link to={linkToRecord('/users', record.user.id, 'show')}>{record.user.username}</Link>
            </span>);
        } else {
            return(
                <TextField label="LABEL" record={record} source={"user.username"}></TextField>
            );
        }
    }
    return (<span>User bad format</span>)
}

export const LocalisationField = (props) => {
    const offlineContext = useContext(OfflineContext);
    const record = useRecordContext(props);
    return (<>
        {record.county} {record.epci} <br />
        {record.postcode} &nbsp;{record.town} 
        {offlineContext?.mode !== "offline" && 
            <><br />GPS: {record.latitude.toFixed(6)} {record.longitude.toFixed(6)}</>
        }
    </>)
    //<br />
}

export const UserField2 = ({ source, record = {} }) => {

    const { data } = useGetOne('users', record.user);
    if (data) {
        if (data.id && data.username)
            return (<Link to={linkToRecord('/users', data.id, 'show')}>{data.username}</Link>)
    }
    return (<span>User bad format</span>)
}

UserField.propTypes = {
label: PropTypes.string,
record: PropTypes.object,
source: PropTypes.string.isRequired,
};