import { ResourceContextProvider } from "react-admin"
import { AlertListBilan } from "../Resources/Alert/AlertListBilan"
import { EventListBilan } from "../Resources/Event/EventListBilan"

export const ListBilan = () => {

    return (<>
            <ResourceContextProvider value="alerts">
                <AlertListBilan basePath="/alerts"></AlertListBilan>
            </ResourceContextProvider>
            <br />
            <ResourceContextProvider value="events">
                <EventListBilan basePath="/alert-events"></EventListBilan>
            </ResourceContextProvider>
    </>)
}
