import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import api, {fetchToken} from '../../../api'


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

export default function AddEventDialog({alertId, disabled = false, icon = null}) {
  const [open, setOpen] = React.useState(false);
  const [commentaire, setCommentaire] = React.useState("");
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    
    let data = {
        description: commentaire,
        alert: alertId
    };
    // console.log(data)
    fetchToken(api.url + `/alert-comments`, {
        method: "POST",
        body:JSON.stringify(data)
    }).then(({json}) => {
        window.location.reload();
    })
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} disabled={disabled} startIcon={icon}>
        Ajouter un commentaire
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ajout d'un commentaire</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              style={{marginTop:"20px"}}
              autoFocus
              margin="dense"
              id="name"
              value={commentaire}
              label="Commentaire"
              onChange={(e) => setCommentaire(e.target.value)}
              type="email"
              fullWidth
              multiline
            />
            </DialogContentText>
          {/* <Box mb={6}></Box> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function UploadButtons() {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            Upload
          </Button>
        </label>
        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <PhotoCamera />
          </IconButton>
        </label>
      </div>
    );
  }