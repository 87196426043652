import React from "react";

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  ResourceContextProvider,
  Pagination,
  DateField
} from "react-admin";

import { Divider } from "@material-ui/core";

import { UserField } from "../../Field/UserField";

export const AlertFromCommentField = ({ source, record = {} }) => {
  return (
    <span>
      <ShowButton basePath="/alerts" label="Voir" record={record.alert} />
    </span>
  );
};

const AlertCommentListFilteredPagination = (props) => (
  <Pagination rowsPerPageOptions={false} {...props} />
);

export const AlertCommentListFiltered = ({ filter }) => {
  // console.log(filter)
  return (
    <ResourceContextProvider value="alert-comments">
      <List
        basePath="/alert-comments"
        filter={filter}
        perPage={5}
        bulkActionButtons={false}
        actions={false}
        pagination={false}
        title=" "
      >
        <>
          <AlertCommentListFilteredPagination />
          <Divider />
          <Datagrid>
            <DateField label="Date" source="created_at" locales="fr-FR" />
            <TextField source="description" />
            <TextField source="town" />
            <TextField source="postcode" />
            <UserField label="Utilisateur" source="user"></UserField>
            <AlertFromCommentField></AlertFromCommentField>
          </Datagrid>
        </>
      </List>
    </ResourceContextProvider>
  );
};
