import React, {useEffect, useState} from 'react';
import { Grid, List, ListItemText, ListItem, ListItemIcon, Card, CardHeader, CardContent, Divider, Typography, useTheme } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { useLocation } from "react-router-dom";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ShareIcon from '@material-ui/icons/Share';
import api, {fetchToken} from '../../api'
import { Title } from 'react-admin';

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const Link = ({link}) => {
    const theme = useTheme();
    return (
        <ListItemLink href={link.link} target="_blank">
            <ListItemIcon style={{color:theme.palette.secondary.main}}>
                <LinkIcon />
            </ListItemIcon>
        <ListItemText primary={link.title} />
        </ListItemLink>
    )
}

const Component = ({component}) => {
    const theme = useTheme();
    return (<Card>
        <CardHeader title={component.title} style={{ textAlign: 'left', background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }} />
        <Divider/>
        <CardContent style={{maxWidth:'100%'}}>
            <List component="nav" aria-label="label">
                {component.Links && component.Links.map((link,index) => <li key={index}><Link link={link}/></li>)}
            </List>
        </CardContent>
        </Card>
    )
}

const Document = ({document}) => {
    const theme = useTheme();
    return (
    <Card>
        <CardHeader  style={{paddingBottom:"0px"}}
            title={<Typography  variant="h5">{document.title}</Typography>}
            avatar={
                (document.title === "Aide documentaire"?
                <LocalHospitalIcon style={{color:theme.palette.secondary.main}} />:
                <ShareIcon style={{color:theme.palette.secondary.main}} />)
            
            }
        ></CardHeader>
        <CardContent>
            <div dangerouslySetInnerHTML={ { __html: document.body } }></div>
            <Grid container spacing={1}>
                {document.content.map((component, index) => {
                let defaultProps = {"xs":12,"sm":12,"md":6,"lg":6};
                let props = {};
                if (component.size !== "") {
                try {
                    props = JSON.parse(component.size)
                } catch (e) {}
                }

                return (
                    <Grid item key={index} {...defaultProps} {...props} >
                        <Component  component={component}  />
                    </Grid>
                )
                })}
            </Grid>
        </CardContent>
    </Card>)
}

export const Documents = () => {
    const [documents, setDocuments] = useState([]);
    const {hash} = useLocation();
    useEffect(() => {
        const query = api.url + '/collectivity-documents?_q=' + hash.split('#')[1]+(api.site==="LAPADE"?"&description=LAPADE":"&description=C2G");
        fetchToken(query).then(({json}) => {
            setDocuments(json);
        })
    }, [hash])

    return (documents.map((document,index) => (
        <div key={index}>
            <Title title="Documents"></Title>
            <Document document={document}></Document>
        </div>
    )))
}

