import React, {useEffect, useState} from 'react';
import api, { fetchToken } from "../../../api";
import DeleteIcon from '@material-ui/icons/Delete';
import {Select, MenuItem, FormControl, InputLabel, Divider, Grid, Button} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
// https://nomi.v2.clean2gether.com/state?name=Occitanie&shape=true

export const Step2 = ({collectivity, update}) => {
    const [stateList, setStateList] = useState([])
    const [state, setState] = useState("")

    const [countyList, setCountyList] = useState([])
    const [county, setCounty] = useState("")

    const [epciList, setEpciList] = useState([])
    const [epci, setEpci] = useState("")

    const [townList, setTownList] = useState([])
    const [town, setTown] = useState("")

    const [townCP, setTownCP] = useState("");

    useEffect(() => {
        const query = async () => {
            const {json} = await fetchToken("https://nomi.v2.clean2gether.com/country");
            setStateList(json.list);
            setState("");
            setCounty("");
            setCountyList([])
            setEpci("");
            setEpciList([])
            setTown("");
            setTownList([])
        }
        query();
    }, [])

    useEffect(() => {
        const query = async () => {
            const {json} = await fetchToken("https://nomi.v2.clean2gether.com/state?name="+state);
            setCountyList(json.list);
            setCounty("");
            setEpci("");
            setEpciList([])
            setTown("");
            setTownList([])
        }
        if (state?.length > 0) { query(); }
            
    }, [state])

    useEffect(() => {
        const query = async () => {
            const {json} = await fetchToken("https://nomi.v2.clean2gether.com/county?name="+county);
            setEpciList(json.list);
            setEpci("")
            setTown("");
            setTownList([])
        }
        if (county?.length > 0) { query(); }
    }, [county])

    useEffect(() => {
        const query = async () => {
            const {json} = await fetchToken("https://nomi.v2.clean2gether.com/epci?name="+epci);
            setTownList(json.list);
        }
        if (epci?.length > 0) { query(); }
    }, [epci])


    useEffect(() => {
        const query = async () => {
            const {json} = await fetchToken("https://nomi.v2.clean2gether.com/town?name="+town+"&epci="+epci+"&info=1");
            // setTownList(json.list);
            // console.log(json)
            setTownCP(json.info.cp.id)
        }
        if (epci?.length > 0) { query(); }
    }, [town, epci])

    return (
        <>
            {/* <Alert severity="info">This is an information message!</Alert> */}
            <MuiAlert elevation={6} variant="outlined" severity="info" style={{margin:0}} color="info">
            <b>Veuillez saisir au moins une zone d’intervention.</b><br />
            Vous pouvez enregistrer plusieurs zones si souhaitez.<br />
            Vous pouvez sélectionner une commune, un EPCI et au minimum un département.<br />
            Votre zone d’intervention permet d’afficher les signalements sur votre tableau de bord et dans la liste des alertes.<br />
            </MuiAlert>
            <h2>Zone géographique d'intervention</h2>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <SelectedZone select={{state, county, epci, town, townCP}} collectivity={collectivity} update={update}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectZone name="state" label="Région" placeholder="Choisissez une région" setter={setState} value={state} list={stateList}/>
                    {countyList?.length > 0 && <SelectZone name="county" label="Département" placeholder="Choisissez un Département" setter={setCounty} value={county} list={countyList}/>}
                    {epciList?.length > 0 && <SelectZone name="epci" label="EPCI" placeholder="Choisissez un EPCI" setter={setEpci} value={epci} list={epciList}/>}
                    {townList?.length > 0 &&<SelectZone name="town" label="Commune" placeholder="Choisissez une commune" setter={setTown} value={town} list={townList}/>}
                </Grid>
            </Grid>
        </>
    )
}

const SelectedZone = ({select, collectivity, update}) => {
    const {description} = collectivity;
    const selZone = (description?.length > 0 && description.includes('#'))?description?.split("\n"):[];
    const [selectedZone, setSelectedZone] = useState(selZone)


    const handleAddZone = (type, name) => {
        // console.log('handleAddZone:', type, name);
        const newZone = type+'#'+name;
        const newSelectedZone = selectedZone.concat([newZone])
        // console.log('newSelectedZone:', newSelectedZone);
        fetchToken(api.url+'/collectivities/'+collectivity.id, {
            method: "PUT",
            body:JSON.stringify({description: newSelectedZone.join('\n')}),
        }).then(({json}) => {
            setSelectedZone(newSelectedZone)
        })
    }

    const handleDelZone = (index) => {
        const newSelectedZone = selectedZone
        newSelectedZone.splice(index, 1)
        fetchToken(api.url+'/collectivities/'+collectivity.id, {
            method: "PUT",
            body:JSON.stringify({description: newSelectedZone.join('\n')}),
        }).then(({json}) => {
            setSelectedZone(newSelectedZone.concat([]))
        })
    }

    useEffect(()=>{
        if (selectedZone !== selZone) {
            update(1)
        }
        // eslint-disable-next-line
    }, [selectedZone])

    return (<>
    
        {selectedZone.length > 0 &&
            <>
                <p>Zones selectionnées:</p>
                {selectedZone.map((area, index) => {
                    // eslint-disable-next-line
                    const [type, zone] = area.split('#');
                    return (<p key={index}> <Button onClick={()=>handleDelZone(index)}><DeleteIcon/></Button> <b> {zone} </b> </p>)
                })}
            </>
        }
        {selectedZone.length === 0 &&
            <>
                <p>Sélectionnez au moins une zone d'intervention</p>
            </>
        }

        {(select?.county && !description?.includes("county#"+select.county)) &&
            <><Button color="primary" variant="contained" onClick={()=>handleAddZone("county",select.county)}>Ajouter </Button> Département <b>{select.county}</b> <br /></>
        }

        {(select?.epci && !description?.includes("epci#"+select.epci)) &&
            <><br /><Button color="primary" variant="contained" onClick={()=>handleAddZone("epci",select.epci)}>Ajouter </Button> EPCI <b>{select.epci}</b> <br /></>
        }

        {(select?.town && !description?.includes("town#"+select.town+"@"+select.townCP)) &&
            <><br /><Button color="primary" variant="contained" onClick={()=>handleAddZone("town",select.town+"@"+select.townCP)}>Ajouter </Button> Commune <b>{select.town}</b> <br /></>
        }
        </>
    )
}

const SelectZone = ({name, label, placeholder, setter, value, list}) => {
    return (
    <>
        <FormControl>
            <InputLabel id={`${name}ListLabel`} shrink >{label}</InputLabel>
            <Select
                displayEmpty
                labelId={`${name}ListLabel`}
                id={`${name}List`}
                value={value}
                onChange={(event)=> {
                    setter(event.target.value)
                }}
                >
                    <MenuItem value="" disabled>
                        {placeholder}
                    </MenuItem>
                    {list.map((cur, index) => 
                        <MenuItem key={index} value={cur}>{cur}</MenuItem>
                    )}
            </Select>
        </FormControl>
        <Divider style={{marginTop: 16, marginBottom: 16}}/>
    </>
    )
}