// in src/Dashboard.js
import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, Box, FormControlLabel, Checkbox, FormGroup, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { indexOf } from "lodash";

import api, {fetchToken} from '../../../api'

const ZONES = ['area', 'country', 'state', 'county','epci','postcode', 'town']

const fetchNomi = (query) => {
    return new Promise((resolve, reject) => {
        // console.log(api.shapeparser + query);
        fetchToken(api.shapeparser + query)
        .then(({json}) => {
            resolve(json);
        })
        .catch(e => {
            // console.log(e)
            reject(e);
        })
    })
}

const extractArea = (area) => {
    // console.log("extractArea : ", area)
    const split = area.split("#");
    const result = split[0];
    // console.log(indexOf(array, result));

    const index = indexOf(ZONES, result);

    // console.log("extractArea:", result, index)
    if (index>=0) {
        return {
            type: ZONES[index],
            name: split[1],
            index,
            subtype: ZONES[index+1],
            subindex: index+1
        }
    } else {
        return {
            type: ZONES[0],
            name: "ALL",
            index: 0
        }
    }
}

const createNomiQuery = (area, data) => {
    const areaInfo = extractArea(area);

    let epciInfo = null;
    if (areaInfo.type === "town") {
        // console.log("createNomiQuery town");
        if (data?.epci) {
            // console.log("blabla epci & town", data.epci);
            if (data.epci?.list[data.epci?.current]) {
                const tabEpci = data.epci.list[data.epci.current].split("#");
                if (tabEpci?.length === 2) {
                    epciInfo = "&epci="+tabEpci[1];
                }
            }
        }
    }
    // console.log("createNomiQuery areaInfo:", areaInfo);
    const query = `/${areaInfo.type}?name=${areaInfo.name}${epciInfo?epciInfo:""}&shape=1`;
    // console.log("createNomiQuery query:", query)
    return query;
}

export const FilterSelectArea = ({contour = true, disabled = false}) => {

    const { collectivite } = useSelector( state => state.account.user)
    const reduxArea = useSelector( state => state.area);
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const updateData = (data) => {
        // console.log("updateData");
        setData(data);
    }
    const dataInit = collectivite?.description?{
        area:{
            list: collectivite.description.trim().split("\n"),
            current: collectivite.description.trim().split("\n").length
        },
        init: true
    }:false;
    const selectChange = (zone, current=0) => {
        let newObj;
        newObj = getValue(zone) || {};

        // TOUS
        if (newObj.list?.length === current) {
            newObj.current = current;
            newObj.query= false;
            updateData({...data, [zone]: newObj})
            if (newObj?.from && getValue(newObj?.from)?.query) {
                setLoading({
                    query: getValue(newObj?.from)?.query, 
                    from : extractArea(getValue(newObj.from).query).type,
                    action: "back from " + newObj?.from
                })
            } else {
                setLoading({
                    query: false, 
                    from : "area",
                    action: "back to All"
                })
            }
        } else {
            // Real choice
            newObj.current = current;
            newObj.query = newObj.list[current];
            updateData({...data, [zone]: newObj})
            setLoading({
                query: newObj.list[current], 
                from : zone
            })
        }
    }
    useEffect(() => {
        // console.log("reduxArea change:", reduxArea);
        if (reduxArea.filter === "ALL" && !reduxArea.geometry) {
            // Launch hard reset
            setLoading({from:"area"})
        }
    }, [reduxArea]);
    const fetchQuery = async (loading) => {
        const {from, query} = loading;

        // console.log("fetchQuery", from, query);
        try {
            if (query) {
                // console.log("LAST QUERY !!", query)

                const result = await fetchNomi(createNomiQuery(query, data));
                let newData = resultQuery(from, query, result)

                if (from === "epci") {
                    const cpresult = await fetchNomi(createNomiQuery(query)+"&list=postcode");
                    newData = resultQuery(from, query, cpresult, newData);
                }

                dispatch({type: 'AREA_UPDATE', payload: {
                    filter:query,
                    geometry: result.feature.geometry,
                    bbox: result.bbox,
                    select: newData,
                    date_start: reduxArea.date_start,
                    date_end: reduxArea.date_end
                }})

                updateData(newData);
            } else { // Select ALL AREA
                const { json } = await fetchToken(api.url + "/collectivity-geometries/me");
                // console.log("LAST QUERY !! ALL", json);
                dispatch({type: 'AREA_UPDATE', payload: {
                    filter:"ALL",
                    geometry: json?.geometry,
                    bbox: json?.bbox,
                    select: dataInit,
                    date_start: reduxArea.date_start,
                    date_end: reduxArea.date_end
                }})
                // dispatch({type: 'AREA_INIT'});
                updateData(dataInit);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false)
    }
    const resultQuery = (from, query, result, newData = {...data}) => {
        const { listType , list } = result;

        let index = ZONES.indexOf(listType);

        if (from === "area") {
            index = ZONES.indexOf("country");
        }

        if (index > 0) {
            ZONES.forEach((zone, i) => {
                if (i >= index && newData[zone]) {
                    if (from === "epci" && zone === "town") {

                    } else {
                        delete newData[zone];
                    }
                }
            })
        }

        if (listType) {
            newData[listType] = {
                list: list.map(i=> listType+"#"+i),
                current: list.length,
                from,
                query
            }
        }

        return (newData)
    }
    const getValue = (zone) => {
        return data[zone];
    }
    //Init
    useEffect(() => {
        if (collectivite?.description) {
            if (reduxArea?.select?.area) {
                // console.log("reload");
                updateData(reduxArea.select);
            } else {
                // console.log("reinit");
                // updateData(dataInit)
                dispatch({type: 'AREA_INIT'});
            }
        }
    // eslint-disable-next-line
    }, [collectivite])

    //Exec Query
    useEffect(() => {
        // console.log("new Loading Value:", loading);
        if (loading && loading?.from) {
            fetchQuery(loading);
        }
    // eslint-disable-next-line
    }, [loading])

    //Select Action
    const handleChange = ({zone, list, value}) => {
        selectChange(zone, value)
    };

    //RENDER
    return (
        <FormGroup row style={{padding:8}}>
            { ZONES.map((ZONE, i) => {
                if (getValue(ZONE)?.list) {
                    return (
                        <SelectArea
                            list={getValue(ZONE).list}
                            current={getValue(ZONE).current}
                            onChange={handleChange}
                            label={ZONE}
                            zone={ZONE}
                            key={"select-"+i}
                            disabled={loading || disabled }
                        />
                    )
                } else {
                    return (null);
                }
            })}
            { contour && 
            <Box m={1}>
                <FormControlLabel
                    control={<Checkbox
                        checked={reduxArea?.polygon}
                        onChange={(event) => dispatch(
                            {
                                type:'AREA_UPDATE', 
                                payload : { 
                                    polygon: !reduxArea?.polygon,
                                    date_start: reduxArea.date_start,
                                    date_end: reduxArea.date_end
                                }})}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />}
                    label="Contour"
                />
                <TextField
                    id="date_start"
                    label="Crée aprés"
                    type="date"
                    defaultValue={null}
                    value={reduxArea?.date_start}
                    onChange={(event) => {
                            dispatch({type:'AREA_UPDATE', payload : {
                                date_start: (event?.target?.value),
                                date_end: (reduxArea?.date_end)
                            }})
                        }
                    }
                    InputLabelProps={{
                        shrink: true
                    }}
                />&nbsp; &nbsp; &nbsp;
                <TextField
                    id="date_end"
                    label="Crée avant"
                    type="date"
                    value={reduxArea?.date_end}
                    defaultValue={null}
                    onChange={(event) => {
                            dispatch({type:'AREA_UPDATE', payload : {
                                date_start: (reduxArea?.date_start),
                                date_end: (event?.target?.value)
                            }})
                        }
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>}
        </FormGroup>
    )
}

const SelectArea = ({list, current = 0, onChange= ()=>{}, label = "Label", zone="zone-label", disabled=false}) => {
    if(!list || list.length < 1)
        return null;
    if (!current && current !== 0) {
        current = list.length
    }

    const transformLabel = (label) => {
        switch (label) {
            case 'area':
                return 'Zones:';
            case 'country':
                return 'Pays:';
            case 'state':
                return 'Régions:';
            case 'county':
                return 'Départements:';
            case 'epci':
                return 'EPCI:';
            case 'postcode':
                return 'Code postaux:'
            case 'town':
                return 'Villes:';
            default:
                return '';
        }
    }

    return (
        <FormControl variant="outlined" style={{margin:5, float:"left"}}>
            <InputLabel id={"label-"+zone}>{transformLabel(label)}</InputLabel>
            <Select
                labelId={"label-"+zone}
                id={"select-"+zone}
                fullWidth
                label={transformLabel(label)}
                value={current}
                style={{minWidth: 150}}
                disabled={Boolean(disabled)}
                onChange={(e)=> {onChange({zone, list, value: e.target.value})}}
            >
                <MenuItem key={zone+'null'} value={list.length}>Tous</MenuItem>
                {list.map((e, index) => {
                    let labelElem;
                    const l = e.split("#")
                    if (l && l.length > 1) {
                        labelElem = l[1]
                    } else {
                        labelElem = e;
                    }

                    if (labelElem.split("@").length > 1) {
                        labelElem = labelElem.split("@")[0];
                    }

                    return (<MenuItem key={zone+index} value={index}>{labelElem}</MenuItem>); 
                })}
            </Select>
        </FormControl>
    )
}