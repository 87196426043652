import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import {Step1} from './Step1';
import {Step2} from './Step2';
import {Step3} from './Step3';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  muiAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

function getSteps() {
  return ['Informations', 'Zone géographique', 'Logo et Images'];
}

const isStepValid = (collectivity, step) => {
  switch (step) {
    case 0:
      let tmp = document.createElement("DIV");
      tmp.innerHTML = collectivity?.page?.AboutUs||"";
      // console.log("length about us", tmp.textContent.length)
      return (tmp.textContent.length > 0);
    case 1:
      const selZone = (collectivity?.description?.length > 0 && collectivity?.description.includes('#'))?collectivity?.description?.split("\n"):[];
      return (selZone.length > 0);
    case 2:
      return (collectivity?.logo?.id && collectivity?.page?.ImageBackground?.id );
    default:
      return false;
  }
}

function getStepContent(step, collectivity, update, handleStatusUpdated) {
  switch (step) {
    case 0:
      return <Step1 collectivity={collectivity} update={update} status={handleStatusUpdated}/>;
    case 1:
      return <Step2 collectivity={collectivity} update={update} status={handleStatusUpdated}/>;
    case 2:
      return <Step3 collectivity={collectivity} update={update} status={handleStatusUpdated}/>;
    default:
      return '';
  }
}

export const WizardForm = ({user, collectivity, update, initStep = 0, handlePublish}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(initStep);
  const [completed, setCompleted] = React.useState(new Set());
  const steps = getSteps();

  useEffect(() => {
    const newCompleted = new Set(completed);
    
      if (isStepValid(collectivity, 0) ) {
        newCompleted.add(0);
      }
      if (isStepValid(collectivity, 1) ) {
        newCompleted.add(1);
      }
      if (isStepValid(collectivity, 2) ) {
        newCompleted.add(2);
      }
      // console.log("newCompleted:",newCompleted)
    setCompleted(newCompleted);
    // eslint-disable-next-line
  }, [collectivity])

  const handleStatusUpdated = ({step, status}) => {
    // console.log("step:", step, "status:", status);
  }

  const totalSteps = () => {
    return getSteps().length;
  };

  const isStepOptional = (step) => {
    //return step === 1;
    return ;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const isCurrentStepCompleted = () => {
    // console.log("isCurrentStepCompleted", activeStep, completed.has(activeStep));
    return (completed.has(activeStep))
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
      // console.log("handleStep", step)
      if (step>=1 && isStepComplete(step-1)) {
        setActiveStep(step);
      }
      if (step === 0) {
        setActiveStep(0);
      }
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          if (isStepOptional(index)) {
            buttonProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {(!collectivity?.page?.Published && allStepsCompleted()) && (
            <MuiAlert
              elevation={6} 
              variant="outlined" 
              severity="success" 
              color="success" 
              className={classes.muiAlert}
              action={<Button  variant="contained" color="primary" onClick={handlePublish}>Publier</Button>}
              >
              Tous les champs requis minimum sont OK. Vous pouvez valider votre demande de création d'espace !
            </MuiAlert>
        )}
        {(collectivity?.page?.Published && !collectivity?.validated) && (
          // Publié et !validated
            <MuiAlert
              elevation={6} 
              variant="outlined" 
              severity="success" 
              color="success" 
              className={classes.muiAlert}
              >Nous vous remercions pour l’ajout de votre espace. Votre espace est en cours de validation.<br />
              Vous recevrez un email dès que votre espace sera publié sur notre site www.clean2gether.com<br />
              La validation de votre espace est nécessaire uniquement lors de sa création.
            </MuiAlert>
        )}
        {(collectivity?.page?.Published && collectivity?.validated) && (
          // Publié et validated
            <MuiAlert
              elevation={6} 
              variant="outlined" 
              severity="success" 
              color="success" 
              className={classes.muiAlert}
              action={<Button  variant="contained" color="primary" target="_blank" href={`https://fr.clean4green.org/associations/${encodeURI(collectivity.title)}/${collectivity.id}`}>Voir</Button>}
              >
                Votre espace est publié.
            </MuiAlert>
        )}
          <div>
            {getStepContent(activeStep, collectivity, update, handleStatusUpdated)}
            <Divider style={{marginTop:18, marginBottom:18}} />
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Précedent
              </Button>
              {!isLastStep() && 
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                disabled={!isCurrentStepCompleted()}
              >
                Suivant
              </Button>}
            </div>
          </div>
      </div>
    </div>
  );
}

