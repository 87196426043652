import React from "react";
import { Form, Field } from 'react-final-form';
import * as yup from 'yup'
import { TextField } from 'mui-rff';

import 'date-fns';
import {  Button, Grid, InputAdornment, Typography } from "@material-ui/core";

import { authStyle, authValidate } from "../../Layout/AuthLayout";
import api, { fetchToken } from "../../../api";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import HomeIcon from '@material-ui/icons/Home';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import InputIcon from '@material-ui/icons/Input';
import PhoneIcon from '@material-ui/icons/Phone';
import HttpIcon from '@material-ui/icons/Http';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import MuiAlert from '@material-ui/lab/Alert';
import FormHelperText from '@material-ui/core/FormHelperText';

import pick from 'lodash.pick'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './quill-custom.css';

const validationSchema = yup.object({
  EmailPublic: yup.string().email("Entrez une adresse email valide ou laissez vide").ensure(),
  LinkSite: yup.string().url("Entrez une adresse internet (https://...) valide ou laissez vide").nullable(),
  LinkFacebook: yup.string().url("Entrez une adresse internet (https://...) valide ou laissez vide").nullable(),
  LinkInstagram: yup.string().url("Entrez une adresse internet (https://...) valide ou laissez vide").nullable(),
  LinkTwitter: yup.string().url("Entrez une adresse internet (https://...) valide ou laissez vide").nullable(),
  LinkLinkedin: yup.string().url("Entrez une adresse internet (https://...) valide ou laissez vide").nullable(),
  LinkMembership: yup.string().url("Entrez une adresse internet (https://...) valide ou laissez vide").nullable(),
  LinkDonation: yup.string().url("Entrez une adresse internet (https://...) valide ou laissez vide").nullable()
});

const variant="outlined";

const formFields = [
  {
    size: { xs: 12, sm: 12 },
    field: (<TextField label="Adresse complète ( Rue, Code postal, Ville)" name="Address" margin="none" variant={variant} InputProps={{
        startAdornment: <InputAdornment position="start"><HomeIcon/></InputAdornment>
    }}/>)
  },


  {
    size: { xs: 12, sm: 12 },
    field: (<TextField label="Email" name="EmailPublic" margin="none" variant={variant} InputProps={{
        startAdornment: <InputAdornment position="start"><AlternateEmailIcon/></InputAdornment>
    }}/>)
  },


  {
    size: { xs: 12, sm: 6 },
    field: (<TextField label="Téléphone" name="Phone" margin="none" variant={variant} InputProps={{
        startAdornment: <InputAdornment position="start"><PhoneIcon/></InputAdornment>
    }}/>)
  },

  {
    size: { xs: 12, sm: 6 },
    field: (<TextField label="Site" name="LinkSite" placeholder="https://www.clean2gether.com" margin="none" variant={variant} InputProps={{
        startAdornment: <InputAdornment position="start"><HttpIcon/></InputAdornment>
    }}/>)
  },

    {
        size: { xs: 12, sm: 6 },
        field: (<TextField label="Page facebook" name="LinkFacebook" variant={variant} margin="none" InputProps={{
            startAdornment: <InputAdornment position="start"><FacebookIcon></FacebookIcon></InputAdornment>
        }}/>)
    },
    {
        size: { xs: 12, sm: 6 },
        field: (<TextField label="Page Instagram" name="LinkInstagram" variant={variant} margin="none" InputProps={{
            startAdornment: <InputAdornment position="start"><InstagramIcon></InstagramIcon></InputAdornment>
        }}/>)
    },
    {
        size: { xs: 12, sm: 6 },
        field: (<TextField label="Page Twitter" name="LinkTwitter"  variant={variant} margin="none" InputProps={{
            startAdornment: <InputAdornment position="start"><TwitterIcon></TwitterIcon></InputAdornment>
        }}/>)
    },
    {
        size: { xs: 12, sm: 6 },
        field: (<TextField label="Page LinkedIn" name="LinkLinkedin" variant={variant} margin="none" InputProps={{
            startAdornment: <InputAdornment position="start"><LinkedInIcon></LinkedInIcon></InputAdornment>
        }}/>)
    },
    {
        size: { xs: 12, sm: 6 },
        field: (<TextField label="Lien vers l’adhésion de votre association" name="LinkMembership" variant={variant} margin="none" InputProps={{
            startAdornment: <InputAdornment position="start"><CardMembershipIcon></CardMembershipIcon></InputAdornment>
        }}/>)
    },
    {
        size: { xs: 12, sm: 6 },
        field: (<TextField label="Lien donation" name="LinkDonation" variant={variant} margin="none" InputProps={{
            startAdornment: <InputAdornment position="start"><InputIcon></InputIcon></InputAdornment>
        }}/>)
    }
]

export const Step1 = ({collectivity, update, status}) => {
  const classes = authStyle();
  const initialValues = pick(collectivity?.page,[
    'AboutUs', 
    'Address',
    'EmailPublic',
    'LinkDonation',
    'LinkFacebook',
    'LinkInstagram',
    'LinkLinkedin',
    'LinkMembership',
    'LinkSite',
    'LinkTwitter',
    'Phone'])
  // console.log(collectivity?.page)
  const onSubmit = async values => {

    // console.log("values:", values)

    let tmp = document.createElement("DIV");
    tmp.innerHTML = values.AboutUs;
    // console.log("text length:", tmp.textContent.length)
    if(tmp.textContent.length === 0) {
      return ({AboutUs:"Décrivez en quelques mots votre association"})
    }


    const data = Object.keys(initialValues).reduce((acc, key) => {
      acc[key] = typeof values[key] === "undefined" ? '' : values[key];
      return acc;
    },{});

    Object.assign(data, values);


    let result = await new Promise(resolve => {
        fetchToken(api.url + `/collectivity-pages/${collectivity.page.id}`, {
            method: "PUT",
            body:JSON.stringify(data)
        }).then(({json}) => {
            // console.log("then:", json);
            update(0);
            resolve(false);
        })
    })
    return result;
  };

  return (
    <>
    <MuiAlert elevation={6} variant="outlined" severity="info" style={{margin:0}} color="info">
      Toutes les informations saisies sur ce formulaire sont diffusées sur notre site clean2gether.com.
      Seule la partie « A propos de nous » est obligatoire.<br />
      </MuiAlert>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={authValidate(validationSchema)}
        render={({ handleSubmit, submitting, pristine, dirty, errors, submitError, submitErrors, error}) => {
          return (
            <form onSubmit={handleSubmit} noValidate className={classes.form} >
                {/* <Divider /> */}
                <Grid container alignItems="flex-start" spacing={4}>

                <Grid item xs={12}>
                      <Typography>A propos de nous *</Typography>
                      <Field name="AboutUs" label="Description label" placeholder="Placeholder">
                        {props=> (<>
                          <ReactQuill className={props.meta.submitError?"errorQuill":"successQuill"} name={props.input.name} id="react-quill" value={props.input.value} onChange={props.input.onChange} theme="snow" label="LABEL" modules={quillModules}/>
                          {props.meta.submitError && <FormHelperText id="react-quill" style={{color:"#ff0000", marginLeft: 14}} >{props.meta.submitError}</FormHelperText>}
                          
                          </>
                        )}
                      </Field>
                      {/* {JSON.stringify(submitErrors)} */}
                </Grid>

                  {formFields.map((item, idx) => (
                    <Grid item {...item.size?item.size:{xs:12}} key={idx}>
                      {item.field}
                    </Grid>
                  ))}


                  <Grid item xs={12} style={{textAlign:"center"}}>
                    <Button variant="contained"color="primary" type="submit" disabled={!dirty && pristine}>
                      Enregistrer
                    </Button>
                  </Grid>
                </Grid>
            </form>
          );
        }}
        />
        </>
  );
}

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['clean']
  ],
}