import React, { useState } from 'react';
import { 
    Grid,
    Typography,
    Button,
    TextField,
    Divider
} from '@material-ui/core';

import { useSelector } from 'react-redux'; 
import { makeStyles } from '@material-ui/core/styles';

import api, {fetchToken } from '../../../api'
import { BlockLoading, Checkmark } from '../../UI/Decorator/Decorator';

const useStyles = makeStyles((theme) => {
    return (
        {
            root: {
                maxWidth: 345,
            },
            media: {
                height: 0,
                paddingTop: '56.25%', // 16:9
            },
            buttons: {
                '& > *': {
                    margin: theme.spacing(1),
                },
            },
            titleTraining: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                padding: "10px",
                borderRadius: theme.shape.borderRadius,
                marginBottom: "0px"
            },
            titleNewFAQ: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.contrastText,
                padding: "10px",
                borderRadius: theme.shape.borderRadius,
                marginBottom: "0px"
            }
        }
    )
});

export const TrainingFAQForm = () => {
    const classes = useStyles();
    const { user } = useSelector(state => state.account);
    const [message, setMessage] = useState("");
    const [valid, setValid] = useState(false);
    const [error, setError] = useState(false);
    const [submit, setSubmit] = useState(false);

    const handleOnPress = async (event) => {
        setSubmit(true);

        try {
            await fetchToken(api.url+'/form-faqs',{
                method: "POST",
                body:JSON.stringify({
                    user: user.id,
                    username: user.username,
                    question: message,
                    email: user.email
                })
            });
            setError(false);
            setValid(true);
            // console.log("newContact:", newContact);
            setSubmit(false);
        } catch (e) {
            // console.log("Error:", e.body);
            setError(e.body.data.errors);
            setSubmit(false);
        }
    }

    return (<>
    <br />
        <Divider></Divider>
        <br /><br /><Grid container    alignItems="center"
  justifyContent="center">
        <Grid item lg={6} md={12}>
        <Typography variant="h6" className={classes.titleNewFAQ}><center>
            Poser une question
            </center>
        </Typography>
        <br />
        <center>
            <Typography variant="subtitle1" >
                Veuillez poser votre question ci-dessous, nous vous répondrons dans les meilleurs délais.
            </Typography>
        </center>
        <br />
        {!valid && 
        
            
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <TextField 
                        id="outlined-basic" 
                        label="Votre question" 
                        placeholder="Rédigez votre question"
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                        variant="outlined" 
                        multiline 
                        rows={4} 
                        fullWidth
                        error={!!error?.message}
                        helperText={error?.message?"Entrez un message":false}
                        className={classes.formControl}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Button
                        fullWidth
                        className={classes.formControl}
                        variant="contained"
                        color="primary"
                        disabled={submit}
                        onClick={(e)=> {
                            console.log("TTT");
                            handleOnPress(e)
                        }}
                    >
                        Envoyer
                    </Button>
                </Grid>
            
            
        </Grid>
        }
        {submit &&
            <BlockLoading loading={submit} label="Envoi..." />
        }
        {valid &&
            <>
                <Checkmark >
                    <br />
                <center>
                    Votre question est envoyée.<br />
                    Merci pour votre suggestion.
                </center>
                </Checkmark>
                
            </>
        }
        </Grid>
        </Grid>
        </>
    )
}