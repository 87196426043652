import React, {useEffect, useState} from 'react';
import {Select, MenuItem, FormControl, InputLabel, Grid, makeStyles} from "@material-ui/core";

import { fetchToken } from '../../api';

// https://nomi.v2.clean2gether.com/state?name=Occitanie&shape=true

export const ZoneSelector = ({onChange = ()=>{}}) => {
  
    const [stateList, setStateList] = useState([])
    const [state, setState] = useState("")

    const [countyList, setCountyList] = useState([])
    const [county, setCounty] = useState("")

    const [epciList, setEpciList] = useState([])
    const [epci, setEpci] = useState("")

    const [townList, setTownList] = useState([])
    const [town, setTown] = useState("")

    // const [townCP, setTownCP] = useState("");


    useEffect(() => {
        const query = async () => {
            const {json} = await fetchToken("https://nomi.v2.clean2gether.com/country");
            setStateList(json.list);
            setState("");
            setCounty("");
            setCountyList([])
            setEpci("");
            setEpciList([])
            setTown("");
            setTownList([])
        }
        query();
    }, [])

    useEffect(() => {
        const query = async () => {
            const {json} = await fetchToken("https://nomi.v2.clean2gether.com/state?name="+state);
            setCountyList(json.list);
            setCounty("");
            setEpci("");
            setEpciList([])
            setTown("");
            setTownList([])
        }
        if (state?.length > 0) { query(); }
            
    }, [state])

    useEffect(() => {
        const query = async () => {
            const {json} = await fetchToken("https://nomi.v2.clean2gether.com/county?name="+county);
            setEpciList(json.list);
            setEpci("")
            setTown("");
            setTownList([])
        }
        if (county?.length > 0) { query(); }
    }, [county])

    useEffect(() => {
        const query = async () => {
            const {json} = await fetchToken("https://nomi.v2.clean2gether.com/epci?name="+epci);
            setTownList(json.list);
        }
        if (epci?.length > 0) { query(); }
    }, [epci])


    useEffect(() => {
        const query = async () => {
            // const {json} = await fetchToken("https://nomi.v2.clean2gether.com/town?name="+town+"&epci="+epci+"&info=1");
            // setTownList(json.list);
            // console.log(json)
            // setTownCP(json.info.cp.id)
        }
        if (epci?.length > 0) { query(); }
    }, [town, epci])

    useEffect(()=> {
      onChange({state, county, epci, town})
    }, [state, county, epci, town])

    return (
        <>
          <SelectZone name="state" label="Région" placeholder="Choisissez une région" setter={setState} value={state} list={stateList}/>
          {countyList?.length > 0 && <SelectZone name="county" label="Département" placeholder="Choisissez un Département" setter={setCounty} value={county} list={countyList}/>}
          {epciList?.length > 0 && <SelectZone name="epci" label="EPCI" placeholder="Choisissez un EPCI" setter={setEpci} value={epci} list={epciList}/>}
          {townList?.length > 0 &&<SelectZone name="town" label="Commune" placeholder="Choisissez une commune" setter={setTown} value={town} list={townList}/>}
        </>
    )
}

const SelectZone = ({name, label="     ", placeholder, setter, value, list}) => {
    return (
    <Grid item xs={6}>
        <FormControl variant="outlined" style={{width:"100%"}}>
            <InputLabel id={`${name}ListLabel`} shrink >{value?label:""}</InputLabel>
            <Select
                variant="outlined" 
                displayEmpty
                labelId={`${name}ListLabel`}
                id={`${name}List`}
                value={value}
                label={label}
                onChange={(event)=> {
                    setter(event.target.value)
                }}
                inputProps={{
                  name:'Région'
                }}
                >
                    <MenuItem value="" disabled>
                        {placeholder}
                    </MenuItem>
                    {list.map((cur, index) => 
                        <MenuItem key={index} value={cur}>{cur}</MenuItem>
                    )}
            </Select>
        </FormControl>
    </Grid>
    )
}


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));