import React, { useEffect, useState } from 'react'
import {Redirect} from 'react-router-dom'
import { Container } from "@material-ui/core";
import { BlockLoading } from "../UI/Decorator/Decorator"
import { useSelector, useDispatch } from 'react-redux'

export const LoadingPage = (props) => {
    const [redirect, setRedirect] = useState(false)
    const { user } = useSelector(state => state.account)
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (!user) {
            dispatch({type: 'SAGA_USER_REFRESH'})
        } else {
            setTimeout(() => {
                setRedirect(true)
            }, 500)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    return (
        <Container>
            <BlockLoading loading={!user}/>
            {redirect && <Redirect push to="/dashboard"></Redirect>}
        </Container>
    )
}