import React, { useEffect, useState } from 'react'
import {Redirect} from 'react-router-dom'
import { Container } from "@material-ui/core";
import { BlockLoading } from "../UI/Decorator/Decorator"

import { useGetIdentity} from 'react-admin'

export const Homepage = (props) => {
    const [redirect, setRedirect] = useState(false)
    const { loading } = useGetIdentity();
    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                // console.log("Ready to redirect")
                setRedirect(true)
            }, 1300)
        }
    }, [loading])
    return (
        <Container>
            <BlockLoading loading={loading}/>
            {redirect && <Redirect push to="/dashboard"></Redirect>}
        </Container>
    )
}