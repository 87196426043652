// in src/NotFound.js
import * as React from "react";

import { Typography, Card, CardContent, CircularProgress } from "@material-ui/core";

import { Title, useLogin } from 'react-admin';

import { useParams, useLocation } from "react-router-dom";

import { AuthLayout } from "../../Layout/AuthLayout";

export const ProviderCallback = () => {
    const params = useParams();
    const login = useLogin();
    const location = new URLSearchParams(useLocation().search)
    
    if (location.get("access_token")) {
        login({provider: params.provider, token: location.get("access_token")})
        .catch(err => {
            // console.log(err);
            if (err.message === "account.provider.email.already.exist") {
                // console.log("Need force connect with facebook ");
            }
        })
    } else {
        // console.log("redirect...")
    }
    
    return (
        <AuthLayout>
            <Title title="Facebook Callback" />
            <Typography component="h1" variant="h5">
                Connexion collectivités
            </Typography>
            <br />
            <Card>
                
                <CardContent>
                    <h1>Connexion depuis {params.provider}</h1>
                    <center><CircularProgress /></center>
                </CardContent>
            </Card>
        </AuthLayout>
    )
};

