import React, {useState} from "react";
import { useSelector } from 'react-redux'; 
import SchoolIcon from '@material-ui/icons/School';
import api from '../../api';
import { Dialog, Box, Button, Grid } from "@material-ui/core";

export const TutoVideo = ({type="Alerts", width="100%", height="100%", buttonText="Tutoriel"}) => {

    const { tutorial } = useSelector(state => state.account);
    const { status } = useSelector(state => state.account);
    const [open, setOpen] = useState(false)
    const dialogWidth = "lg";
    let apiField = "associationDashboard";

    switch (type) {
        case "Alerts":
            apiField = (status?.type === "Collectivité")?"collectivityAlerts":"associationAlerts";
        break;
        case "Dashboard":
            apiField = (status?.type === "Collectivité")?"collectivityDashboard":"associationDashboard";
        break;
        case "Page":
            apiField = "associationPage";
        break;
        case "Events":
            apiField = (status?.type === "Collectivité")?"collectivityEvents":"events";
            apiField = "events";
        break;
        case "Bilan":
            apiField = (status?.type === "Collectivité")?"collectivityBilan":"associationBilan";
        break;
        case "Training":
            apiField = (status?.type === "Collectivité")?"collectivityTraining":"associationTraining";
        break;
        default: 
            apiField = "none";
    }

    const handleToggleOpen = () => {
        setOpen(!open)
    }

    // console.log(apiField);
    // console.log(tutorial);
    if (tutorial[apiField] && tutorial[apiField]?.url)
    return (
        <Box textAlign="right">
            <Button
            onClick={handleToggleOpen}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SchoolIcon />}
            aria-label="tutoriel"
            >
                {buttonText}
            </Button>
            <Dialog
                open={open}
                onClose={handleToggleOpen}
                maxWidth={dialogWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <video width={width} height={height} controls src={api.url+tutorial[apiField]?.url } />
            </Dialog>
        </Box>
    );
    return (null);
}

export const ContentHeaderTuto = ({title="Liste", type="Alerts", width="100%", height="100%", buttonText="Tutoriel"}) => {

    return (
        <Grid container>
            <Grid item xs={6}>
                {title}
            </Grid>
            <Grid item xs={6}>
                { type && 
                    <TutoVideo type={type} width={width} height={height} buttonText={buttonText} />
                }
            </Grid>
        </Grid>
    )
}

